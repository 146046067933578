import dayjs from 'dayjs';
import * as qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import CountDown, { CountdownRenderProps } from 'react-countdown-now';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';

import { PosPairing } from '../../../../../api/posApi';
import Button from '../../../../../components/button/Button';
import CopyButton from '../../../../../components/copy-button/CopyButton';
import StyledField from '../../../../../components/field/FieldStyle';
import Loader from '../../../../../components/loader/Loader';
import Modal from '../../../../../components/modal/Modal';
import Notification from '../../../../../components/notification/Notification';
import Pager from '../../../../../components/pager/Pager';
import Table from '../../../../../components/table/Table';
import View from '../../../../../components/view/View';
import WithPermission from '../../../../../components/with-permission/WithPermission';
import { GLOBAL_DATE_FORMAT, ITEMS_PER_PAGE, RoutesUrls, Scopes } from '../../../../../constants';
import { ColumnedFlex, H1, Light } from '../../../../../gfx/globals';
import InvoiceRpcClient, { SubscribeMethod } from '../../../../../services/invoice-rpc-client';
import { useStoreActions, useStoreState } from '../../../../../services/store';
import { Json } from '../../../../../services/ws-rpc-client';
import { RouteProps } from '../../../../../typings';
import { StyledCountdown } from '../../../../../services/get-payment-countdown';
import { PosPairingColSizes } from '../../../../../gfx/constants';
import { UserRole } from '../../../../../constants';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import {
	DownloadApp,
	DownloadAppDescription,
	DownloadAppLinks,
	PairedDevicesTitle,
	PairedDevicesWrapper,
	PairingModalWrapper,
	PairingInputWrapper,
	PairingModalFooter,
	ListViewWrap,
	TableWrapper,
} from './PosPairingsListViewStyle';

function PosPairingsListView(props: RouteProps) {
	const [page, setPage] = useState(1);
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activePosPairing, setActivePosPairing] = useState<PosPairing | null>(null);
	// const [errorMessage, setErrorMessage] = useState('');
	const [isGenerateCodeModalOpened, setIsGenerateCodeModalOpened] = useState(false);

	const rpcRef = useRef<InvoiceRpcClient>();

	const { posPairings, generatePosPairingResult, activeViewer } = useStoreState((state) => ({
		posPairings: state.pos.posPairings,
		generatePosPairingResult: state.pos.generatePosPairingResult,
		activeViewer: state.viewer.activeViewer,
	}));

	const { generatePosPairingCode, revokePosPairingCode, getPosPairings } = useStoreActions((actions) => ({
		...actions.pos,
	}));

	const shouldShowPairingDeleteButton = (posPairing: PosPairing) => {
		const isOwnerOrAdmin = activeViewer?.role === UserRole.OWNER || activeViewer?.role === UserRole.ADMIN;
		const isAdvancedTeamMember = activeViewer?.role === UserRole.ADVANCED_TEAM_MEMBER;
		const isTeamMember = activeViewer?.role === UserRole.TEAM_MEMBER;

		if (isOwnerOrAdmin) {
			return true;
		}

		if (isAdvancedTeamMember && ['ADVANCED_TEAM_MEMBER', 'TEAM_MEMBER'].includes(posPairing.pairingUserRole)) {
			return true;
		}

		if (isTeamMember && posPairing.userId === activeViewer?.id) {
			return true;
		}

		return false;
	};

	useEffect(() => {
		const pageFromLink = qs.parse(props.location.search).page;

		if (pageFromLink && typeof pageFromLink === 'string') {
			setPage(parseInt(pageFromLink, 10));
		}
	}, [props.location.search]);

	useEffect(() => {
		const fetchPosPairings = async () => {
			return await getPosPairings({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};
		fetchPosPairings();
	}, [page, getPosPairings]);

	// close opened ws
	useEffect(() => {
		return () => {
			if (rpcRef.current) {
				rpcRef.current.close();
			}
		};
	}, []);

	if (!posPairings) {
		return <Loader />;
	}

	const pairings = posPairings ? posPairings.items : [];
	const itemCount = posPairings ? posPairings.itemCount : 0;
	const pageCount = posPairings ? posPairings.pageCount : 0;

	const setupPosPairingRpcClient = (pairingCode: string) => {
		// close previous connection if exists
		if (rpcRef.current) {
			rpcRef.current.close();
		}

		const protocol = process.env.REACT_APP_API_SSL === 'true' ? 'wss' : 'ws';
		const url = `${protocol}://${process.env.REACT_APP_API_URL}/ws`;

		rpcRef.current = new InvoiceRpcClient(url, SubscribeMethod.POS_APP_PAIRED, pairingCode);

		rpcRef.current.handlePosPaired = async (data: Json) => {
			toast.success(`Device "${data.deviceName}" was successfully paired`);
			setIsGenerateCodeModalOpened(false);
			await getPosPairings({ page, itemsPerPage: ITEMS_PER_PAGE });

			if (rpcRef.current) {
				rpcRef.current.close();
			}
		};
	};

	const generatePosPairing = async () => {
		const response = await generatePosPairingCode();

		if (response.success && response.payload) {
			setIsGenerateCodeModalOpened(true);
			setupPosPairingRpcClient(response.payload.pairingCode);
		}
	};

	const renderGeneratePairingCodeModal = () => {
		if (!generatePosPairingResult) {
			return null;
		}

		const renderCountDown = (props: CountdownRenderProps) => (
			<StyledCountdown>{` ${props.formatted.minutes}:${props.formatted.seconds}`}</StyledCountdown>
		);

		return (
			<Modal
				ariaHideApp={false}
				hasCloseButton
				isOpen={isGenerateCodeModalOpened}
				onRequestClose={() => handleGenerateModalClose()}
			>
				<PairingModalWrapper>
					<H1>Pairing code</H1>
					<Notification>
						Enter the following code into your Dagpay POS app to <br />
						connect with your Dagpay account.
					</Notification>
					<p>
						Code expires in:
						<CountDown
							daysInHours
							date={generatePosPairingResult.pairingCodeExpiryDate}
							renderer={renderCountDown}
							onComplete={() => setIsGenerateCodeModalOpened(false)}
						/>
					</p>
					<StyledField>
						<PairingInputWrapper>
							<StyledField.InputWrapper>
								<StyledField.Input value={generatePosPairingResult.pairingCode} disabled />
								<StyledField.AddonNode>
									<CopyButton value={generatePosPairingResult.pairingCode}>
										<ReactSVG src="/files/svg/private/CopyRound.svg" />
									</CopyButton>
								</StyledField.AddonNode>
							</StyledField.InputWrapper>
						</PairingInputWrapper>
					</StyledField>
					<PairingModalFooter>
						<Modal.Buttons>
							<Button alignedRight onClick={handleGenerateModalClose}>
								Close
							</Button>
						</Modal.Buttons>
					</PairingModalFooter>
				</PairingModalWrapper>
			</Modal>
		);
	};

	const handleGenerateModalClose = async () => {
		setIsGenerateCodeModalOpened(false);
		await getPosPairings({ page, itemsPerPage: ITEMS_PER_PAGE });
	};

	const renderPosPairingTr = (posPairing: PosPairing, index: number) => {
		return (
			<Table.TrPrimary key={posPairing.id}>
				<Table.FixedWidthTd cellWidth={PosPairingColSizes.DATE_ADDED}>
					<Table.DataWrapper data-label="Date added">
						<ColumnedFlex>
							{dayjs(posPairing.createdDate).format(GLOBAL_DATE_FORMAT)}
							<Light>{dayjs(posPairing.createdDate).format('HH:mm')}</Light>
						</ColumnedFlex>
					</Table.DataWrapper>
				</Table.FixedWidthTd>

				<Table.FixedWidthTd cellWidth={PosPairingColSizes.DEVICE}>
					<Table.DataWrapper data-label="Device">{posPairing.deviceName}</Table.DataWrapper>
				</Table.FixedWidthTd>
				<Table.FixedWidthTd cellWidth={PosPairingColSizes.EMAIL}>
					<Table.DataWrapper data-label="Device">{posPairing.userEmail}</Table.DataWrapper>
				</Table.FixedWidthTd>
				{shouldShowPairingDeleteButton(posPairing) ? (
					<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.REVOKE_POS_PAIRING]}>
						<Table.FixedWidthTd cellWidth={PosPairingColSizes.ACTIONS}>
							<Table.DataWrapper data-label="Actions">
								<Table.HiddenSettingsItem isDisable key={index} onClick={() => handleOpenModal(posPairing, index)}>
									<ReactSVG src="/files/svg/private/Delete.svg" />
								</Table.HiddenSettingsItem>
							</Table.DataWrapper>
						</Table.FixedWidthTd>
					</WithPermission>
				) : (
					<Table.FixedWidthTd cellWidth={PosPairingColSizes.ACTIONS}></Table.FixedWidthTd>
				)}
			</Table.TrPrimary>
		);
	};

	const renderRevokePairingCodeModal = () => {
		if (activeIndex === null || activePosPairing === null) {
			return null;
		}

		return (
			<Modal
				ariaHideApp={false}
				isOpen={isModalOpen[activeIndex]}
				onRequestClose={() => handleCloseModal(activeIndex)}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<h1>Revoke device access?</h1>
					<p>
						You are about to revoke access for the following device: <strong>{activePosPairing.deviceName}</strong>. You
						will need to pair the device again if you wish to continue using Dagpay POS app with your current account.
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => handleCloseModal(activeIndex)}>
							Cancel
						</Button.Secondary>
						<Button.Secondary
							type="button"
							red
							onClick={() => handleRevokePairingCode(activePosPairing.id, activeIndex)}
						>
							Revoke
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
		);
	};

	const handlePageChanged = (newPage: number) => {
		setPage(newPage);
	};

	const handleOpenModal = (posPairing: PosPairing, index: number) => {
		const isSettingsOpenedCopy = isSettingsOpened.slice();
		isSettingsOpenedCopy[index] = !isSettingsOpened[index];
		setIsSettingsOpened(isSettingsOpenedCopy);
		setActivePosPairing(posPairing);
		setActiveIndex(index);

		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = true;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleCloseModal = (index: number) => {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = false;
		setIsModalOpen(isModalOpenCopy);
	};

	const handleRevokePairingCode = async (pairingId: string, index: number) => {
		const isModalOpenCopy = isModalOpen.slice();

		try {
			const response = await revokePosPairingCode({ pairingId });
			isModalOpenCopy[index] = false;
			setIsModalOpen(isModalOpenCopy);

			if (response.success) {
				setIsModalOpen(isModalOpenCopy);

				await getPosPairings({ page, itemsPerPage: ITEMS_PER_PAGE });

				toast.success('POS device successfully revoked');
				props.history.push(RoutesUrls.POS_PAIRINGS);
			}
		} catch (e) {
			// setErrorMessage('Revoking pos pairing failed');
			toast.error('Revoking POS pairing failed');
		}
	};

	return (
		<View paddingBottom={37} paddingTop={37}>
			<H1>Dagpay POS app</H1>
			<ListViewWrap>
				<View.ListHeader>
					<Notification>
						Download and install the Dagpay POS checkout app on your Android device and start accepting dagcoin payments
						<br /> easily at your brick-and-mortar business.{' '}
						<a
							href="https://help.dagpay.io/en/articles/294019-pos-checkout-app-setup"
							target="_blank"
							rel="noopener noreferrer"
						>
							Learn more
						</a>
					</Notification>
					<DownloadApp>
						<DownloadAppDescription>Download and install the app</DownloadAppDescription>
						<DownloadAppLinks>
							<a
								href="https://play.google.com/store/apps/details?id=org.dagcoin.dagpos&hl=en_US&gl=US"
								target="_blank"
								rel="noopener noreferrer"
							>
								<ReactSVG src="/files/svg/private/button/GooglePlay.svg" />
							</a>
						</DownloadAppLinks>
					</DownloadApp>
					<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.GENERATE_POS_PAIRING_CODE]}>
						<PairedDevicesWrapper>
							<PairedDevicesTitle>
								<p>Paired devices</p>
								<p>Generate new pairing code to connect your Dagpay POS app and view connected devices</p>
							</PairedDevicesTitle>
							{pairings.length > 0 ? (
								<Button.Secondary green onClick={generatePosPairing}>
									Generate new pairing code
								</Button.Secondary>
							) : (
								' '
							)}
						</PairedDevicesWrapper>
					</WithPermission>
				</View.ListHeader>
			</ListViewWrap>
			{renderGeneratePairingCodeModal()}

			{renderRevokePairingCodeModal()}
			{pairings.length > 0 ? (
				<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.GET_POS_PAIRINGS]}>
					<TableWrapper>
						<Table>
							<Table.Thead hiddenOnSmallerScreen>
								<tr>
									<th>Date added</th>
									<th>Device</th>
									<th>Email</th>
									<WithPermission permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.REVOKE_POS_PAIRING]}>
										<th />
									</WithPermission>
								</tr>
							</Table.Thead>
							<tbody>{pairings.map(renderPosPairingTr)}</tbody>
						</Table>
					</TableWrapper>
				</WithPermission>
			) : (
				<View.NoItemsFound>
					<ReactSVG src="/files/svg/private/NoPairings.svg" />
					<p>You haven’t paired any devices yet</p>
					<Button.Secondary white onClick={generatePosPairing}>
						Generate new pairing code
					</Button.Secondary>
				</View.NoItemsFound>
			)}
			<Pager totalItems={itemCount} currentPage={page} onChange={handlePageChanged} totalPages={pageCount} />
		</View>
	);
}

export default withRouter(PosPairingsListView);
