import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import { EnvironmentType } from '../../../../../../api/environmentApi';
import Button from '../../../../../../components/button/Button';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import StyledField from '../../../../../../components/field/FieldStyle';
import Loader from '../../../../../../components/loader/Loader';
import Modal from '../../../../../../components/modal/Modal';
import Notification from '../../../../../../components/notification/Notification';
import Pager from '../../../../../../components/pager/Pager';
import Table from '../../../../../../components/table/Table';
import View from '../../../../../../components/view/View';
import WithPermission from '../../../../../../components/with-permission/WithPermission';
import { CamelCasedIntegrationTypes, ITEMS_PER_PAGE, RoutesUrls, Scopes } from '../../../../../../constants';
import { EnvironmentColSizes } from '../../../../../../gfx/constants';
import { ColumnedFlex, H1 } from '../../../../../../gfx/globals';
import { useStoreActions, useStoreState } from '../../../../../../services/store';
import { RouteProps } from '../../../../../../typings';
import {
	EnvironmentLoaderWrapper,
	EnvironmentNameWrapper,
	EnvironmentNotificationWrapper,
	EnvironmentTab,
	EnvironmentTabsWrapper,
} from './EnvironmentsListStyle';
import { TatumEnvironmentType } from '../../../../../../api/tatumEnvironmentApi';

export type EnvironmentTypeInList = EnvironmentType | TatumEnvironmentType;

function EnvironmentsListView(props: RouteProps) {
	const [activeEnvironment, setActiveEnvironment] = useState<{
		id: string;
		name: string;
		creatorId: string;
		creator: {
			firstName: string;
			lastName: string;
		};
	} | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [page, setPage] = useState(1);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activeTab, setActiveTab] = useState<'dagcoin' | 'tatum'>('dagcoin');

	const { verifications, parentVerifications, environments, activeViewer, tatumEnvironmentList } = useStoreState(
		(state) => ({
			tatumEnvironmentList: state.tatumEnvironment.envList,
			environments: state.environment.environments,
			verifications: state.verification.verifications,
			parentVerifications: state.verification.parentUserVerifications,
			activeViewer: state.viewer.activeViewer,
		}),
	);

	const { getEnvironments, deleteTatumEnvironment, deleteEnvironment, getTatumEnvironmentList } = useStoreActions(
		(actions) => ({
			...actions.environment,
			...actions.tatumEnvironment,
			...actions.verification,
		}),
	);

	useEffect(() => {
		const fetchEnvironments = async () => {
			if (activeTab === 'dagcoin') {
				return await getEnvironments({ page: page, itemsPerPage: ITEMS_PER_PAGE });
			}

			await getTatumEnvironmentList({ page: page, itemsPerPage: ITEMS_PER_PAGE });
		};

		fetchEnvironments();
	}, [page, getEnvironments, getTatumEnvironmentList, activeTab]);

	useEffect(() => {
		const newPage = qs.parse(props.location.search).page;
		const tab = qs.parse(props.location.search).tab;

		if (newPage && typeof newPage === 'string') {
			const parsedPage = parseInt(newPage, 10);

			if (parsedPage !== page) {
				setPage(parsedPage);
			}
		}

		if (tab && typeof tab === 'string' && (tab === 'dagcoin' || tab === 'tatum') && tab !== activeTab) {
			setActiveTab(tab);
		}

		if (!tab) {
			setActiveTab('dagcoin');
		}
	}, [page, props.location.search, activeTab]);

	const isWalletAdded = verifications ? verifications.isWalletAdded : false;

	const canAddDagcoinIntegration =
		activeTab === 'dagcoin' &&
		environments &&
		environments.items.length > 0 &&
		(isWalletAdded || parentVerifications?.isWalletAdded);

	const canAddTatumIntegration =
		activeViewer?.tatumEnabled &&
		activeTab === 'tatum' &&
		tatumEnvironmentList &&
		tatumEnvironmentList.items.length > 0;

	// find out total items and page count for pager
	const totalItemsCount = activeTab === 'dagcoin' ? environments?.itemCount || 0 : tatumEnvironmentList?.itemCount || 0;
	const totalPages = activeTab === 'dagcoin' ? environments?.pageCount || 0 : tatumEnvironmentList?.pageCount || 0;

	return (
		<View paddingBottom={37} paddingTop={37}>
			<H1> Integrations</H1>
			<View.ListHeader hasNoBorder={activeViewer?.tatumEnabled}>
				<EnvironmentNotificationWrapper>
					<Notification>Create a new environment and integrate it with your website to accept payments.</Notification>
					<Notification>
						Note that when using the Dagpay API for multiple projects you must create new unique API credentials for
						each project.
					</Notification>
					{!isWalletAdded && (
						<WithPermission permissions={[Scopes.WALLETS, Scopes.CREATE_WALLET]}>
							<Notification>
								Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to create new environment.
							</Notification>
						</WithPermission>
					)}
				</EnvironmentNotificationWrapper>

				{(canAddDagcoinIntegration || canAddTatumIntegration) && (
					<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT]}>
						<Button.Secondary green onClick={handleRedirectToCreateEnvironment}>
							<span>Add integration</span>
						</Button.Secondary>
					</WithPermission>
				)}
			</View.ListHeader>
			{activeViewer?.tatumEnabled && integrationsTabs()}

			{(activeTab === 'dagcoin' && !environments) || (activeTab === 'tatum' && !tatumEnvironmentList) ? (
				<EnvironmentLoaderWrapper>
					<Loader />
				</EnvironmentLoaderWrapper>
			) : (
				<React.Fragment>
					{activeTab === 'dagcoin' && environments && environments.items.length > 0 ? (
						<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.GET_ENVIRONMENTS]}>
							{environments.items.map(renderEnvironmentTable).reverse()}
						</WithPermission>
					) : activeTab === 'tatum' && tatumEnvironmentList && tatumEnvironmentList.items.length > 0 ? (
						<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.GET_ENVIRONMENTS]}>
							{tatumEnvironmentList.items
								.map((item, index) =>
									renderEnvironmentTable(
										{
											name: item.name,
											id: item.id,
											userId: item.userId,
											secret: item.secret,
											integrationType: TatumEnvironmentType.TATUM,
											creatorId: item.creatorId,
											creator: {
												firstName: 'TODO first name',
												lastName: 'TODO last name',
											},
										},
										index,
									),
								)
								.reverse()}
						</WithPermission>
					) : (
						<View.NoItemsFound>
							<ReactSVG src="/files/svg/private/NoTransactions.svg" />
							<p>You haven't created any integrations yet</p>
							<Button.Secondary white onClick={handleRedirectToCreateEnvironment}>
								Add integration
							</Button.Secondary>
						</View.NoItemsFound>
					)}
				</React.Fragment>
			)}

			<Pager totalItems={totalItemsCount} currentPage={page} onChange={handlePageChanged} totalPages={totalPages} />
			{getDeleteModal()}
		</View>
	);

	function renderEnvironmentTable(
		environment: {
			name: string;
			integrationType: EnvironmentTypeInList;
			id: string;
			userId: string;
			secret: string;
			creatorId: string;
			creator: {
				firstName: string;
				lastName: string;
			};
		},
		index: number,
	) {
		return (
			<Table key={index}>
				<Table.Thead>
					<tr>
						<th colSpan={4}>
							<EnvironmentNameWrapper>
								{environment.name}{' '}
								<StyledField.Optional>{CamelCasedIntegrationTypes[environment.integrationType]} </StyledField.Optional>
							</EnvironmentNameWrapper>
						</th>
					</tr>
				</Table.Thead>

				<tbody>
					<Table.TrPrimary
						key={environment.id}
						onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(environment, index) : () => null}
					>
						<Table.FixedWidthTd cellWidth={EnvironmentColSizes.ENVIRONMENT_ID}>
							<Table.NoLabelDataWrapper wordBrake="break-all" justifyOption="flex-start">
								<ColumnedFlex>
									<Table.TdInsideTitle>Environment ID</Table.TdInsideTitle>
									{environment.id}
								</ColumnedFlex>
							</Table.NoLabelDataWrapper>
						</Table.FixedWidthTd>
						<Table.FixedWidthTd cellWidth={EnvironmentColSizes.USER_ID}>
							<Table.NoLabelDataWrapper wordBrake="break-all" justifyOption="flex-start">
								<ColumnedFlex>
									<Table.TdInsideTitle>User ID</Table.TdInsideTitle>
									{environment.userId}
								</ColumnedFlex>
							</Table.NoLabelDataWrapper>
						</Table.FixedWidthTd>
						<Table.FixedWidthTd cellWidth={EnvironmentColSizes.SECRET}>
							<Table.NoLabelDataWrapper wordBrake="break-all" justifyOption="flex-start">
								<ColumnedFlex>
									<Table.TdInsideTitle>Secret</Table.TdInsideTitle>
									{environment.secret}
								</ColumnedFlex>
							</Table.NoLabelDataWrapper>
						</Table.FixedWidthTd>
						<Table.FixedWidthTd cellWidth={EnvironmentColSizes.ACTIONS}>
							<Table.DataWrapper data-label="Actions">
								<Table.SettingsIcon onClick={toggleSettingsVisibility(environment, index)}>
									<ReactSVG src="/files/svg/private/TableSettings.svg" />
								</Table.SettingsIcon>
								{isSettingsOpened[index] && (
									<Table.HiddenSettings>
										<Link
											to={
												activeTab === 'dagcoin'
													? `environments/detail/${environment.integrationType}/${environment.id}`
													: `${RoutesUrls.TATUM_ENVIRONMENT_DETAIL}/${environment.id}`
											}
										>
											<Table.HiddenSettingsItem>
												<ReactSVG src="/files/svg/private/Edit.svg" />
												<span>Edit</span>
											</Table.HiddenSettingsItem>
										</Link>

										<button key={index} onClick={(e) => handleOpenModal(index)}>
											<Table.HiddenSettingsItem isDisable>
												<ReactSVG src="/files/svg/private/Delete.svg" />
												<span>Delete</span>
											</Table.HiddenSettingsItem>
										</button>
									</Table.HiddenSettings>
								)}
							</Table.DataWrapper>
						</Table.FixedWidthTd>
					</Table.TrPrimary>
				</tbody>
			</Table>
		);
	}

	function getDeleteModal() {
		if (activeIndex === null || activeEnvironment === null) {
			return null;
		}

		const createdByAnotherUser = activeViewer && activeViewer.id !== activeEnvironment.creatorId;

		return (
			<Modal
				isOpen={isModalOpen[activeIndex]}
				ariaHideApp={false}
				onRequestClose={() => handleCloseModal(activeIndex)}
				small
				hasCloseButton
			>
				<EscrowSupportModalWrapper>
					<h1>Delete environment?</h1>
					<p>
						You are about to delete the environment <strong>{activeEnvironment.name}</strong>
						{createdByAnotherUser && (
							<span>
								, created by another user <strong>{activeEnvironment.creator.firstName}</strong>{' '}
								<strong>{activeEnvironment.creator.lastName}</strong>.
							</span>
						)}
					</p>
					<ModalButtonWrapper>
						<Button.Secondary white onClick={() => handleCloseModal(activeIndex)}>
							Cancel
						</Button.Secondary>
						<Button.Secondary
							type="button"
							red
							onClick={() => handleDeleteEnvironment(activeEnvironment.id, activeIndex)}
						>
							Delete
						</Button.Secondary>
					</ModalButtonWrapper>
				</EscrowSupportModalWrapper>
			</Modal>
		);
	}

	function toggleSettingsVisibility(
		environment: {
			name: string;
			integrationType: EnvironmentTypeInList;
			id: string;
			userId: string;
			secret: string;
			creatorId: string;
			creator: {
				firstName: string;
				lastName: string;
			};
		},
		index: number,
	) {
		return (_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent) => {
			const isSettingsOpenedCopy = isSettingsOpened.slice();

			isSettingsOpenedCopy[index] = !isSettingsOpened[index];

			setIsSettingsOpened(isSettingsOpenedCopy);
			setActiveEnvironment(environment);
			setActiveIndex(index);
		};
	}

	function handlePageChanged(newPage: number) {
		setPage(newPage);
	}

	function handleRedirectToCreateEnvironment() {
		if (activeTab === 'dagcoin') {
			return props.history.push(`environments/choose-environment-type`);
		}

		return props.history.push(RoutesUrls.CHOOSE_TATUM_ENVIRONMENT_TYPE);
	}

	function handleOpenModal(index: number) {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = true;
		setIsModalOpen(isModalOpenCopy);
	}

	function handleCloseModal(index: number) {
		const isModalOpenCopy = isModalOpen.slice();
		isModalOpenCopy[index] = false;
		setIsModalOpen(isModalOpenCopy);
	}

	async function handleDeleteEnvironment(environmentId: string, index: number) {
		const isModalOpenCopy = isModalOpen.slice();

		try {
			const response =
				activeTab === 'dagcoin'
					? await deleteEnvironment({ environmentId })
					: await deleteTatumEnvironment({ environmentId });

			isModalOpenCopy[index] = false;
			setIsModalOpen(isModalOpenCopy);

			if (response && response.success) {
				setIsModalOpen(isModalOpenCopy);

				toast.success('Environment successfully deleted');
			}
		} catch (e) {
			// setErrorMessage(e);
			toast.error(e);

			return;
		}
	}

	function handleActiveTab(newTab: 'dagcoin' | 'tatum') {
		if (activeTab === newTab) {
			return;
		}

		props.history.push(`${RoutesUrls.ENVIRONMENTS}?page=${page}&tab=${newTab}`);
	}

	function integrationsTabs() {
		return (
			<EnvironmentTabsWrapper>
				<EnvironmentTab onClick={() => handleActiveTab('dagcoin')} isActive={activeTab === 'dagcoin'}>
					Dagcoin
				</EnvironmentTab>
				<EnvironmentTab onClick={() => handleActiveTab('tatum')} isActive={activeTab === 'tatum'}>
					Tatum
				</EnvironmentTab>
			</EnvironmentTabsWrapper>
		);
	}
}

export default withRouter(EnvironmentsListView);
