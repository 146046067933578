import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export const QrWrap = styled.div`
	position: relative;

	${media.fromSize481`
		margin: auto auto 10px;
	`}

	${media.fromSize1000`
		margin: 0;
	`}
`;

export const QrCode = styled.div<{ isDarkBackground: boolean; isLoading?: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 164px;
	height: 167px;
	margin: 10px auto 10px;
	padding: 20px;
	border: 8px solid #e5e6e8;
	border-radius: 8px;
	opacity: 0.75;

	${(props) =>
		props.isDarkBackground &&
		css`
			border: none;
			opacity: 1;
		`}

	&::before,
	&::after {
		content: '';
		position: absolute;
		background-color: ${Color.WHITE};

		${(props) =>
			props.isDarkBackground &&
			css`
				background-color: transparent;
			`}

		${(props) =>
			props.isLoading &&
			css`
				background-color: transparent;
			`}
	}

	&::before {
		height: calc(100% + 20px);
		width: 50%;
		top: -10px;
		left: 25%;
	}

	&::after {
		height: 50%;
		width: calc(100% + 20px);
		top: 25%;
		left: -10px;
	}

	> img {
		position: relative;
		z-index: 1;
		opacity: 1;
		border-radius: 4px;

		${media.maxSize481`
			min-width: 160px;
			height: 160px;
		`}

		${media.fromSize1000`
			min-width: 147px;
			height: 149px;
		`}
	}
`;

export const LogoWrap = styled.div`
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		width: 46px;
		height: 46px;
	}
`;

export const LoadingQr = styled.div<{ isDarkBackground?: boolean }>`
	height: 180px;
	width: 180px;
	background-color: ${(props) => (props.isDarkBackground ? Color.WHITE : Color.GRAY_4)};
	border-radius: 4px;
	opacity: 0.5;
`;
