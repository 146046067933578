import React from 'react';
import styled, { css, StyledComponentProps } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export interface StyledPanelProps { }

export enum PanelSectionType {
	FORM = 'FORM',
	BUTTON = 'BUTTON',
}

interface SectionProps {
	white?: boolean;
	gray?: boolean;
	first?: boolean;
	middle?: boolean;
	last?: boolean;
	noBorder?: boolean;
	panelType?: PanelSectionType;
	separateWithBorder?: boolean;
}

const StyledPanel = styled.div<StyledPanelProps>`
	height: 100%;
	width: 100%;
`;

// this list should not contain back button
const ActionButtonsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	align-items: center;
	justify-content: flex-end;

	/* all except first */
	& > :nth-child(n + 2) {
		margin-left: 10px;

		${media.maxSize481`
			margin-left: 0;
		`}
	}

	${media.maxSize481`
		justify-content: flex-end;
	`}
`;

const Section = styled.div<SectionProps>`
	border: 1px solid ${Color.GRAY_5};
	border-style: ${(props) =>
		props.noBorder
			? 'none'
			: props.separateWithBorder
				? 'solid'
				: props.first
					? 'solid solid none'
					: props.middle
						? 'none solid'
						: props.last
							? 'none solid solid'
							: 'solid'};
	border-radius: ${(props) =>
		props.noBorder ? 0 : props.first ? '4px 4px 0 0' : props.middle ? 0 : props.last ? '0 0 4px 4px' : '4px'};
	background: ${(props) => (props.white ? Color.WHITE : props.gray ? Color.GRAY_2 : 'transparent')};
	padding: ${(props) =>
		props.panelType === PanelSectionType.FORM
			? '50px 36px 54px'
			: props.panelType === PanelSectionType.BUTTON
				? '15px 20px'
				: 0};

	${(props) =>
		props.panelType === PanelSectionType.BUTTON &&
		css`
			display: flex;

			/* direct child back link */
			& > a {
				margin-right: auto;

				${media.maxSize481`
					margin: 10px 0 0 0;
					justify-self: center;
				`}
			}
			${media.maxSize481`
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-end;
			`}
			@media(max-width: 350px)
			{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				width:100%;
				row-gap: 1rem;
			}
		`}

	${(props) =>
		props.panelType === PanelSectionType.FORM &&
		css`
			${media.maxSize481`
				padding: 24px 20px 54px;
			`}
		`}
`;

const PanelNew = (props: StyledComponentProps<'div', StyledPanelProps, StyledPanelProps, never>) => (
	<StyledPanel {...props} />
);

PanelNew.Section = Section;
PanelNew.ActionButtonsWrapper = ActionButtonsWrapper;

export default PanelNew;
