import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import ReactHookForm from '../../../../components/react-hook-form/ReactHookFormStyle';
import { FaqLinks, Validations } from '../../../../constants';
import { MaximizedText, ContentTitle, SecondaryViewWrapper } from '../ExternalInvoiceViewStyle';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { ExternalInvoiceCurrency } from '../../../../api/externalInvoiceApi';
import { UpdateContentName } from '../ExternalInvoiceView';
import { withRouter } from 'react-router';
import { RouteProps } from '../../../../typings';
import * as qs from 'query-string';
import { toast } from 'react-toastify';
import { ButtonWrapper, DetailsWrapper, FormWrapper } from './ExternalInvoiceDetailsViewStyle';
import ExternalInvoiceHeader from '../../../../components/ext-invoice-header/ExternalInvoiceHeader';

export interface CustomerDetailsFields {
	name: string;
	email: string;
}

export interface CustomerDetailsProps extends RouteProps {
	extInvoiceId: string | null;
	extInvoiceCustomerName: string | null;
	extInvoiceCustomerEmail: string | null;
	extInvoicePaymentCurrency: ExternalInvoiceCurrency | null;
	showHeader?: boolean;
}

const ExternalInvoiceDetailsView = (props: CustomerDetailsProps) => {
	const { extInvoiceId, extInvoiceCustomerEmail, extInvoiceCustomerName, extInvoicePaymentCurrency, showHeader } =
		props;

	const { isExternalInvoiceLoading } = useStoreState((state) => ({
		isExternalInvoiceLoading: state.externalInvoice.isLoading,
	}));

	const { updateExternalInvoice } = useStoreActions((actions) => ({
		...actions.externalInvoice,
	}));

	const schema = Yup.object<CustomerDetailsFields>().shape({
		name: Yup.string()
			.required('Name is required')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true)
			.max(100, 'Name of limit should not be longer than 100 characters'),
		email: Validations.INVALID_EMAIL,
	});

	const defaultValues: CustomerDetailsFields = {
		name: extInvoiceCustomerName || '',
		email: extInvoiceCustomerEmail || '',
	};

	const methods = useForm<CustomerDetailsFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldFocusError: false,
	});

	// for changing continue button color
	const nameIssue = methods.watch().name.length === 0 || !!methods.errors.name;
	const emailIssue = methods.watch().email.length === 0 || !!methods.errors.email;
	const disableButton = nameIssue || emailIssue || isExternalInvoiceLoading;

	// parse search string
	const { redirectUrl, update: updateContentName }: { redirectUrl?: string; update?: UpdateContentName } = qs.parse(
		props.location.search,
	);

	const getCleanedUrlPath = () => {
		// combine "clean" search string
		const searchString = redirectUrl ? `?${qs.stringify({ redirectUrl })}` : '';

		// join qs string parts
		return `${props.location.pathname}${searchString}`;
	};

	const handleUpdateDetails = async (input: CustomerDetailsFields) => {
		// invoice not loaded
		if (!extInvoiceId) {
			return;
		}

		// same info, pass update, but go back
		if (extInvoiceCustomerEmail === input.email && extInvoiceCustomerName === input.name) {
			props.history.goBack();

			return;
		}

		// attempt to update
		const response = await updateExternalInvoice({
			externalInvoiceId: extInvoiceId,
			customerEmail: input.email,
			customerName: input.name,
			paymentCurrency: extInvoicePaymentCurrency,
		});

		// handle failure response
		if (!response.error) {
			toast.warn('Could not update contact info');

			return;
		}

		// if updating, should be go back instead straight to qr view
		if (updateContentName) {
			props.history.goBack();

			return;
		}

		// remove update search param to get back to pending content
		const cleanedUrlPath = getCleanedUrlPath();

		// add cleaned url to history stack
		props.history.push(cleanedUrlPath);
	};

	return (
		<SecondaryViewWrapper disableStyles={!showHeader}>
			{showHeader && (
				<ExternalInvoiceHeader disableLogoClick hasWhiteBackground helpLink={FaqLinks.externalInvoiceHelp} />
			)}
			<DetailsWrapper>
				<ContentTitle>Enter contact details</ContentTitle>
				<MaximizedText marginTop={10} marginBottom={20}>
					Merchant is requesting the following contact
					<br /> details to proceed with payment
				</MaximizedText>

				<FormWrapper>
					<FormProvider {...methods}>
						<ReactHookForm id="details-form" onSubmit={methods.handleSubmit(handleUpdateDetails)}>
							<HookBaseField label="Name" name="name" placeholder="Full name" />
							<HookBaseField label="Email" name="email" placeholder="Customer email" />
							{/* {errorMessage.length > 0 && <View.Error center>{errorMessage}</View.Error>} */}
						</ReactHookForm>
					</FormProvider>
				</FormWrapper>

				<MaximizedText marginTop={10} marginBottom={20}>
					You will also receive a receipt of the confirmed
					<br /> payment on the entered email address
				</MaximizedText>

				<ButtonWrapper>
					<Button isDisabled={disableButton} disabled={disableButton} type={ButtonTypes.SUBMIT} form="details-form">
						Continue to payment
					</Button>
				</ButtonWrapper>
			</DetailsWrapper>
		</SecondaryViewWrapper>
	);
};

export default withRouter(ExternalInvoiceDetailsView);
