import styled from 'styled-components/macro';

import { Color, Gutter } from '../../../../../../gfx/constants';
import { media } from '../../../../../../services/media';
import StyledField from '../../../../../../components/field/FieldStyle';

export const EnvironmentNotificationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;
	margin-right: ${Gutter.SMALL};
`;

export const EnvironmentNameWrapper = styled.div`
	display: flex;
	font-family: PrimaryMedium;

	${media.maxTable`
		margin-top: 15px;
		flex-direction: column;
		width: fit-content;

		${StyledField.Optional}{
			margin: 5px 0 0 0;

		}
	`}
`;

export const EnvironmentLoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
`;

export const EnvironmentTabsWrapper = styled.div`
	display: flex;
	padding: 0 15px;
	align-items: center;
	border-bottom: 1px solid ${Color.GRAY_5};
`;

export const EnvironmentTab = styled.div<{ isActive: boolean }>`
	margin-right: 20px;
	padding-bottom: 15px;
	color: ${Color.GRAY_8};
	font-family: ${({ isActive }) => (isActive ? 'PrimaryMedium' : 'Primary')};
	border-bottom: ${({ isActive }) => (isActive ? `2px solid ${Color.GREEN_3}` : '2px solid transparent')};
`;
