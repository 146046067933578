import Image from 'react-image-webp';
import styled, { css } from 'styled-components/macro';

import Button from '../../components/button/Button';
import StyledField from '../../components/field/FieldStyle';
import Modal from '../../components/modal/Modal';
import {
	fadeInAnimation,
	jumpDownAnimation,
	jumpUpAnimation,
	moveAnimation,
	pendingCircleAnimation,
	progressLineAnimation,
	showCompleteIconAnimation,
	successCircleAnimation,
	successProgressLineAnimation,
	successTickAnimation,
} from '../../gfx/animations';
import { Color, Gutter, Shadow } from '../../gfx/constants';
import { media } from '../../services/media';

// Interfaces

interface PaymentSentHeaderTextProps {
	isConfirmed?: boolean;
	isFailed?: boolean;
}

interface StyledSvgProps {
	isConfirmed?: boolean;
	isError?: boolean;
	isAnimationDisabled?: boolean;
}

interface StyledDiscountProps {
	isDiscountEnabled?: boolean;
	isOtherCurrencyEnabled?: boolean;
}
// Components

export const PayContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	${media.maxSize800`
		margin:0;
	`}
`;

export const DescriptionText = styled.p<{ paddingTop?: string }>`
	color: #444444;
	font-family: Secondary;
	font-size: 14px;
	line-height: 20px;
	margin: 10px 45px 15px;
	text-align: center;

	${(props) =>
		props.paddingTop &&
		css`
			padding-top: ${props.paddingTop};
		`};
`;

export const PayWrapper = styled.div`
	width: 100%;
	height: 100%;

	${media.fromSize800`
		display: flex;
		border-radius: 4px;
		width: 720px;
		min-height: 580px;
		max-width: calc(100% - 40px);
		max-height: calc(100% - 40px);
		margin: ${Gutter.SMALL} 0;
		box-shadow: 0 12px 21px 8px rgba(0, 0, 0, 0.2);
	`}
`;

export const PaySidebar = styled.div`
	background: ${Color.BLUE_8};
	display: flex;
	flex-direction: column;
	position: relative;
	color: ${Color.WHITE};
	padding: 31px;
	min-width: 280px;
	overflow: hidden;

	svg {
		fill: ${Color.GREEN_3};
	}

	${media.maxSize800`
		padding: 20px;
		height: 100vh;
	`}
`;

export const PayContent = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	/* height: 100%;
	width: 100%; */
	background: #fdfdfd;
	padding: 35px 56px 35px;

	.react-reveal {
		width: 100%;
		max-width: 100%;
	}

	.modalContent .Modal__Content-sc-1x1idws-0 {
		margin-left: 0;
		margin-right: 0;
		padding: 25px 34px 60px;
	}

	${Modal.Wrapper} {
		padding: 50px;
	}
`;

export const PayContentBox = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	height: 100%;
	width: 100%;

	${StyledField.Error} {
		text-align: center;
	}

	button {
		&:focus {
			outline: none;
		}
	}
`;

export const ContentHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ContentHeaderIconWrapper = styled.div`
	position: relative;
	width: 127px;
	height: 45px;

	div div svg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
`;

export const PosHelpLink = styled.a`
	height: 24px;
	width: 24px;
	opacity: 0.5;
	cursor: pointer;
`;

export const PaymentDagPayLogo = styled.div`
	position: absolute;
	bottom: 40px;
	left: 40px;
	color: ${Color.GRAY_MENULINK};
	font-size: 15px;
	line-height: 20px;

	${media.maxSize800`
		display: none;
	`}
`;

export const PaymentHourGlassLoader = styled.div`
	padding-left: 10px;
`;

export const PaymentAmountInfoBlock = styled.div`
	padding-top: 68px;
	padding-bottom: 10px;

	${media.maxSize800`
		display: flex;
		padding-top: 20px;
		padding-bottom: 20px;
	`}
`;

export const PaymentInfoBlock = styled.div`
	margin-bottom: 26px;
	${media.maxSize800`
		display: flex;
		align-items: center;
		margin-bottom: ${Gutter.SMALL};
	`}
`;

export const PaymentExpirationBlock = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: auto;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	letter-spacing: 0.09px;
	line-height: 23px;

	${media.maxSize800`
		margin-top: auto;
		padding-top: 0;
	`}
`;

export const PaymentAmountText = styled.div`
	padding-left: 10px;
	font-size: 16px;
	line-height: 21px;
`;

export const PaymentInfoLabelText = styled.div`
	padding-left: 15px;
	font-size: 16px;
	line-height: 21px;
	font-family: RobotoLight;
`;

export const PaymentDagAmount = styled.div`
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
`;

export const PaymentCurrencyAmount = styled.div`
	position: absolute;
	bottom: 5px;
	opacity: 0.65;
	padding-left: 30px;
	font-size: 14px;
	line-height: 24px;
	${media.maxSize800`
		bottom: -15px;
	`}
`;

export const PaymentAmountBlock = styled.div<StyledDiscountProps>`
	padding-left: 25px;
	display: flex;
	flex-direction: column;
	position: relative;
	padding-top: ${(props) => (props.isDiscountEnabled ? '34px' : '10px')};
	${media.maxSize800`
		padding-top: 0;
		padding-left: 15px;
	`}
	padding-bottom: ${(props) => (props.isOtherCurrencyEnabled ? '34px' : '20px')};
	${media.maxSize800`
		padding-bottom: 0;
	`}
`;

export const PaymentInfoText = styled.div`
	padding-left: 32px;
	font-size: 16px;
	font-weight: 500;
	line-height: 21px;
	font-family: PrimaryMedium;

	white-space: nowrap;
	max-width: 230px;
	overflow: hidden;
	text-overflow: ellipsis;

	${media.maxSize800`
		padding-left: 27px;
	`}
`;

export const PaymentCancelLink = styled.div`
	color: ${Color.WHITE};
	line-height: 19px;
	font-weight: 500;
	font-size: 14px;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		color: ${Color.RED_2};
	}
`;

export const PaymentReceiptFocussableTitle = styled.span`
	color: ${Color.ORANGE};
	position: absolute;
	font-size: 11px;
	left: 1px;
	padding-left: 1.35rem;
	top: 4px;
	animation: ${fadeInAnimation};
	display: none;
`;

export const BoxHeader = styled.div`
	color: ${Color.BLUE_10};
	font-size: 18px;
	font-family: PrimaryMedium;
	line-height: 24px;
	text-align: center;

	@media (max-width: 475px) {
		padding-top: 20px;
	}
`;

export const PaymentSentHeaderText = styled.div<PaymentSentHeaderTextProps>`
	padding-top: 30px;
	font-size: 18px;
	line-height: 21px;
	text-align: center;

	@media (max-width: 320px) {
		padding-top: -1px;
	}

	span:nth-child(1) {
		color: ${Color.ORANGE};
		display: inline-block;
		font-weight: 500;

		${(props) =>
			props.isConfirmed &&
			css`
				font-weight: bold;
				animation: ${jumpUpAnimation};
				opacity: 0;
				position: absolute;
			`}

		${(props) =>
			props.isFailed &&
			css`
				color: ${Color.RED_2};
				font-weight: bold;
			`}
	}

	span:nth-child(2) {
		color: ${Color.BLUE_10};
		display: inline-block;
		font-weight: 500;

		${(props) =>
			props.isConfirmed &&
			css`
				color: ${Color.GREEN_3};
				font-weight: bold;
				animation: ${jumpDownAnimation};
			`}

		${(props) =>
			props.isFailed &&
			css`
				color: ${Color.RED_2};
				font-weight: bold;
			`}
	}
`;

export const AwaitingPaymentText = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	padding-top: 18px;
`;

export const QrWrap = styled.div`
	color: #444444;
	font-family: Secondary;
	font-size: 14px;
	padding-top: 33px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		position: relative;
		z-index: 1;
		border-radius: 5px;
		overflow: hidden;
		border: 1px solid ${Color.WHITE};
	}
`;

export const QrWrapperMaxTablet = styled.div`
	position: relative;
	margin: 0 auto auto;
`;

export const QrCode = styled.div<{ isPosInvoice?: boolean }>`
	position: relative;
	display: inline-block;
	padding: 20px;
	opacity: 0.75;

	${(props) =>
		props.isPosInvoice &&
		css`
			${media.maxSize800`
			border: 8px solid #dcdde0;
			border: 8px solid rgba(220, 221, 224, 0.1);
	`}
		`}

	${media.maxSize800`
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 10px auto auto;
	`}

	${media.maxMobile`
		width: 155px;
		height: 155px;
	`}

	${media.fromSize800`
		width: 180px;
		height: 180px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 10px auto auto;
	`}

	&::before,
	&::after {
		content: '';
		position: absolute;
		background-color: ${Color.WHITE};

		${(props) =>
			props.isPosInvoice &&
			css`
				background-color: ${Color.BLUE_8};
			`}
	}

	&::before {
		height: calc(100% + 20px);
		width: 50%;
		top: -10px;
		left: 25%;
	}

	&::after {
		height: 50%;
		width: calc(100% + 20px);
		top: 25%;
		left: -10px;
	}

	img {
		position: relative;
		z-index: 1;
		opacity: 1;
		box-shadow: inset 0 9px 3px 1px rgba(0, 0, 0, 0.2);
		border: 1px solid ${Color.GRAY_5};
		border-radius: 4px;

		${media.maxMobile`
			min-width: 120px;
			height: 120px;
		`}

		${media.fromSize800`
			min-width: 180px;
			height: 180px;
		`}
	}
`;

export const DagcoinCardButton = styled(Button)`
	align-self: center;
	position: absolute;
	bottom: 33px;
	background-color: ${Color.BLUE_8};
	margin-top: 20px;
	width: 230px;

	:hover {
		box-shadow: ${Shadow.BLUE};
	}
`;

export const Icon = styled.span`
	margin-right: 8px;
`;
export const Message = styled.div`
	font-size: 12px;
	line-height: 16px;
`;

export const QrScanner = styled.span`
	position: absolute;
	left: calc(50% - 121px);
	top: 0;
	width: 240px;
	opacity: 0.5;
	border-radius: 5px;
	height: 10px;
	background-color: #43d354;
	z-index: 1;
	transform: translateY(135%);
	animation: ${moveAnimation};
	animation-iteration-count: infinite;
`;

export const CancelPaymentWrapper = styled.div`
	text-align: center;
	margin-top: ${Gutter.SMALL};

	${media.fromSize800`
		margin-top: auto;
	`}
`;

export const CompleteArrow = styled.div`
	animation: ${showCompleteIconAnimation};
`;

export const PaymentWaitingImage = styled(Image)`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 170px;
	height: 170px;
	border-radius: 50%;
	background: #f3f3f3;
	transform: translate(-50%, -50%);
`;

export const StyledBackgroundSvg = styled.div<StyledSvgProps>`
	position: relative;
	min-height: 255px;
	#pendingBottomCircle {
		fill: white;
		stroke: #dfe0e3;
		animation: ${pendingCircleAnimation};

		${(props) =>
			props.isConfirmed &&
			css`
				fill: 'null';
				stroke: 'null';
				animation: '';
			`}

		${(props) =>
			props.isError &&
			css`
				stroke: 'null';
				animation: '';
			`}
	}

	#upCircle {
		fill: ${Color.ORANGE};
		stroke: ${Color.ORANGE};

		${(props) =>
			props.isConfirmed &&
			css`
				fill: ${Color.GREEN_3};
				stroke: ${Color.GREEN_3};
			`}
	}

	#successCircle {
		circle {
			opacity: 0;
			animation: '';

			${(props) =>
				props.isConfirmed &&
				css`
					opacity: 1;
				`}

			${(props) =>
				props.isConfirmed &&
				!props.isAnimationDisabled &&
				css`
					animation: ${successCircleAnimation};
				`}
		}

		polyline {
			opacity: 0;
			animation: '';

			${(props) =>
				props.isConfirmed &&
				css`
					opacity: 1;
				`}

			${(props) =>
				props.isConfirmed &&
				!props.isAnimationDisabled &&
				css`
					animation: ${successTickAnimation};
				`}
		}
	}

	#errorCircle {
		opacity: 0;

		${(props) =>
			props.isError &&
			css`
				opacity: 1;
			`}
	}
`;

export const StyledProgressSvg = styled.div<StyledSvgProps>`
	position: absolute;
	z-index: 10;
	left: 50%;
	width: 250px;
	height: 250px;
	transform: translateX(-50%) rotate(-225deg) scaleX(-1);

	svg {
		border-radius: 50%;

		circle {
			opacity: 1;
			fill: transparent;
			stroke: ${Color.ORANGE};
			stroke-width: 0.4px;
			stroke-dasharray: 0 100;

			${(props) =>
				props.isAnimationDisabled &&
				css`
					animation: none;
					stroke-dasharray: 61 100;
				`}

			${(props) =>
				props.isConfirmed &&
				css`
					animation: ${successProgressLineAnimation};
					stroke: ${Color.GREEN_3};
				`}

			${(props) =>
				!props.isConfirmed &&
				css`
					animation: ${progressLineAnimation};
				`}
			${(props) =>
				props.isError &&
				css`
					opacity: 0;
				`}
		}
	}

	div {
		height: 100%;
		width: 100%;
		min-width: 100%;
		min-height: 100%;
	}
`;

export const InvoiceDiscount = styled.p`
	position: absolute;
	top: 10px;
	left: 28px;
	font-size: 14px;
	color: ${Color.GREEN_3};
	font-family: Primary;
	padding: 0 5px;
	margin: 0px;
	white-space: nowrap;

	${media.maxSize800`
		padding: 0;
		margin: 0 ;
		font-size: 12px;
		top: -15px;
	`}
`;
