import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import handleFetch from '../services/handle-fetch';
import getExternalInvoiceGroupedStatus from '../services/get-external-invoice-grouped-status';
import { Response } from '../typings';

import { StoreModel } from '.';
import {
	cancelExternalInvoiceApi,
	CancelExternalInvoiceRequest,
	expireExternalInvoiceApi,
	ExpireExternalInvoiceRequest,
	ExternalInvoice,
	getExternalInvoiceApi,
	GetExternalInvoiceRequest,
	pollExternalInvoiceBalanceApi,
	PollExternalInvoiceBalanceRequest,
	PollExternalInvoiceBalanceResponse,
	updateExternalInvoiceApi,
	UpdateExternalInvoiceRequest,
} from '../api/externalInvoiceApi';
import { ExternalInvoiceGroupedStatus } from '../services/get-external-invoice-grouped-status';

export interface ExternalInvoiceModel {
	// state
	externalInvoice: ExternalInvoice | null;
	status: ExternalInvoiceGroupedStatus | null;
	isLoading: boolean;

	// actions
	saveExternalInvoice: Action<ExternalInvoiceModel, ExternalInvoice | null>;
	clearExternalInvoice: Action<ExternalInvoiceModel>;
	setExternalInvoiceGroupedStatus: Action<ExternalInvoiceModel, ExternalInvoiceGroupedStatus>;
	setExternalInvoiceInvoiceReceivedAmount: Action<ExternalInvoiceModel, number>;
	setIsLoading: Action<ExternalInvoiceModel, boolean>;

	// thunks
	pollExternalInvoiceBalace: Thunk<
		ExternalInvoiceModel,
		PollExternalInvoiceBalanceRequest,
		{},
		StoreModel,
		Response<PollExternalInvoiceBalanceResponse>
	>;
	getExternalInvoice: Thunk<ExternalInvoiceModel, GetExternalInvoiceRequest, {}, StoreModel, Response<ExternalInvoice>>;
	cancelExternalInvoice: Thunk<
		ExternalInvoiceModel,
		CancelExternalInvoiceRequest,
		{},
		StoreModel,
		Response<ExternalInvoice>
	>;
	expireExternalInvoice: Thunk<
		ExternalInvoiceModel,
		ExpireExternalInvoiceRequest,
		{},
		StoreModel,
		Response<ExternalInvoice>
	>;
	updateExternalInvoice: Thunk<
		ExternalInvoiceModel,
		UpdateExternalInvoiceRequest,
		{},
		StoreModel,
		Response<ExternalInvoice>
	>;

	// listeners
	reloadOn: ThunkOn<ExternalInvoiceModel, {}, StoreModel>;
}

const externalInvoice: ExternalInvoiceModel = {
	// state
	externalInvoice: null,
	status: null,
	isLoading: false,

	// actions
	saveExternalInvoice: action((state, payload) => {
		state.externalInvoice = payload;
	}),
	clearExternalInvoice: action((state) => {
		state.externalInvoice = null;
	}),
	setExternalInvoiceGroupedStatus: action((state, payload) => {
		state.status = payload;
	}),
	setExternalInvoiceInvoiceReceivedAmount: action((state, payload) => {
		if (state.externalInvoice) {
			state.externalInvoice.invoiceReceivedAmount = payload;
		}
	}),
	setIsLoading: action((state, payload) => {
		state.isLoading = payload;
	}),

	// thunks
	pollExternalInvoiceBalace: thunk(async (actions, payload) => {
		const result = await handleFetch<PollExternalInvoiceBalanceResponse>(pollExternalInvoiceBalanceApi(payload));

		return result;
	}),
	getExternalInvoice: thunk(async (actions, payload) => {
		actions.setIsLoading(true);

		actions.clearExternalInvoice();
		const result = await handleFetch<ExternalInvoice>(getExternalInvoiceApi(payload));

		actions.saveExternalInvoice(result.payload);

		// set grouped status on success
		if (result.payload && result.success) {
			actions.setExternalInvoiceGroupedStatus(getExternalInvoiceGroupedStatus(result.payload.status));
		}

		// job complete, end loading
		actions.setIsLoading(false);

		return result;
	}),
	cancelExternalInvoice: thunk(async (actions, payload) => {
		// listener triggers getExternalInvoice, which sets loading to false
		actions.setIsLoading(true);

		const result = await handleFetch<ExternalInvoice>(cancelExternalInvoiceApi(payload));

		// set grouped status on success
		if (result.payload && result.success) {
			actions.setExternalInvoiceGroupedStatus(ExternalInvoiceGroupedStatus.CANCELLED);
		}

		return result;
	}),
	expireExternalInvoice: thunk(async (actions, payload) => {
		// listener triggers getExternalInvoice, which sets loading to false
		actions.setIsLoading(true);

		const result = await handleFetch<ExternalInvoice>(expireExternalInvoiceApi(payload));

		// set grouped status on success
		if (result.payload && result.success) {
			actions.setExternalInvoiceGroupedStatus(ExternalInvoiceGroupedStatus.EXPIRED);
		}

		return result;
	}),
	updateExternalInvoice: thunk(async (actions, payload) => {
		// listener triggers getExternalInvoice, which sets loading to false
		actions.setIsLoading(true);

		const result = await handleFetch<ExternalInvoice>(updateExternalInvoiceApi(payload));

		return result;
	}),

	// listeners
	reloadOn: thunkOn(
		(actions, storeActions) => [
			actions.cancelExternalInvoice,
			actions.expireExternalInvoice,
			actions.updateExternalInvoice,
		],
		(actions, payload) => {
			actions.getExternalInvoice(payload.payload);
		},
	),
};

export default externalInvoice;
