import styled from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';
import { RadioProp } from './HookVerticalRadioGroup';

export const Row = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 10px;
	margin-bottom: 12px;

	${media.maxSize481`
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	`}
`;

export const HiddenControl = styled.input`
	position: absolute;
	opacity: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;

export const RadioFieldWrapper = styled.div`
	height: 50px;
	position: relative;
	display: flex;
	align-items: center;

	${media.maxSize481`
		height: auto;
	`}
`;

export const RadioButtonsContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	label {
		font-size: 12px;
		outline: 1px ${Color.GREY_BORDER} solid;
	}
	label:first-child {
		border-radius: 5px 0 0 5px;
	}
	label:last-child {
		border-radius: 0 5px 5px 0;
	}

	${media.maxSize481`
		flex-direction: column;
		width: 100%;
		label:first-child {
			border-radius: 5px 5px 0 0;
		}
		label:last-child {
			border-radius: 0 0 5px 5px;
		}
	`}
`;
export const RadioButton = styled.span`
	position: relative;
	text-align: center;
	align-self: center;
`;

export const RadioLabel = styled.label<RadioProp>`
	cursor: pointer;
	display: flex;
	justify-content: center;
	color: #17203c;
	background: ${({ selected, disabled }) =>
		disabled && selected ? Color.GRAY_BUTTON : selected ? Color.GREEN_3 : Color.WHITE};
	outline: ${({ selected, disabled }) => {
		if (selected && !disabled) {
			return `1px ${Color.GREEN_3} solid`;
		}
	}};

	span {
		font-family: primary;
		font-size: 16px;
		line-height: 19px;
		color: ${({ selected }) => (selected ? Color.WHITE : Color.GRAY_9)};
		padding: 9px 16px;
		white-space: nowrap;

		${media.maxTransactionTable`
			padding: 8px 12px;

		`}
	}

	${media.maxSize481`
		width: 100%;
	`}
`;
