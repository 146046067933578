import { Action, action, thunk, Thunk } from 'easy-peasy';

import { getSchemaApi, Schema } from '../api/schemaApi';

import { StoreModel } from '.';

export interface SchemaModel {
	// state
	schema: Schema | null;

	// actions
	save: Action<SchemaModel, Schema | null>;

	// thunks
	getSchema: Thunk<SchemaModel, undefined, {}, StoreModel, Promise<Schema>>;
}

const schema: SchemaModel = {
	// state
	schema: null,

	// actions
	save: action((state, payload) => {
		state.schema = payload;
	}),

	// thunks
	getSchema: thunk(async (actions) => {
		const result = await getSchemaApi();

		actions.save(result);

		return result;
	}),
};

export default schema;
