import dayjs from 'dayjs';
import * as qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';

import { ExtendedInvitedUser, InvitedUsersCount } from '../../../../api/inviteApi';
import ActionModal from '../../../../components/action-modal/ActionModal';
import Button from '../../../../components/button/Button';
import Loader from '../../../../components/loader/Loader';
import Notification from '../../../../components/notification/Notification';
import Pager from '../../../../components/pager/Pager';
import Table from '../../../../components/table/Table';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import {
	GLOBAL_DATE_FORMAT,
	InvitedUsersFilterStatus,
	InvitedUserStatus,
	InviteStatus,
	ITEMS_PER_PAGE,
	RoutesUrls,
	Scopes,
	UserRole,
} from '../../../../constants';
import ColoredInvitedUserState from '../../../../components/colored-invited-user-status/ColoredInvitedUserStatus';
import { Avatar, ColumnedFlex, H1, JustifiedLeftFlex } from '../../../../gfx/globals';
import isParentVerified from '../../../../services/is-parent-verified';
import isUserVerified from '../../../../services/is-user-verified';
import isWalletAdded from '../../../../services/is-wallet-added';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { ApiArrayPayload, RouteProps } from '../../../../typings';

import {
	BulletMarker,
	UsersFilterLink,
	UsersFilterLinkForMobile,
	UsersFilters,
	UserFilterCurrent,
	UserFilterWrapper,
	UserFilterMobile,
	NavBarWrapper,
	UserManagement,
	RotateArrow,
} from './UsersViewStyle';
import { Color, UserColSizes } from '../../../../gfx/constants';
import assertUnreachable from '../../../../services/assert-unreachable';
import { TitleWrapper } from '../SettingsStyle';
import { NotificationRowWrapper, NotificationText } from '../../../../gfx/globals';

interface HandleOpenModalArgs {
	index: number;
	modalType: UserListViewModalType;
}

let UserStatusFilters = [
	{
		title: 'All users',
		status: InvitedUsersFilterStatus.ALL,
		color: Color.GRAY_12,
	},
	{
		title: 'Active',
		status: InvitedUsersFilterStatus.ACTIVE,
		color: Color.GREEN_3,
	},
	{
		title: 'Invited',
		status: InvitedUsersFilterStatus.INVITED,
		color: Color.BLUE_2,
	},
	{
		title: 'Expired',
		status: InvitedUsersFilterStatus.EXPIRED,
		color: Color.ORANGE_1,
	},
	{
		title: 'Inactive',
		status: InvitedUsersFilterStatus.INACTIVE,
		color: Color.GRAY_3,
	},
];

interface ConditionalQueryObject {
	status?: InvitedUserStatus;
}

type UserRoleKeys = { [name in keyof typeof UserRole]: string };

const UserRoleNames: UserRoleKeys = {
	ADMIN: 'Administrator',
	ADVANCED_TEAM_MEMBER: 'Advanced team member',
	TEAM_MEMBER: 'Team member',
	OWNER: 'Administrator',
	POS: 'Pos',
	PENDING_ADMIN: 'Not verified administrator',
};

enum UserListViewModalType {
	ENABLE = 'ENABLE',
	RESEND = 'RESEND',
	DISABLE = 'DISABLE',
	DELETE = 'DELETE',
}

function UsersListView(props: RouteProps) {
	const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
	const [isSettingsOpened, setIsSettingsOpened] = useState<boolean[]>([]);
	const [activeUser, setActiveUser] = useState<ExtendedInvitedUser | null>(null);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [modalType, setModalType] = useState<UserListViewModalType | null>(null);
	const { status: qsStatus, page: qsPage } = qs.parse(props.location.search);
	const [page, setPage] = useState<number>(qsPage && typeof qsPage === 'string' ? parseInt(qsPage) : 1);
	const [isDropDownVisible, setIsDropDownVisible] = useState(false);
	const [wrapperRef] = useState(React.createRef<HTMLDivElement>());

	const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
		const currentElement = event.target;

		if (
			wrapperRef &&
			wrapperRef.current &&
			currentElement instanceof Node &&
			!wrapperRef.current.contains(currentElement)
		) {
			setIsDropDownVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const toggleDropDownVisibility = () => {
		setIsDropDownVisible(!isDropDownVisible);
	};

	// TODO: redo filtering logic to avoid 2x fetching, eg. EscrowListView
	const [activeFilteredStatus, setActiveFilteredStatus] = useState<InvitedUsersFilterStatus | string | string[]>(
		qsStatus || InvitedUsersFilterStatus.ALL,
	);

	const { invitedUserList, activeViewer, invitedUsersStatistics } = useStoreState((state) => ({
		invitedUserList: state.invite.invitedUsers,
		activeViewer: state.viewer.activeViewer,
		invitedUsersStatistics: state.invite.invitedUsersStatistics,
	}));

	const {
		getInvitedUsers,
		resendEmailInvite,
		deleteInvite,
		disableInvitedUser,
		enableInvitedUser,
		getInvitedUsersStatistics,
	} = useStoreActions((actions) => ({
		...actions.invite,
	}));

	type InviteCode = keyof typeof InvitedUserStatus;

	const fetchInvitedUsers = useCallback(
		async (conditionalQueryObject: ConditionalQueryObject) => {
			return await getInvitedUsers({ page, itemsPerPage: ITEMS_PER_PAGE, ...conditionalQueryObject });
		},
		[getInvitedUsers, page],
	);

	const getConditionalQueryObject = () => {
		const { status } = qs.parse(props.location.search);

		return {
			...(status !== InvitedUsersFilterStatus.ALL && { status: InvitedUserStatus[status as InviteCode] }),
		};
	};

	useEffect(() => {
		const fetchInvitedUserStatistics = async () => {
			return await getInvitedUsersStatistics({});
		};
		fetchInvitedUserStatistics();
	}, [getInvitedUsersStatistics]);

	useEffect(() => {
		const { status, page } = qs.parse(props.location.search);
		const conditionalQueryObject = {
			...(status !== InvitedUsersFilterStatus.ALL && { status: InvitedUserStatus[status as InviteCode] }),
		};

		if (page && typeof page === 'string') {
			setPage(parseInt(page, 10));
			fetchInvitedUsers(conditionalQueryObject);
		} else fetchInvitedUsers(conditionalQueryObject);
	}, [fetchInvitedUsers, props.location.search]);

	const getStatusNavigation = (invitedUserList: ApiArrayPayload<InvitedUsersCount>) => (
		<UserFilterWrapper>
			<UserFilterMobile>
				<UserFilterCurrent onClick={toggleDropDownVisibility} ref={wrapperRef}>
					{UserStatusFilters.map((status, index) => (
						<>
							{status.status === activeFilteredStatus && (
								<UsersFilterLink active={status.status === activeFilteredStatus} key={`${status.title}-${index}`}>
									{status.title}{' '}
									<BulletMarker color={status.color}>
										{getUserCountByStatus(invitedUserList, status.status)}
									</BulletMarker>
									{isDropDownVisible ? (
										<RotateArrow>
											<ReactSVG src="/files/svg/private/Arrow.svg" />
										</RotateArrow>
									) : (
										<ReactSVG src="/files/svg/private/Arrow.svg" />
									)}
								</UsersFilterLink>
							)}
						</>
					))}
					{isDropDownVisible && (
						<UsersFilters>
							{UserStatusFilters.map((status, index) => (
								<UsersFilterLinkForMobile
									key={`${status.title}-${index}`}
									onClick={setActiveStatus(status.status)}
									active={status.status === activeFilteredStatus}
								>
									<>
										{status.title}{' '}
										<BulletMarker color={status.color}>
											{getUserCountByStatus(invitedUserList, status.status)}
										</BulletMarker>
									</>
								</UsersFilterLinkForMobile>
							))}
						</UsersFilters>
					)}
				</UserFilterCurrent>
			</UserFilterMobile>
			<UsersFilters>
				{UserStatusFilters.map((status, index) => (
					<UsersFilterLink
						key={`${status.title}-${index}`}
						onClick={setActiveStatus(status.status)}
						active={status.status === activeFilteredStatus}
						underline={status.color}
					>
						<>
							{status.title}{' '}
							<BulletMarker color={status.color}>{getUserCountByStatus(invitedUserList, status.status)}</BulletMarker>
						</>
					</UsersFilterLink>
				))}
			</UsersFilters>
		</UserFilterWrapper>
	);

	const renderUsersRows = (invitedUser: ExtendedInvitedUser, index: number) => {
		const lastLoginDate = invitedUser.lastLoginDate ? dayjs(invitedUser.lastLoginDate).format(GLOBAL_DATE_FORMAT) : '-';
		const name = `${invitedUser.firstName} ${invitedUser.lastName}`;

		const isDeletePossible =
			invitedUser.status === InvitedUserStatus.SENT ||
			invitedUser.status === InvitedUserStatus.EXPIRED ||
			invitedUser.status === InvitedUserStatus.FAILED ||
			invitedUser.status === InvitedUserStatus.UNKNOWN;
		const isDisablePossible = invitedUser.status === InvitedUserStatus.ACTIVE;
		const isEnablePossible = invitedUser.status === InvitedUserStatus.INACTIVE;
		const isEditPossible = invitedUser.status === InvitedUserStatus.ACTIVE;

		const isResendInvitePossible =
			invitedUser.status === InvitedUserStatus.EXPIRED ||
			invitedUser.status === InvitedUserStatus.FAILED ||
			invitedUser.status === InvitedUserStatus.UNKNOWN;

		const areActionDotsVisible =
			invitedUser.role !== UserRole.OWNER &&
			!!activeViewer &&
			activeViewer.id !== invitedUser.userId &&
			activeViewer &&
			activeViewer.role !== UserRole.ADVANCED_TEAM_MEMBER &&
			(isDisablePossible || isEditPossible || isEnablePossible || isResendInvitePossible || isDeletePossible);

		return (
			<Table.TrPrimary
				key={`${invitedUser.userId}-${index}`}
				onMouseLeave={isSettingsOpened[index] ? toggleSettingsVisibility(invitedUser, index) : () => null}
			>
				<Table.UserNameTd data-label="User">
					<JustifiedLeftFlex>
						<Avatar>{name.charAt(0).toUpperCase()}</Avatar>
						<ColumnedFlex>
							<Table.UserName>{name}</Table.UserName>
							<Table.Email>{invitedUser.email}</Table.Email>
						</ColumnedFlex>
					</JustifiedLeftFlex>
				</Table.UserNameTd>

				<Table.FixedWidthTd cellWidth={UserColSizes.ROLE}>
					<Table.DataWrapper data-label="Role">
						{UserRoleNames[invitedUser.role]}
						{invitedUser.role === UserRole.OWNER && <Table.Tag>Owner</Table.Tag>}
					</Table.DataWrapper>
				</Table.FixedWidthTd>

				<Table.FixedWidthTd cellWidth={UserColSizes.STATUS}>
					<Table.DataWrapper data-label="Status">
						<ColoredInvitedUserState state={invitedUser.status} />
					</Table.DataWrapper>
				</Table.FixedWidthTd>

				<Table.FixedWidthTd cellWidth={UserColSizes.LAST_LOGIN} noBeforeElement={!areActionDotsVisible}>
					<Table.DataWrapper data-label="Last login">{lastLoginDate}</Table.DataWrapper>
				</Table.FixedWidthTd>

				<Table.TransparentTd cellWidth={UserColSizes.ACTIONS}>
					{areActionDotsVisible && (
						<Table.DataWrapper data-label="Actions">
							<Table.SettingsIcon onClick={toggleSettingsVisibility(invitedUser, index)}>
								<ReactSVG src="/files/svg/private/TableSettings.svg" />
							</Table.SettingsIcon>

							{isSettingsOpened[index] && (
								<Table.HiddenSettings>
									{isEditPossible && (
										<Link to={`${RoutesUrls.USERS_DETAIL}/${invitedUser.userId}`}>
											<Table.HiddenSettingsItem>
												<ReactSVG src="/files/svg/private/Edit.svg" />
												<span>Edit</span>
											</Table.HiddenSettingsItem>
										</Link>
									)}
									{isResendInvitePossible && (
										<Table.HiddenSettingsItem
											onClick={() => handleOpenModal({ index, modalType: UserListViewModalType.RESEND })}
										>
											<ReactSVG src="/files/svg/private/settings/mail.svg" />
											<span>Resend invite</span>
										</Table.HiddenSettingsItem>
									)}
									{isEnablePossible && (
										<Table.HiddenSettingsItem
											isEnable
											onClick={() => handleOpenModal({ index, modalType: UserListViewModalType.ENABLE })}
										>
											<ReactSVG src="/files/svg/private/Success.svg" />
											<span>Enable</span>
										</Table.HiddenSettingsItem>
									)}
									{isDisablePossible && (
										<Table.HiddenSettingsItem
											isDisable
											onClick={() => handleOpenModal({ index, modalType: UserListViewModalType.DISABLE })}
										>
											<ReactSVG src="/files/svg/private/dashboard/icon-fail.svg" />
											<span>Disable</span>
										</Table.HiddenSettingsItem>
									)}
									{isDeletePossible && (
										<Table.HiddenSettingsItem
											isDelete
											onClick={() => handleOpenModal({ index, modalType: UserListViewModalType.DELETE })}
										>
											<ReactSVG src="/files/svg/private/Delete.svg" />
											<span>Delete</span>
										</Table.HiddenSettingsItem>
									)}
								</Table.HiddenSettings>
							)}
						</Table.DataWrapper>
					)}
				</Table.TransparentTd>
			</Table.TrPrimary>
		);
	};

	const renderActionModal = () => {
		if (activeIndex === null || activeUser === null || modalType === null) {
			return null;
		}

		let action: () => void;
		let title;
		let actionTitle;
		let notification;
		let notificationStrong;
		let isRed;

		switch (modalType) {
			case UserListViewModalType.ENABLE:
				action = () => handleEnableUser(activeUser, activeIndex);
				title = 'Enable account?';
				actionTitle = 'Enable';
				notification = 'You are about to enable account for ';
				notificationStrong = `${activeUser.firstName} ${activeUser.lastName}`;
				isRed = false;
				break;

			case UserListViewModalType.RESEND:
				action = () => handleResendInvite(activeUser, activeIndex);
				title = 'Resend email invitation?';
				actionTitle = 'Resend';
				notification = 'You are about to resend email invitation to ';
				notificationStrong = `${activeUser.firstName} ${activeUser.lastName}`;
				isRed = false;
				break;
			case UserListViewModalType.DISABLE:
				action = () => handleDisableUser(activeUser, activeIndex);
				title = 'Disable account?';
				actionTitle = 'Disable';
				notification = 'You are about to disable account for ';
				notificationStrong = `${activeUser.firstName} ${activeUser.lastName}`;
				isRed = true;
				break;

			case UserListViewModalType.DELETE:
				action = () => handleDeleteInvite(activeUser, activeIndex);
				title = 'Delete invitation?';
				actionTitle = 'Delete';
				notification = 'You are about to delete invitation for ';
				notificationStrong = `${activeUser.firstName} ${activeUser.lastName}`;
				isRed = true;

				break;

			default:
				return assertUnreachable(modalType, `Unhandled discriminated union member: ${JSON.stringify(modalType)}`);
		}

		return (
			<ActionModal
				activeIndex={activeIndex}
				activeUser={activeUser}
				isOpen={isModalOpen[activeIndex]}
				title={title}
				notification={notification}
				notificationStrong={notificationStrong}
				actionButtonTitle={actionTitle}
				handleClose={handleCloseModal}
				handleAction={action}
				green={!isRed}
				red={isRed}
			/>
		);
	};

	const setActiveStatus = (status: InvitedUsersFilterStatus) => async (_e: React.MouseEvent<HTMLAnchorElement>) => {
		const conditionalQueryObject = {
			...(status !== InvitedUsersFilterStatus.ALL && { status: InvitedUserStatus[status] }),
		};

		try {
			await getInvitedUsers({
				...conditionalQueryObject,
				page,
				itemsPerPage: ITEMS_PER_PAGE,
			});

			setActiveFilteredStatus(status);

			const stringifiedInput = qs.stringify({
				...conditionalQueryObject,
			});

			props.history.push(`${props.match.path}?page=${page}&${stringifiedInput}`);
		} catch (e) {
			throw new Error(`Failed to fetch invites - ${e}`);
		}
	};

	const handleOpenModal = (props: HandleOpenModalArgs) => {
		const { index, modalType } = props;
		const modalIsOpen = isModalOpen.slice();
		modalIsOpen[index] = true;
		setIsModalOpen(modalIsOpen);
		setModalType(modalType);
	};

	const handleCloseModal = (index: number) => {
		const modalIsOpened = isModalOpen.slice();
		modalIsOpened[index] = false;
		setIsModalOpen(modalIsOpened);
		setModalType(null);
	};

	const handleResendInvite = async (activeUser: ExtendedInvitedUser, index: number) => {
		// just close modal
		if (!activeUser.inviteId) {
			handleCloseModal(index);
			toast.error('Something went wrong. Please contact support@dagpay.io');

			return;
		}

		try {
			const result = await resendEmailInvite({ inviteId: activeUser.inviteId });

			handleCloseModal(index);

			// fails with validation, our problem
			if (!result.payload || !result.success) {
				toast.error('Something went wrong. Please contact support@dagpay.io');

				return;
			}

			// show where user ends up after action
			props.history.push(`${RoutesUrls.USERS}?status=${InviteStatus.SENT}`);
			setActiveFilteredStatus(InvitedUsersFilterStatus.INVITED);

			toast.success('Invitation successfully sent!');
		} catch (e) {
			handleCloseModal(index);

			// cool down
			if (e.response.status === 400) {
				toast.error('Please wait 5 minutes to resend invitation');

				// probably error worth to know
			} else {
				toast.error('Something went wrong. Please contact support@dagpay.io');
			}
		}
	};

	const handleDeleteInvite = async (activeUser: ExtendedInvitedUser, index: number) => {
		// just close modal
		if (!activeUser.inviteId) {
			handleCloseModal(index);
			toast.error('Something went wrong. Please contact support@dagpay.io');

			return;
		}

		try {
			const result = await deleteInvite({ inviteId: activeUser.inviteId });

			// fails with validation, our problem
			if (!result.payload || !result.success) {
				handleCloseModal(index);
				toast.error('Something went wrong. Please contact support@dagpay.io');

				return;
			}

			// fetch conditionally to update list
			await fetchInvitedUsers(getConditionalQueryObject());

			handleCloseModal(index);
			toast.success('Invitation successfully deleted');
		} catch (e) {
			handleCloseModal(index);

			toast.error('Something went wrong. Please contact support@dagpay.io');
		}
	};

	const handleDisableUser = async (activeUser: ExtendedInvitedUser, index: number) => {
		// just close modal
		if (!activeUser.userId || activeUser.status !== InvitedUserStatus.ACTIVE) {
			handleCloseModal(index);
			toast.error('Something went wrong. Please contact support@dagpay.io');

			return;
		}

		try {
			const result = await disableInvitedUser({ userId: activeUser.userId });

			handleCloseModal(index);

			// some validation error
			if (!result || !result.payload || !result.success) {
				toast.error('Something went wrong. Please contact support@dagpay.io');

				return;
			}

			// show where user ends up after action
			props.history.push(`${RoutesUrls.USERS}?status=${InvitedUsersFilterStatus.INACTIVE}`);
			setActiveFilteredStatus(InvitedUsersFilterStatus.INACTIVE);

			toast.success('User successfully disabled');
		} catch (e) {
			handleCloseModal(index);
			toast.error('Something went wrong. Please contact support@dagpay.io');
		}
	};

	const handleEnableUser = async (activeUser: ExtendedInvitedUser, index: number) => {
		// just close modal
		if (!activeUser.userId || activeUser.status !== InvitedUserStatus.INACTIVE) {
			handleCloseModal(index);
			toast.error('Something went wrong. Please contact support@dagpay.io');

			return;
		}

		try {
			const result = await enableInvitedUser({ userId: activeUser.userId });

			handleCloseModal(index);

			// some validation error
			if (!result || !result.payload || !result.success) {
				toast.error('Something went wrong. Please contact support@dagpay.io');

				return;
			}

			// show where user ends up after action
			props.history.push(`${RoutesUrls.USERS}?status=${InvitedUsersFilterStatus.ACTIVE}`);
			setActiveFilteredStatus(InvitedUsersFilterStatus.ACTIVE);

			toast.success('User successfully enabled');
		} catch (e) {
			handleCloseModal(index);
			toast.error('Something went wrong. Please contact support@dagpay.io');
		}
	};

	const toggleSettingsVisibility =
		(user: ExtendedInvitedUser, index: number) =>
		(_e: React.MouseEvent<HTMLSpanElement | HTMLDivElement> | KeyboardEvent) => {
			const settingsIsOpened = isSettingsOpened.slice();

			settingsIsOpened[index] = !settingsIsOpened[index];
			setIsSettingsOpened(settingsIsOpened);
			setActiveUser(user);
			setActiveIndex(index);
		};

	const handleInviteUser = () => {
		props.history.push(RoutesUrls.USERS_DETAIL);
	};

	const getUserCountByStatus = (userList: ApiArrayPayload<InvitedUsersCount>, status: InvitedUsersFilterStatus) => {
		switch (status) {
			case InvitedUsersFilterStatus.ALL:
				return userList.itemCount;
			case InvitedUsersFilterStatus.ACTIVE:
				const active = userList.items.filter((status) => status.status === InvitedUserStatus.ACTIVE);
				return active.length > 0 ? active[0].count : 0;
			case InvitedUsersFilterStatus.EXPIRED:
				const expired = userList.items.filter((status) => status.status === InvitedUserStatus.EXPIRED);
				return expired.length > 0 ? expired[0].count : 0;
			case InvitedUsersFilterStatus.INVITED:
				const sent = userList.items.filter((status) => status.status === InvitedUserStatus.SENT);
				return sent.length > 0 ? sent[0].count : 0;
			case InvitedUsersFilterStatus.INACTIVE:
				const inactive = userList.items.filter((status) => status.status === InvitedUserStatus.INACTIVE);
				return inactive.length > 0 ? inactive[0].count : 0;
		}
	};

	const isVerified = isUserVerified() && isWalletAdded();
	const parentVerified = isParentVerified();

	const isTeamMember =
		(!!activeViewer && activeViewer.role === UserRole.TEAM_MEMBER) ||
		(!!activeViewer && activeViewer.role === UserRole.ADVANCED_TEAM_MEMBER);
	const isOwner = !!activeViewer && activeViewer.role === UserRole.OWNER;

	if (!activeViewer || !invitedUsersStatistics) {
		return <Loader />;
	}

	return (
		<View>
			<TitleWrapper>
				<H1>User management</H1>
			</TitleWrapper>
			<NotificationRowWrapper>
				<NotificationText>Invite users to join your Dagpay business account</NotificationText>
			</NotificationRowWrapper>
			<NavBarWrapper>
				{getStatusNavigation(invitedUsersStatistics)}
				{parentVerified && isVerified && (
					<WithPermission permissions={[Scopes.INVITES, Scopes.CREATE_INVITE]}>
						<Button.Secondary green onClick={handleInviteUser} align="flex-end">
							Invite user
						</Button.Secondary>
					</WithPermission>
				)}
			</NavBarWrapper>
			{!isTeamMember && !isVerified && (
				<Notification>
					Your verification process is not completed or wallet not added, you can send invite after process is
					completed.
				</Notification>
			)}
			{!isOwner && !parentVerified && <Notification>Dagpay account access limited</Notification>}

			{!invitedUserList ? (
				<Loader />
			) : invitedUserList.items.length !== 0 ? (
				<UserManagement>
					<Table>
						<Table.Thead hiddenOnSmallerScreen>
							<tr>
								<th>User</th>
								<th>Role</th>
								<th>Status</th>
								<th>Last login</th>
								<th />
							</tr>
						</Table.Thead>
						<tbody>{invitedUserList.items.map(renderUsersRows)}</tbody>
					</Table>
					<Pager
						totalItems={invitedUserList.itemsPerPage}
						currentPage={page}
						onChange={(newPage: number) => setPage(newPage)}
						// this issue needs to be fixed in the backend
						totalPages={page > invitedUserList.pageCount ? page : invitedUserList.pageCount}
					/>
					{renderActionModal()}
				</UserManagement>
			) : (
				<View.NoItemsFound>
					<ReactSVG src="/files/svg/private/NoTransactions.svg" />
					<p>Invited users not found</p>
				</View.NoItemsFound>
			)}
		</View>
	);
}

export default withRouter(UsersListView);
