import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { User } from '../../../api/userApi';
import Loader from '../../../components/loader/Loader';
import Logo from '../../../components/logo/Logo';
import HookBaseField from '../../../components/react-hook-form/HookBaseField';
import HookCheckboxField from '../../../components/react-hook-form/HookCheckboxField';
import HookPasswordField from '../../../components/react-hook-form/HookPasswordField';
import ReactHookForm, { RowPlacement, TitleInfoPosition } from '../../../components/react-hook-form/ReactHookFormStyle';
import { MAX_LOGIN_CODE_LENGTH, MIN_LOGIN_CODE_LENGTH, RoutesUrls, Validations } from '../../../constants';
import { Color } from '../../../gfx/constants';
import { getServerValidationErrors } from '../../../services/input-error-utils';
import { useStoreActions } from '../../../services/store';
import { RouteProps } from '../../../typings';

import Login from './LoginStyle';

export interface LoginFields {
	email: string;
	password: string;
	loginCode: string;
	remember: boolean;
}

function LoginView(props: RouteProps) {
	const [isVerificationCodeVisible, setIsVerificationCodeVisible] = useState(false);
	const [isResendLoginCodeLoading, setIsResendLoginCodeLoading] = useState(false);
	const [isResendVisible, setResendVisible] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [maintenanceMode, setMaintenanceMode] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCheckingVerificationCode, setIsCheckingVerificationCode] = useState<boolean>(false);
	const [loginTimeout] = useState<NodeJS.Timeout>();
	let timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const { login, resendLoginCode, getVerifications } = useStoreActions((actions) => ({
		...actions.viewer,
		...actions.verification,
	}));

	const { history, location } = props;

	const schema = Yup.object().shape({
		email: Validations.INVALID_EMAIL,
		password: Validations.PASSWORD_IS_REQUIRED,
		loginCode: Yup.string()
			.test('isRequired', 'Verification code is required', (value) => !(isVerificationCodeVisible && !value))
			.test('isLongEnough', 'Verification code should be 6 characters', (value) => {
				if (isVerificationCodeVisible && !value) {
					return false;
				}

				if (
					isVerificationCodeVisible &&
					(value.length > MAX_LOGIN_CODE_LENGTH || value.length < MIN_LOGIN_CODE_LENGTH)
				) {
					return false;
				}

				return true;
			}),
		remember: Yup.boolean().notRequired(),
	});

	const defaultValues = {
		email: '',
		password: '',
		loginCode: '',
		remember: false,
	};

	const methods = useForm<LoginFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldUnregister: false,
		shouldFocusError: false,
	});

	// automatically redirect !verified! and !logged! in user coming from https://vatm.dagcoin.org/
	const shouldRedirectToVatm = async () => {
		// fetch logged in user verifications info
		const verifications = await getVerifications({ overrideState: true });

		setIsLoading(false);

		// no wallet check in dashboard
		const verificationsOk =
			verifications.payload &&
			verifications.payload.isBusinessVerified &&
			verifications.payload.isEmailVerified &&
			verifications.payload.isIdentityVerified;

		const { referer } = qs.parse(location.search);
		const redirectToVatm = referer !== undefined && typeof referer === 'string' && referer === 'VATM';

		if (redirectToVatm && verificationsOk) {
			return true;
		}

		return false;
	};

	const loginCodeWatch = methods.watch('loginCode');

	const loginButton = document.getElementById('login');

	const handleAutoLogin = () => {
		if (loginCodeWatch.length === 6 && loginButton !== null) {
			setTimeout(() => {
				loginButton.click();
			}, 200);
		}
	};

	useEffect(() => {
		if (!(loginCodeWatch.length === MAX_LOGIN_CODE_LENGTH)) {
			return;
		}
		const autologin = async () => {
			setIsCheckingVerificationCode(true);
			await methods.trigger();
			const response = await login({ ...methods.getValues(), initialLogin: !isVerificationCodeVisible });

			if (response.success) {
				const { redirectUrl } = qs.parse(location.search);
				setIsCheckingVerificationCode(false);
				if (redirectUrl !== undefined && typeof redirectUrl === 'string' && document.location) {
					document.location.href = redirectUrl;
					return;
				}
				loginTimeout && clearTimeout(loginTimeout);
				history.push('/');
			}
			if (response.validationErrors.length !== 0) {
				setIsCheckingVerificationCode(false);
				return methods.setError('loginCode', { type: 'validate', message: 'Invalid verification code' });
			}
		};
		//wait 1 seconds on auth code field change
		timeoutRef.current = setTimeout(() => autologin, 1000);
		return () => {
			timeoutRef.current && clearTimeout(timeoutRef.current);
		};
	}, [loginCodeWatch, history, isVerificationCodeVisible, location.search, login, loginTimeout, methods]);

	const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
		//manual login check, clear auto login timer
		timeoutRef.current && clearTimeout(timeoutRef.current);
		// validate form
		event.preventDefault();

		await methods.trigger();

		const input = methods.getValues();
		setIsLoading(true);
		// try to log in
		const response = await login({ ...input, initialLogin: !isVerificationCodeVisible });

		if (response.success) {
			const shouldRedirect = await shouldRedirectToVatm();
			if (shouldRedirect) {
				history.push(RoutesUrls.VATMS);

				return;
			}

			const { redirectUrl } = qs.parse(location.search);

			if (redirectUrl !== undefined && typeof redirectUrl === 'string' && document.location) {
				document.location.href = redirectUrl;

				return;
			}

			history.push('/');

			return;
		}

		setIsLoading(false);

		// If status 403 - forbidden is returned - ask verification code
		if (response.status === 403) {
			if (response.error === 'AUTHENTICATOR_LOGIN_CODE_REQUIRED') {
				setResendVisible(false);
			}
			setIsVerificationCodeVisible(true);
			setErrorMessage('');

			return;
		}

		if (response.status === 429) {
			return setErrorMessage('Received too frequent login attempts, please try again in a few seconds.');
		}

		if (response.validationErrors.length !== 0) {
			let code = false;

			response.validationErrors.forEach((error) => {
				if (/loginCode/.exec(error.path)) {
					code = true;
				}
			});

			return setErrorMessage(!code ? 'Invalid email or password' : 'Invalid verification code');
		}

		if (response.status === 500 && response.error === 'Network Error') {
			return setErrorMessage('The connection has timed out. Please try again later.');
		}

		if (response.status !== 403) {
			const serverValidationErrors = getServerValidationErrors<User>(response);

			return setErrorMessage(serverValidationErrors.errorMessage);
		}
	};

	const handleResendLoginCode = async () => {
		const loginEmail = methods.getValues().email;

		setIsResendLoginCodeLoading(true);

		const response = await resendLoginCode({ email: loginEmail });

		setIsResendLoginCodeLoading(false);

		if (!response.success) {
			toast.error(response.error);

			return;
		}

		toast.success(`Sent login code to "${loginEmail}"`);
	};

	const checkApiRespond = async () => {
		const protocol = process.env.REACT_APP_API_SSL === 'true' ? 'https' : 'http';
		const url = `${protocol}://${process.env.REACT_APP_API_URL}/countries`;

		try {
			await axios.get(url);
		} catch (error: any) {
			if (error.message === 'Network Error' || error.message === 500) {
				setMaintenanceMode(true);
			} else {
				setMaintenanceMode(false);
			}
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			checkApiRespond();
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Login>
			{maintenanceMode && (
				<Login.Maintenance>
					<Login.MaintenanceWrapper>
						<Logo />
						<h1>We&rsquo;ll be back soon!</h1>
						<div>
							<p>
								Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to
								you can always <a href="mailto:support@dagpay.io">contact us</a>, otherwise we&rsquo;ll be back online
								shortly!
							</p>
							<p>&mdash; The Dagpay team</p>
						</div>
					</Login.MaintenanceWrapper>
				</Login.Maintenance>
			)}
			<Login.Left>
				<FormProvider {...methods}>
					<ReactHookForm onSubmit={handleLogin}>
						<Login.LogoWrapper>
							<Logo hasWhiteBackground />
						</Login.LogoWrapper>
						{isVerificationCodeVisible && (
							<>
								<ReactHookForm.Title infoPosition={TitleInfoPosition.BOTTOM}>
									Enter verification code
									<ReactHookForm.Info>Please enter the code from your email or authenticator app</ReactHookForm.Info>
								</ReactHookForm.Title>
								<ReactHookForm.Fields direction="column">
									<HookBaseField
										onChange={handleAutoLogin}
										name="loginCode"
										label="Code"
										placeholder="Enter your code"
										autocomplete="one-time-code"
										maxLength={6}
										subLabel={
											isResendVisible && (
												<ReactHookForm.Row placement={RowPlacement.LEFT}>
													<ReactHookForm.RowText>
														Didn’t receive code?
														{isResendLoginCodeLoading ? (
															<ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />
														) : (
															<ReactHookForm.ApiLink onClick={handleResendLoginCode}>Resend</ReactHookForm.ApiLink>
														)}
													</ReactHookForm.RowText>
												</ReactHookForm.Row>
											)
										}
									/>
								</ReactHookForm.Fields>
							</>
						)}
						{!isVerificationCodeVisible && (
							<>
								<ReactHookForm.Title infoPosition={TitleInfoPosition.RIGHT}>
									Log in
									<ReactHookForm.Info>
										{`Don't have an account? `}
										<ReactHookForm.Link to="/sign-up/create-account">Create an account</ReactHookForm.Link>
									</ReactHookForm.Info>
								</ReactHookForm.Title>
								<ReactHookForm.Fields direction="column">
									<HookBaseField label="Email" name="email" />
									<HookPasswordField label="Password" name="password" />
								</ReactHookForm.Fields>
							</>
						)}
						{!isVerificationCodeVisible && (
							<ReactHookForm.Link to={RoutesUrls.PASSWORD_RECOVERY}>Forgot password?</ReactHookForm.Link>
						)}
						<ReactHookForm.Row
							placement={isVerificationCodeVisible ? RowPlacement.SPACE_BETWEEN_COLUMN : RowPlacement.RIGHT}
						>
							{isVerificationCodeVisible && (
								<Login.VerificationCodeCheckBox>
									<HookCheckboxField name="remember" label="Trust this browser (for 30 days)" />
								</Login.VerificationCodeCheckBox>
							)}
							{!isCheckingVerificationCode ? (
								<ReactHookForm.Button id="login" type="submit" disabled={isLoading || methods.formState.isSubmitting}>
									Log in
									<ReactSVG src="/files/svg/icons/HookButtonArrow.svg" />
								</ReactHookForm.Button>
							) : (
								<Loader />
							)}
						</ReactHookForm.Row>
						<ReactHookForm.Error>{errorMessage}</ReactHookForm.Error>
					</ReactHookForm>
				</FormProvider>
			</Login.Left>

			<Login.Right>
				<Login.Image />
				<Login.TextWrapper>
					<Login.Welcome>Welcome back!</Login.Welcome>
					<Login.Text>Log in to continue to your account.</Login.Text>
				</Login.TextWrapper>
			</Login.Right>
		</Login>
	);
}

export default withRouter(LoginView);
