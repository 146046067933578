import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { CreateInviteRequest } from '../../../../api/inviteApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import StyledField from '../../../../components/field/FieldStyle';
import Form from '../../../../components/form/Form';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import Notification from '../../../../components/notification/Notification';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import HookCheckboxField from '../../../../components/react-hook-form/HookCheckboxField';
import HookRadioField from '../../../../components/react-hook-form/HookRadioField';
import ReactHookForm from '../../../../components/react-hook-form/ReactHookFormStyle';
import { InvitedUserRole, InviteStatus, RoutesUrls } from '../../../../constants';
import { H1 } from '../../../../gfx/globals';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';

import {
	InviteUserConfirmationInnerWrapper,
	InviteUserConfirmationWrapper,
	RadioWrapper,
	InviteWrap,
	ModalFooter,
} from './UsersViewStyle';

interface RouteParams {
	id?: string;
}

interface InviteFormFields extends CreateInviteRequest {
	verificationAgreement: boolean;
	responsibilityAgreement: boolean;
}

function UsersDetailView(props: RouteProps<RouteParams>) {
	const [isModalOpened, setIsModalOpened] = useState<boolean>(true);
	const appElement = document.getElementById('root')!;
	const { invitedUser } = useStoreState((state) => ({
		invitedUser: state.invite.invitedUser,
	}));

	const { getInvitedUser, updateInvite, createInvite } = useStoreActions((actions) => ({
		...actions.invite,
	}));

	useEffect(() => {
		const fetchInvitedUser = async (id: string) => {
			return await getInvitedUser({ userId: id });
		};
		(async () => {
			const id = props.match.params.id;

			if (id) {
				try {
					await fetchInvitedUser(id);
				} catch (error) {
					return;
				}
			}
		})();
	}, [getInvitedUser, props.match.params.id]);

	useEffect(() => {
		methods.reset({
			email: invitedUser ? invitedUser.email : '',
			firstName: invitedUser ? invitedUser.firstName : '',
			lastName: invitedUser ? invitedUser.lastName : '',
			role: invitedUser ? invitedUser.role : InvitedUserRole.TEAM_MEMBER,
			verificationAgreement: false,
			responsibilityAgreement: false,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invitedUser]);
	const isCreatable = !props.match.params.id;

	const toggleModal = () => {
		props.history.push(RoutesUrls.USERS);
		setIsModalOpened(!isModalOpened);
	};

	const handleCreateInvite = async (input: InviteFormFields) => {
		const result = props.match.params.id
			? await updateInvite({ ...input, userId: props.match.params.id })
			: await createInvite({ ...input });

		if (result.success) {
			// inviting new user, direct to right view
			if (!props.match.params.id) {
				props.history.push(`${RoutesUrls.USERS}?status=${InviteStatus.SENT}`);
				toast.success('User successfully invited');
			} else {
				props.history.push(RoutesUrls.USERS);
				toast.success('User successfully updated');
			}

			return result;
		}

		if (result.validationErrors[0].params[0] === 'unique-email') {
			methods.setError('email', {
				message:
					'The email you entered is already registered in Dagpay, you cannot invite a user that is already signed up',
			});
			const fieldSelector = document.querySelector(`[name=email]`);
			if (fieldSelector) fieldSelector.scrollIntoView();
			return;
		}
		if (result.validationErrors[0].params[0] === 'unique-invite-email') {
			methods.setError('email', {
				message: 'User with entered email address has already been invited',
			});
			const fieldSelector = document.querySelector(`[name=email]`);
			if (fieldSelector) fieldSelector.scrollIntoView();
			return;
		}
		if (result.error && result.status !== 400) {
			toast.error('Something went wrong, please try again');

			return;
		}
	};

	const inviteUserValidationSchema = Yup.object<InviteFormFields>().shape({
		email: Yup.lazy((_val) => {
			if (!props.match.params.id) {
				return Yup.string().email().required('Email is required');
			}
			return Yup.string().notRequired();
		}),
		firstName: Yup.string()
			.required('First name is required')
			.max(100, 'Maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		lastName: Yup.string()
			.required('Last name is required')
			.max(100, 'Maximum length is 100 characters')
			.trim('Field cannot be left empty or filled with spaces')
			.strict(true),
		role: Yup.mixed().oneOf(Object.values(InvitedUserRole)).required('Role is required'),
		verificationAgreement: Yup.boolean().oneOf([isCreatable], 'Agreement should be checked'),
		responsibilityAgreement: Yup.boolean().oneOf([isCreatable], 'Agreement should be checked'),
	});

	const defaultValues = {
		email: invitedUser ? invitedUser.email : '',
		firstName: invitedUser ? invitedUser.firstName : '',
		lastName: invitedUser ? invitedUser.lastName : '',
		role: invitedUser ? invitedUser.role : InvitedUserRole.TEAM_MEMBER,
		verificationAgreement: false,
		responsibilityAgreement: false,
	};

	const methods = useForm<InviteFormFields>({
		resolver: yupResolver(inviteUserValidationSchema),
		defaultValues: defaultValues,
		mode: 'all',
	});

	if (!isCreatable && !invitedUser) {
		return <Loader />;
	}

	return (
		<Modal
			appElement={appElement}
			hasCloseButton
			ariaHideApp={false}
			isOpen={isModalOpened}
			onRequestClose={toggleModal}
			shouldCloseOnOverlayClick={true}
		>
			<InviteWrap>
				<FormProvider {...methods}>
					<ReactHookForm onSubmit={methods.handleSubmit(handleCreateInvite)} autoComplete="off">
						<H1 left>{isCreatable ? 'Invite new user' : 'Edit user'}</H1>
						<Notification>
							{isCreatable
								? "Enter the email address of the user you'd like to invite, and assign a role for the user"
								: 'Edit user details, or assign a different role for the user'}
						</Notification>
						<Form.Section>
							<Form.Column withMargin>
								<HookBaseField name="firstName" label="First name" />
							</Form.Column>
							<Form.Column>
								<HookBaseField name="lastName" label="Last name" />
							</Form.Column>
						</Form.Section>
						<HookBaseField name="email" label="Email" disabled={!isCreatable} />
						<StyledField.Title>Choose role</StyledField.Title>
						<RadioWrapper>
							<HookRadioField
								name="role"
								control={methods.control}
								isGreen={true}
								options={[
									{
										value: InvitedUserRole.ADMIN,
										label: 'Admin',
										description:
											'All permissions, including viewing all Dagpay transactions, sending invoices, setting up integrations and team management',
										isActive: methods.watch('role') === InvitedUserRole.ADMIN,
									},
									{
										value: InvitedUserRole.ADVANCED_TEAM_MEMBER,
										label: 'Advanced Team Member',
										description: 'Create email invoices, POS checkouts and view all Dagpay transactions',
										isActive: methods.watch('role') === InvitedUserRole.ADVANCED_TEAM_MEMBER,
									},
									{
										value: InvitedUserRole.TEAM_MEMBER,
										label: 'Team Member',
										description: 'Create email invoices, POS checkouts and view Dagpay transactions made by the member',
										isActive: methods.watch('role') === InvitedUserRole.TEAM_MEMBER,
									},
								]}
							/>
						</RadioWrapper>

						{isCreatable && (
							<>
								<InviteUserConfirmationWrapper>
									<InviteUserConfirmationInnerWrapper>
										<HookCheckboxField name="verificationAgreement" label="I have verified the invited employee" />
										<HookCheckboxField
											name="responsibilityAgreement"
											label="I'm responsible for the conduct of the invited employee"
										/>
									</InviteUserConfirmationInnerWrapper>
								</InviteUserConfirmationWrapper>
							</>
						)}
						<ModalFooter>
							<Modal.Buttons>
								<Button.Secondary green type={ButtonTypes.SUBMIT} disabled={!methods.formState.isValid}>{`${
									isCreatable ? 'Send Invite' : 'Update invited user'
								}`}</Button.Secondary>
							</Modal.Buttons>
						</ModalFooter>
					</ReactHookForm>
				</FormProvider>
			</InviteWrap>
		</Modal>
	);
}

export default withRouter(UsersDetailView);
