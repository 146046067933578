import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';

import { Invoice, InvoiceState } from '../../../../api/invoiceApi';
import ColoredInvoiceState from '../../../../components/colored-invoice-state/ColoredInvoiceState';
import GridTable, { GridTableButton, GridTableItem, GridTableType } from '../../../../components/grid-table/GridTable';
import InvoicePrice from '../../../../components/invoice-price/InvoicePrice';
import Loader from '../../../../components/loader/Loader';
import Pager from '../../../../components/pager/Pager';
import Table from '../../../../components/table/Table';
import View from '../../../../components/view/View';
import { GLOBAL_DATE_FORMAT, ITEMS_PER_PAGE } from '../../../../constants';
import { TransactionColSizes } from '../../../../gfx/constants';
import { ColumnedFlex, Light } from '../../../../gfx/globals';
import { downloadFile } from '../../../../services/download-file';
import getInvoiceType from '../../../../services/get-invoice-type';
import { isInvoicePaid } from '../../../../services/is-invoice-paid';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';

import FilterBlock from './filter-block/FilterBlock';
import CreateLimitModal from './modals/create-limit/CreateLimitModal';
import SendConfirmationReceiptModal from './modals/SendConfirmationReceiptModal';
import { FiltersSection, SenderAddress } from './TransactionViewStyle';
dayjs.extend(customParseFormat);

export enum ModalType {
	SEND_RECEIPT = 'SEND_RECEIPT',
	CREATE_LIMIT = 'CREATE_LIMIT',
}

export interface OpenedInformation {
	transactionId: string;
	isOpened: boolean;
}

export interface SortRowType {
	rowName: string;
	isDescending: boolean;
}

function TransactionsView(props: RouteProps) {
	const [page, setPage] = useState(1);
	const [openedInformationRows, setOpenedInformationRows] = useState<OpenedInformation[]>([]);
	const [isFilterSearchEnabled, setIsFilterSearchEnabled] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [activeTransaction, setActiveTransaction] = useState<Invoice | null>(null);
	const [modalType, setModalType] = useState<ModalType | null>(null);
	const [isExportOptionsOpened, setIsExportOptionsOpened] = useState<boolean>(false);

	const { startDate, endDate, state, type, keyword, users } = qs.parse(props.location.search);

	const { invoices, activeViewer } = useStoreState((state) => ({
		invoices: state.invoice.invoices,
		activeViewer: state.viewer.activeViewer,
	}));

	const { getInvoices, getInvoiceReceipt } = useStoreActions((actions) => ({
		...actions.invoice,
	}));

	const getFilterArray = (fieldQueryString: string | string[] | null) => {
		if (!fieldQueryString || typeof fieldQueryString !== 'string') {
			return [];
		}
		const queryStringList = fieldQueryString.split(',');

		if (queryStringList.includes('all') || queryStringList.includes('ALL')) {
			return [];
		}

		return queryStringList;
	};

	useEffect(() => {
		const fetchInvoices = async () => {
			const conditionalQueryObject = {
				...(startDate && { startDate: dayjs(startDate as string, 'DD/MM/YYYY').toISOString() }),
				...(endDate && {
					endDate: dayjs(endDate as string, 'DD/MM/YYYY')
						.add(1, 'day')
						.toISOString(),
				}),
				...(keyword && { keyword: keyword as string }),
			};
			return await getInvoices({
				...conditionalQueryObject,
				type: getFilterArray(type),
				state: getFilterArray(state) as InvoiceState[],
				users: getFilterArray(users) as InvoiceState[],
				page: page,
				itemsPerPage: ITEMS_PER_PAGE,
			});
		};
		fetchInvoices();
	}, [page, startDate, endDate, state, type, users, keyword, getInvoices]);

	const handleCloseModalClick = useCallback(() => {
		setModalType(null);
		setActiveIndex(null);
		setActiveTransaction(null);
	}, []);

	if (!invoices || !activeViewer) {
		return <Loader />;
	}

	let transactions: Invoice[] = [];

	const handleToggleInformation = (transactionId: string) => {
		const index = openedInformationRows.findIndex((obj) => obj.transactionId === transactionId);
		const openedInformationRowsCopy = [...openedInformationRows];

		if (index === -1) {
			openedInformationRowsCopy.push({ transactionId, isOpened: true });
			setOpenedInformationRows(openedInformationRowsCopy);
		}

		if (index >= 0) {
			openedInformationRowsCopy[index].isOpened = !openedInformationRows[index].isOpened;
			setOpenedInformationRows(openedInformationRowsCopy);
		}
	};

	const getSplitted = (senderAddresses: string) => {
		return senderAddresses.split(',').map((address: string, index) => (
			<SenderAddress key={index} index={index + 1}>
				{address}
			</SenderAddress>
		));
	};

	const handleOpenModal = (transaction: Invoice, index: number, modalType: ModalType) => {
		setModalType(modalType);
		setActiveIndex(index);
		setActiveTransaction(transaction);
	};

	const handleDownloadPdf = async (transaction: Invoice) => {
		try {
			const response = await getInvoiceReceipt({ invoiceId: transaction.id });
			if (!response.success || typeof response.payload !== 'string') {
				return;
			}
			const b64PdfString = response.payload;
			downloadFile(atob(b64PdfString), `Dagpay_receipt_${transaction.no}.pdf`);
		} catch (error) {
			toast.error('Downloading payment receipt failed');
		}
	};

	const isOpened = (transactionId: string) =>
		openedInformationRows.filter((obj) => obj.transactionId === transactionId && obj.isOpened).length > 0;

	const getMoreInformation = (transaction: Invoice, index: number) => {
		const tableItems: GridTableItem[] = [
			{
				label: 'Merchant',
				value: transaction.merchantName,
				position: { startingColumn: 1, endingColumn: 1, startingRow: 1, endingRow: 1 },
			},
			{
				label: 'Invoiced by',
				value: `${transaction.creator.firstName} ${transaction.creator.lastName}`,
				position: { startingColumn: 2, endingColumn: 2, startingRow: 1, endingRow: 1 },
			},
			{
				label: 'Environment',
				value: transaction.environment.name,
				position: { startingColumn: 3, endingColumn: 3, startingRow: 1, endingRow: 1 },
			},
			{
				label: 'Invoice ID',
				value: transaction.id,
				position: { startingColumn: 1, endingColumn: 1, startingRow: 3, endingRow: 3 },
			},
			{
				label: 'Public ID',
				value: transaction.publicId,
				position: { startingColumn: 2, endingColumn: 2, startingRow: 3, endingRow: 3 },
			},
			{
				label: 'Custom ID',
				value: transaction.paymentId,
				position: { startingColumn: 3, endingColumn: 3, startingRow: 3, endingRow: 3 },
			},
			{
				label: 'Dagloyalty payment',
				value: transaction.atmOnly ? 'Yes' : '-',
				position: { startingColumn: 1, endingColumn: 1, startingRow: 5, endingRow: 5 },
			},
			{
				label: 'Buyer wallet(s)',
				value: transaction.senderAddresses ? getSplitted(transaction.senderAddresses) : '',
				position: { startingColumn: 2, endingColumn: 2, startingRow: 5, endingRow: 5 },
			},
			{
				label: 'Receiving wallet',
				value: transaction.wallet.name,
				position: { startingColumn: 3, endingColumn: 3, startingRow: 5, endingRow: 5 },
			},
			{
				label: 'Invoice total',
				value: `${transaction.currencyAmount} ${transaction.currency}`,
				totalItem: {
					name: 'DAG',
					value: transaction.coinAmount,
				},
				position: { startingColumn: 3, endingColumn: 3, startingRow: 7, endingRow: 7 },
			},
		];

		const tableButtons: GridTableButton[] = [
			{
				label: 'Create limit',
				iconPath: '',
				white: true,
				isVisible: transaction.senderAddresses !== null && activeViewer.customerLimitActive,
				func: () => handleOpenModal(transaction, index, ModalType.CREATE_LIMIT),
			},
			{
				label: 'Send receipt',
				iconPath: '',
				white: true,
				isVisible: isInvoicePaid(transaction.state) && transaction.senderAddresses !== null,
				func: () => handleOpenModal(transaction, index, ModalType.SEND_RECEIPT),
			},
			{
				label: 'Download PDF',
				iconPath: '',
				white: true,
				isVisible: isInvoicePaid(transaction.state) && transaction.senderAddresses !== null,
				func: () => handleDownloadPdf(transaction),
			},
		];

		return (
			<GridTable
				opened={isOpened(transaction.id)}
				tableType={GridTableType.TRANSACTION_HISTORY}
				tableItems={tableItems}
				tableButtons={tableButtons}
			/>
		);
	};

	const renderTransactionRow = (transaction: Invoice, index: number) => (
		<React.Fragment key={transaction.id}>
			<Table.TrPrimary className={isOpened(transaction.id) ? 'opened' : undefined}>
				<Table.FixedWidthTd noWhiteSpaceWrap cellWidth={TransactionColSizes.DATE_AND_TIME}>
					<Table.DataWrapper data-label="Date & Time">
						<ColumnedFlex>
							{dayjs(transaction.createdDate).format(GLOBAL_DATE_FORMAT)}
							<Light>{dayjs(transaction.createdDate).format('HH:mm')}</Light>
						</ColumnedFlex>
					</Table.DataWrapper>
				</Table.FixedWidthTd>
				<Table.FixedWidthTd noWhiteSpaceWrap cellWidth={TransactionColSizes.INVOICE_NO}>
					<Table.DataWrapper data-label="Invoice no">{transaction.no}</Table.DataWrapper>
				</Table.FixedWidthTd>
				<Table.FixedWidthTd noWhiteSpaceWrap cellWidth={TransactionColSizes.TYPE}>
					<Table.DataWrapper data-label="Type">
						{getInvoiceType(transaction.type, transaction.atmOnly)}
					</Table.DataWrapper>
				</Table.FixedWidthTd>
				<Table.FixedWidthTd noWhiteSpaceWrap cellWidth={TransactionColSizes.STATUS}>
					<Table.DataWrapper data-label="Status">
						<ColoredInvoiceState state={transaction.state} />
					</Table.DataWrapper>
				</Table.FixedWidthTd>
				<Table.FixedWidthTd noWhiteSpaceWrap cellWidth={TransactionColSizes.AMOUNT}>
					<Table.DataWrapper data-label="Amount">
						<InvoicePrice {...transaction} />
					</Table.DataWrapper>
				</Table.FixedWidthTd>

				<Table.SeeMoreTd
					className={isOpened(transaction.id) ? 'opened' : undefined}
					cellWidth={TransactionColSizes.ACTIONS}
				>
					<Table.Arrow
						opened={isOpened(transaction.id)}
						onClick={() => {
							setIsExportOptionsOpened(false);
							handleToggleInformation(transaction.id);
						}}
					>
						{isOpened(transaction.id) ? 'See less' : 'See more'}
						<ReactSVG src="/files/svg/private/Arrow.svg" />
					</Table.Arrow>
				</Table.SeeMoreTd>
			</Table.TrPrimary>
			{isOpened(transaction.id) && getMoreInformation(transaction, index)}
		</React.Fragment>
	);

	const renderModal = () => {
		if (activeIndex === null || activeTransaction === null) {
			return null;
		}

		switch (modalType) {
			case ModalType.SEND_RECEIPT:
				return (
					<SendConfirmationReceiptModal transaction={activeTransaction} handleCloseModal={handleCloseModalClick} />
				);
			case ModalType.CREATE_LIMIT:
				return <CreateLimitModal transaction={activeTransaction} handleCloseModal={handleCloseModalClick} />;
			default:
				return null;
		}
	};

	if (invoices) {
		transactions = invoices.items;
	}

	return (
		<>
			{renderModal()}
			<View>
				<FiltersSection>
					<FilterBlock
						location={props.location}
						history={props.history}
						match={props.match}
						setIsFilterSearchEnabled={setIsFilterSearchEnabled}
						setPage={setPage}
						transactions={transactions}
						isFilterSearchEnabled={isFilterSearchEnabled}
						isExportOptionsOpened={isExportOptionsOpened}
					/>
				</FiltersSection>

				{transactions.length > 0 ? (
					<Table.LatestTransactions>
						<Table.Thead hiddenOnSmallerScreen>
							<tr>
								<th>Date & Time</th>
								<th>Invoice no.</th>
								<th>Type</th>
								<th>Status</th>
								<th>Amount</th>
								<th />
							</tr>
						</Table.Thead>
						<tbody>{transactions.map((transaction, index) => renderTransactionRow(transaction, index))}</tbody>
					</Table.LatestTransactions>
				) : (
					<View.NoItemsFound>
						<ReactSVG src="/files/svg/private/NoTransactions.svg" />
						{isFilterSearchEnabled ? (
							<p>No transactions matching your search are found</p>
						) : (
							<p>You haven't made or received any transactions yet</p>
						)}
					</View.NoItemsFound>
				)}
				<Pager
					totalItems={invoices.itemCount}
					currentPage={page}
					onChange={(newPage: number) => setPage(newPage)}
					totalPages={invoices.pageCount}
				/>
			</View>
		</>
	);
}

export default withRouter(TransactionsView);
