import * as React from 'react';
import ReactSVG from 'react-svg';
import styled, { css } from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

interface StyledNotificationProps {
	children: React.ReactNode;
	left?: boolean;
	center?: boolean;
	gray?: boolean;
	hasError?: boolean;
	box?: boolean;
	noIcon?: boolean;
	blueIcon?: boolean;
	maxWidth?: string;
}

const StyledNotification = styled.div<StyledNotificationProps>`
	width: ${({ left }) => left && '100%'};
	margin-bottom: 20px;
	border-radius: 4px;
	${(props) =>
		props.box &&
		css`
			max-width: ${props.maxWidth ? props.maxWidth : '100%'};
			background: ${props.gray ? Color.GRAY_2 : '#F8FDFF'};
			border: 1px solid ${props.gray ? Color.GRAY_5 : Color.BLUE_2};
			padding: 24px 56px 24px 20px;
			box-shadow: 0px 2px 40px rgba(20, 38, 82, 0.1);

			${media.maxSize1000`
				padding: 24px 20px 24px 20px;
			`}
		`}

	${media.maxSize1000`
		div { 
			font-size: 14px;
		}
	`}
`;

export const StyledInfoBlock = styled.div<StyledNotificationProps>`
	display: flex;
	max-width: 100%;
	color: ${Color.GRAY_10};
	font-family: Secondary;
	font-size: 16px;
	line-height: 24px;
	justify-content: ${({ center }) => center && 'center'};

	> div div {
		margin-top: 3px;
	}

	a,
	a:visited {
		color: ${({ gray }) => (gray ? Color.BLUE_1 : Color.BLUE_2)};
	}

	div {
		font-family: Secondary;

		h3 {
			color: ${Color.BLUE_9};
			font-family: 'Primary';
			font-size: 18px;
			line-height: 24px;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		p {
			margin-top: 10px;
			margin-bottom: 0px;
		}

		strong {
			font-family: 'Secondary';
		}

		h1 {
			color: ${Color.BLUE_9};
			font-size: 16px;
			line-height: 21px;
			margin-top: 0px;
		}
		margin: 0;
		p:first-child {
			margin: 0;
			margin-bottom: 27px;
		}
	}

	svg {
		margin-right: 8px;
		margin-top: -3px;

		${(props) =>
			props.hasError &&
			css`
				fill: ${Color.RED_ERROR};
			`}
		${(props) =>
			props.gray &&
			!props.hasError &&
			css`
				fill: ${Color.GRAY_6};
			`}
	}

	${media.maxSize1000`
		line-height: 21px;
	`}
`;

const Notification = (props: StyledNotificationProps) => {
	const { children, center, hasError, noIcon, blueIcon, ...rest } = props;

	return (
		<StyledNotification {...rest}>
			<StyledInfoBlock {...props}>
				{blueIcon ? <ReactSVG src="/files/svg/private/blueWarning.svg" /> : ' '}
				<div>{children}</div>
			</StyledInfoBlock>
		</StyledNotification>
	);
};

export default Notification;
