import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button, { ButtonTypes } from '../../../../../../components/button/Button';
import CopyButton from '../../../../../../components/copy-button/CopyButton';
import { ModalButtonWrapper } from '../../../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import StyledField from '../../../../../../components/field/FieldStyle';
import Loader from '../../../../../../components/loader/Loader';
import Modal from '../../../../../../components/modal/Modal';
import Notification from '../../../../../../components/notification/Notification';
import PanelNew, { PanelSectionType } from '../../../../../../components/panel-new/PanelNew';
import PreviousLink from '../../../../../../components/previous-link/PreviousLink';
import HookBaseField from '../../../../../../components/react-hook-form/HookBaseField';
import HookTextAreaField from '../../../../../../components/react-hook-form/HookTextAreaField';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
	HookFormSeparator,
} from '../../../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../../../components/view/View';
import { RoutesUrls } from '../../../../../../constants';
import { Color } from '../../../../../../gfx/constants';
import { CenteredFlex, H1 } from '../../../../../../gfx/globals';
import { RouteProps } from '../../../../../../typings';

import { DownloadPluginButton, PluginDescription, PluginHeader, OverlayWithBackground } from './EnvironmentDetailStyle';
import { getEnvInfo, TatumEnvironment, TatumEnvironmentSubType } from '../../../../../../api/tatumEnvironmentApi';
import { useStoreActions } from '../../../../../../services/store';

export interface TatumEnvironmentFields {
	id: string;
	userId: string;
	masterAddress: string;
	withdrawalAddress: string;
	withdrawalAccountId: string;
	secret: string;
	apiKey: string;
	masterWalletPrivateKey: string;
	email: string;
	name: string;
	description: string;
	maxPoolSize: number;
	poolBufferSize: number;
	fee: number;
	paymentThreshold: number;
	gasPumpAddressExpiryTimeInHours: number;
	statusUrl: string;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
}

const validateOnlyHttpsUrl = /^https:\/\//;

const tatumEnvValidationSchema = Yup.object<TatumEnvironmentFields>().shape({
	id: Yup.string().notRequired(),
	userId: Yup.string().notRequired(),
	masterAddress: Yup.string().required('Master address is required'),
	withdrawalAddress: Yup.string().required('Withdrawal address is required'),
	withdrawalAccountId: Yup.string().required('Withdrawal account ID is required'),
	secret: Yup.string().notRequired(),
	apiKey: Yup.string().required('API key is required'),
	masterWalletPrivateKey: Yup.string().required('Master wallet private key is required'),
	email: Yup.string().email('Invalid email'),
	name: Yup.string()
		.required('Name is required')
		.min(2, 'Name should contain at least 2 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	description: Yup.string()
		.max(1024, 'The maximum length is 1024 characters')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	maxPoolSize: Yup.number().required('Max pool size is required'),
	poolBufferSize: Yup.number().required('Pool buffer size is required'),
	fee: Yup.number().required('Fee is required'),
	paymentThreshold: Yup.number().required('Payment threshold is required'),
	gasPumpAddressExpiryTimeInHours: Yup.number().required('Gas pump address expiry time is required'),
	statusUrl: Yup.string()
		.matches(validateOnlyHttpsUrl, 'Status URL must include secure “https://” to create environment')
		.strict(true),
	redirectSuccessUrl: Yup.string()
		.matches(validateOnlyHttpsUrl, 'Status URL must include secure “https://” to create environment')
		.required('Redirect URL is required'),
	redirectCancelUrl: Yup.string()
		.matches(validateOnlyHttpsUrl, 'Status URL must include secure “https://” to create environment')
		.required('Redirect URL is required'),
	redirectFailUrl: Yup.string()
		.matches(validateOnlyHttpsUrl, 'Status URL must include secure “https://” to create environment')
		.required('Redirect URL is required'),
});

interface RouteParams {
	environmentId?: string;
}

function TatumEnvironmentDetailView(props: RouteProps<RouteParams>) {
	const { match } = props;
	const isCreatable = !match.params.environmentId;
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [tatumEnvironment, setTatumEnvironment] = useState<TatumEnvironment | null>(null);
	const [isActionLoading, setIsActionLoading] = useState(false);

	const { getTatumEnvironment, updateTatumEnvironment, createTatumEnvironment } = useStoreActions((actions) => ({
		...actions.tatumEnvironment,
	}));

	useEffect(() => {
		const fetchTatumEnvironment = async (environmentId?: string) => {
			if (!environmentId) {
				return;
			}

			const response = await getTatumEnvironment({ environmentId });

			if (response && response.success) {
				if (!tatumEnvironment) {
					setTatumEnvironment(response.payload);
					methods.reset({
						id: response.payload?.id || '',
						userId: response.payload?.userId || '',
						masterAddress: response.payload?.masterAddress || '',
						withdrawalAddress: response.payload?.withdrawalAddress || '',
						withdrawalAccountId: response.payload?.withdrawalAccountId || '',
						secret: response.payload?.secret || '',
						apiKey: response.payload?.apiKey || '',
						masterWalletPrivateKey: response.payload?.masterWalletPrivateKey || '',
						email: response.payload?.email || '',
						name: response.payload?.name || '',
						description: response.payload?.description || '',
						maxPoolSize: response.payload?.maxPoolSize || 0,
						poolBufferSize: response.payload?.poolBufferSize || 0,
						fee: response.payload?.fee || 0,
						paymentThreshold: response.payload?.paymentThreshold || 0,
						gasPumpAddressExpiryTimeInHours: response.payload?.gasPumpAddressExpiryTimeInHours || 0,
						statusUrl: response.payload?.statusUrl || '',
						redirectSuccessUrl: response.payload?.redirectSuccessUrl || '',
						redirectCancelUrl: response.payload?.redirectCancelUrl || '',
						redirectFailUrl: response.payload?.redirectFailUrl || '',
					});
				}
			}
		};

		fetchTatumEnvironment(match.params.environmentId);
	}, [match.params.environmentId, getTatumEnvironment, tatumEnvironment]);

	const defaultValues: TatumEnvironmentFields = {
		id: tatumEnvironment?.id || '',
		userId: tatumEnvironment?.userId || '',
		masterAddress: tatumEnvironment?.masterAddress || '',
		withdrawalAddress: tatumEnvironment?.withdrawalAddress || '',
		withdrawalAccountId: tatumEnvironment?.withdrawalAccountId || '',
		secret: tatumEnvironment?.secret || '',
		apiKey: tatumEnvironment?.apiKey || '',
		masterWalletPrivateKey: tatumEnvironment?.masterWalletPrivateKey || '',
		email: tatumEnvironment?.email || '',
		name: tatumEnvironment?.name || '',
		description: tatumEnvironment?.description || '',
		maxPoolSize: tatumEnvironment?.maxPoolSize || 0,
		poolBufferSize: tatumEnvironment?.poolBufferSize || 0,
		fee: tatumEnvironment?.fee || 0,
		paymentThreshold: tatumEnvironment?.paymentThreshold || 0,
		gasPumpAddressExpiryTimeInHours: tatumEnvironment?.gasPumpAddressExpiryTimeInHours || 0,
		statusUrl: tatumEnvironment?.statusUrl || '',
		redirectSuccessUrl: tatumEnvironment ? tatumEnvironment.redirectSuccessUrl : '',
		redirectCancelUrl: tatumEnvironment ? tatumEnvironment.redirectCancelUrl : '',
		redirectFailUrl: tatumEnvironment ? tatumEnvironment.redirectFailUrl : '',
	};

	const methods = useForm<TatumEnvironmentFields>({
		resolver: yupResolver(tatumEnvValidationSchema),
		defaultValues: defaultValues,
		shouldFocusError: true,
		shouldUnregister: false,
		mode: 'onChange',
	});

	if (!isCreatable && !tatumEnvironment) {
		return <Loader />;
	}

	const handleRedirectToCreatedEnvironment = () => {
		props.history.push(`${RoutesUrls.ENVIRONMENTS}?tab=tatum`);
		toast.success(`Tatum environment is created successfully.`);
	};

	async function handleSaveEnvironment(input: TatumEnvironmentFields) {
		const { id, userId, secret, ...rest } = input;
		setIsActionLoading(true);

		const response = match.params.environmentId
			? await updateTatumEnvironment({
					...input,
					id: match.params.environmentId,
			  })
			: await createTatumEnvironment({
					...rest,
			  });

		if (response.error) {
			setIsActionLoading(false);
			toast.error(response.error);

			console.log('response.error: ', response.error);
			return;
		}

		setIsActionLoading(false);

		if (isCreatable && response.payload) {
			const result = response.payload;
			setIsCreateModalOpen(true);
			methods.reset({
				...result,
			});

			return;
		}

		if (response.payload && !isCreatable) {
			props.history.push(`${RoutesUrls.ENVIRONMENTS}?tab=tatum`);
			toast.success(`Environment is updated successfully.`);

			return;
		}
	}

	const getCredentialsModal = () => {
		const values = methods.getValues();
		return (
			<Modal
				ariaHideApp={false}
				isOpen={isCreateModalOpen}
				onRequestClose={handleRedirectToCreatedEnvironment}
				hasCloseButton
			>
				<H1>Your web platform configuration details</H1>
				<OverlayWithBackground>
					<CenteredFlex marginBottom={20}>
						<StyledField.Title>{values.name}</StyledField.Title>
						<StyledField.OptionalIntegration>{TatumEnvironmentSubType.TRON}</StyledField.OptionalIntegration>
					</CenteredFlex>
					<Notification maxWidth="440px">
						Use the following credentials to accept USDT TRC20 payments on your website. Refer to the{' '}
						<a href={RoutesUrls.DOCUMENTATION} target="_blank" rel="noopener noreferrer">
							Dagpay API documentation
						</a>{' '}
						to integrate or take a look at an example in our{' '}
						<a href="https://help.dagpay.io/en/" target="_blank" rel="noopener noreferrer">
							support section
						</a>
						.
					</Notification>
				</OverlayWithBackground>
				<HookBaseField
					name="id"
					label="Tatum environment ID"
					disabled
					addonSize={48}
					addonNode={
						<CopyButton value={values.id}>
							<ReactSVG src="/files/svg/private/CopySquare.svg" />
						</CopyButton>
					}
				/>
				<HookBaseField
					name="userId"
					label="User ID"
					disabled
					addonSize={48}
					addonNode={
						<CopyButton value={values.userId}>
							<ReactSVG src="/files/svg/private/CopySquare.svg" />
						</CopyButton>
					}
				/>
				<HookBaseField
					name="secret"
					label="Secret"
					disabled
					addonSize={48}
					addonNode={
						<CopyButton value={values.secret}>
							<ReactSVG src="/files/svg/private/CopySquare.svg" />
						</CopyButton>
					}
				/>
				<ModalButtonWrapper>
					<Button.Secondary type={ButtonTypes.BUTTON} green onClick={handleRedirectToCreatedEnvironment}>
						Save and close
					</Button.Secondary>
				</ModalButtonWrapper>
			</Modal>
		);
	};

	return (
		<View>
			{getEnvironmentTypeDescription(TatumEnvironmentSubType.TRON)}
			<PanelNew>
				<FormProvider {...methods}>
					<ReactHookForm onSubmit={methods.handleSubmit(handleSaveEnvironment)} autoComplete="off">
						<PanelNew.Section first white panelType={PanelSectionType.FORM} separateWithBorder>
							<HookFormSeparator>Environment Details</HookFormSeparator>
							<HookFormSection>
								<HookFormColumn>
									<HookBaseField
										name="name"
										label="Environment name"
										placeholder="Title to recognize integration"
										tooltipContent={
											'Environment name is displayied on invoice (receiver) in case invoice was created without custom receiver name'
										}
									/>
								</HookFormColumn>
								<HookFormColumn>
									<HookBaseField name="email" label="Payment notifications" placeholder="Email address" />
								</HookFormColumn>
							</HookFormSection>

							<HookFormSection>
								<HookTextAreaField
									name="description"
									label="Description"
									rows={3}
									placeholder="Description"
									optionalBubble
								/>
							</HookFormSection>

							<HookFormSeparator>Tatum platform details</HookFormSeparator>
							<HookFormSection>
								<HookFormColumn>
									<HookBaseField
										name="apiKey"
										label="API key"
										placeholder="Enter Tatum API key"
										tooltipContent={
											'Used to associate gas pump address with integrators virtual account and to query information of ongoing transactions'
										}
									/>
								</HookFormColumn>

								<HookFormColumn>
									<HookBaseField
										name="masterAddress"
										label="Master wallet address"
										placeholder="Address"
										tooltipContent={
											'This master address is responsible for paying the gas fees for transactions, centralizing the cost and increasing efficiency'
										}
									/>
								</HookFormColumn>
							</HookFormSection>

							<HookFormSection>
								<HookTextAreaField
									name="masterWalletPrivateKey"
									label="Master wallet private key"
									rows={3}
									placeholder="Enter your Tron master wallet private key"
									tooltipContent={
										'Private key of the master wallet address, used to sign transactions and pay gas fees'
									}
								/>
							</HookFormSection>

							<HookFormSection>
								<HookFormColumn>
									<HookBaseField
										name="withdrawalAccountId"
										label="Withdrawal account ID"
										placeholder="Enter withdrawal account ID"
										tooltipContent={
											'Virtual account of the integrator in Tatum platform. Used to associate gas pump address of ongpoing invoice to the integrator'
										}
									/>
									<HookBaseField
										name="withdrawalAddress"
										label="Withdrawal address"
										placeholder="Enter withdrawal address"
										tooltipContent={'Specifies the destination for USDT funds upon successful invoice payment'}
									/>
								</HookFormColumn>

								<HookFormColumn>
									<HookBaseField
										type="number"
										name="paymentThreshold"
										label="Payment threshold %"
										placeholder="(Enter payment threshold) 1"
										tooltipContent={
											'Default: 1%. Threshold amount is calculated from invoice total amount (Contains fee if fee is set). Provides some flexibility for the customer (e.g. due to exchange rate fluctuations).'
										}
									/>
								</HookFormColumn>
							</HookFormSection>

							<Notification box noIcon left gray>
								<h1>Gas pump feature</h1>
								Stablecoin transactions, like USDT (TRC20), need two currencies: USDT for the actual payment and TRON
								(TRX) for transaction fees during deposits and withdrawals. To manage these fees effectively, Dpay uses
								Tatum's gas pump system. This system simplifies how we handle these fees. Instead of taking fees from
								each individual withdrawal address, they are taken from one central 'master address'. This means there's
								no need to send extra money to every withdrawal address just for fees.
								<br />
								<br />
								Also, each time we use a gas pump address for bills, there's a one-time fee. To avoid constantly setting
								up and discarding addresses, Dpay has a system that recycles them. When creating a new Dpay bill, the
								system looks for an old address to reuse. If it can't find one, it asks Tatum to make a new one, which
								then gets added to our list of addresses.
							</Notification>

							<HookFormSection>
								<HookFormColumn>
									<HookBaseField
										type="number"
										name="fee"
										label="Service fee %"
										placeholder="(Enter fee) 1.5"
										tooltipContent={
											'Default: 1.5%. Fee amount is calculated from invoice base amount. Fee amount larger than zero will be added to the invoice amount and is shown on invoice separately.'
										}
									/>
									<HookBaseField
										type="number"
										name="maxPoolSize"
										label="Max pool size"
										placeholder="Enter max pool size"
										tooltipContent={
											'Constrains the number of activated addresses in the pool, controlling costs and managing resources'
										}
									/>
								</HookFormColumn>
								<HookFormColumn>
									<HookBaseField
										type="number"
										name="poolBufferSize"
										label="Pool buffer size"
										placeholder="Enter pool buffer size"
										tooltipContent={'How many gas pump addresses can be generated after hitting max pool size'}
									/>
									<HookBaseField
										type="number"
										name="gasPumpAddressExpiryTimeInHours"
										label="Gas pump address expiry time in hours"
										placeholder="Enter gas pump address expiry time in hours"
										tooltipContent={
											'This time frame ensures that unused gas pump addresses are properly managed, optimizing costs'
										}
									/>
								</HookFormColumn>
							</HookFormSection>

							<HookFormSeparator>Configure status & redirect URLs</HookFormSeparator>
							<HookFormSection>
								<Notification box noIcon left gray>
									<h1>Status URL</h1>
									HTTP endpoint to which DagPay server will POST updates about the invoice state changes. Required to be
									a https URL
								</Notification>

								<HookBaseField
									name="statusUrl"
									label="Status URL (server to server secure status)"
									placeholder="https://example.com/status"
								/>

								<Notification box noIcon left gray>
									<h1>Redirect URLs</h1>
									Return URLs for the browser to redirect back to from the payment view, depending on the final outcome
									of the transaction (can be the same for all states)
								</Notification>
								<HookBaseField name="redirectSuccessUrl" label="Browser redirect (SUCCESS)" />

								<HookBaseField name="redirectCancelUrl" label="Browser redirect (CANCEL)" />

								<HookBaseField name="redirectFailUrl" label="Browser redirect (FAIL)" />
							</HookFormSection>
							<Notification gray>
								For more information, refer to{' '}
								<a href={RoutesUrls.DOCUMENTATION} target="_blank" rel="noopener noreferrer">
									API integration documentation
								</a>{' '}
							</Notification>
							{!isCreatable && (
								<>
									<HookFormSeparator>Your web platform configuration details</HookFormSeparator>
									<HookFormSection>
										<Notification gray>
											Use the following API credentials to accept stablecoin payments on your website.
										</Notification>
										<HookBaseField name="id" label="Tatum environment ID" disabled />
										<HookBaseField name="userId" label="User ID" disabled />
										<HookBaseField name="secret" label="Secret" disabled />
									</HookFormSection>
								</>
							)}
						</PanelNew.Section>

						<PanelNew.Section last gray panelType={PanelSectionType.BUTTON}>
							<PreviousLink
								title="Back"
								to={isCreatable ? RoutesUrls.CHOOSE_TATUM_ENVIRONMENT_TYPE : `${RoutesUrls.ENVIRONMENTS}?tab=tatum`}
							/>
							<PanelNew.ActionButtonsWrapper>
								<Button.Secondary
									green
									type={ButtonTypes.SUBMIT}
									isDisabled={isActionLoading || !methods.formState.isDirty}
									tabIndex={9}
									onClick={methods.handleSubmit(handleSaveEnvironment)}
								>
									{!isActionLoading && isCreatable ? (
										'Create environment'
									) : !isCreatable ? (
										'Update environment'
									) : (
										<Loader size={20} color={Color.WHITE} width={120.5} />
									)}
								</Button.Secondary>
							</PanelNew.ActionButtonsWrapper>
						</PanelNew.Section>

						{getCredentialsModal()}
					</ReactHookForm>
				</FormProvider>
			</PanelNew>
		</View>
	);
}

function getEnvironmentTypeDescription(integrationType: TatumEnvironmentSubType) {
	const description = getEnvInfo(integrationType);

	return (
		<PluginHeader>
			<H1>{description.name} Dagpay integration</H1>
			{description.help ||
				(description.download && (
					<PluginDescription>
						{description.help && (
							<p>
								Refer to the{' '}
								<a href={description.help} target="_blank" rel="noopener noreferrer">
									{description.name !== 'Custom'
										? `${description.name} Dagpay plugin integration`
										: 'API integration documentation'}
								</a>{' '}
								instructions to get started with Dagpay for your website.
							</p>
						)}
						{description.download && (
							<DownloadPluginButton target="_blank" rel="noopener noreferrer" href={description.download}>
								<ReactSVG src="/files/svg/icons/DownloadPlugin.svg" />
								Download Plugin
							</DownloadPluginButton>
						)}
					</PluginDescription>
				))}
		</PluginHeader>
	);
}

export default withRouter(TatumEnvironmentDetailView);
