import { Action, action, thunk, Thunk } from 'easy-peasy';

import { getRatesApi, Rates } from '../api/ratesApi';
import handleFetch from '../services/handle-fetch';
import { Response } from '../typings';

import { StoreModel } from '.';

export interface RatesModel {
	// state
	rates: Rates | null;

	// actions
	saveRates: Action<RatesModel, Rates | null>;

	// thunks
	getRates: Thunk<RatesModel, undefined, {}, StoreModel, Response<Rates>>;
}

const rates: RatesModel = {
	// state
	rates: null,

	// actions
	saveRates: action((state, payload) => {
		state.rates = payload;
	}),

	// thunks
	getRates: thunk(async (actions) => {
		const result = await handleFetch<Rates>(getRatesApi());

		actions.saveRates(result.payload);

		return result;
	}),
};

export default rates;
