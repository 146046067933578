import * as React from 'react';
import ReactSVG from 'react-svg';
import ButtonProps from '../button/Button';
import StyledGridTable from './GridTableStyle';

export enum GridTableType {
	TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
	ONE_TIME_INVOICE = 'ONE_TIME_INVOICE',
	RECURRING_INVOICE = 'RECURRING_INVOICE',
	ESCROW_DEAL = 'ESCROW_DEAL',
}

type Position = {
	startingRow: number;
	endingRow: number;
	startingColumn: number;
	endingColumn: number;
};

type TotalItem = {
	[key: string]: string | number | JSX.Element[] | null;
};

export interface GridTableItem {
	position: Position;
	label?: string;
	value?: string | string[] | number | JSX.Element[] | JSX.Element;
	isSeparatorLine?: boolean;
	totalItem?: TotalItem;
	showCondition?: boolean;
	hidden?: boolean;
	preWrap?: boolean;
	id?: string;
}

export interface GridTableButton {
	label: string;
	iconPath: string;
	isVisible: boolean;
	func: () => void;
	orange?: boolean;
	red?: boolean;
	green?: boolean;
	white?: boolean;
}

export interface GridTableProps {
	tableItems: GridTableItem[];
	tableType: GridTableType;
	opened?: boolean;
	tableButtons?: GridTableButton[];
}

export default function GridTable(props: GridTableProps) {
	const { tableButtons, tableType, tableItems, opened } = props;

	const hasButtonRow = !!tableButtons && tableButtons.some((el) => el.isVisible);

	return (
		<StyledGridTable.TableRow>
			<StyledGridTable.TableData colSpan={6}>
				<StyledGridTable className={opened ? 'opened' : undefined}>
					<StyledGridTable.InfoAreaWrapper hasButtonRow={hasButtonRow} type={tableType}>
						{getTableItems(tableItems)}
					</StyledGridTable.InfoAreaWrapper>
					{hasButtonRow && (
						<StyledGridTable.ButtonWrapper>{getTableButtons(tableButtons)}</StyledGridTable.ButtonWrapper>
					)}
				</StyledGridTable>
			</StyledGridTable.TableData>
		</StyledGridTable.TableRow>
	);
}

function getTableItems(itemList: GridTableItem[]) {
	let oddInfoCell = true;
	return itemList.map((itemObject, index) => {
		const className = oddInfoCell ? 'odd' : 'even';
		oddInfoCell = itemObject.isSeparatorLine ? oddInfoCell : !oddInfoCell;
		if (itemObject.hidden) {
			return <StyledGridTable.hiddenCell />;
		}
		return itemObject.isSeparatorLine ? (
			<StyledGridTable.SeparatorLine2d
				key={`rowLine-${index}`}
				startingRow={itemObject.position.startingRow}
				endingRow={itemObject.position.endingRow}
				startingColumn={itemObject.position.startingColumn}
				endingColumn={itemObject.position.endingColumn}
			/>
		) : (
			<StyledGridTable.InfoCell
				key={`tableItem-${index}`}
				className={className}
				row={itemObject.position.startingRow}
				row2={itemObject.position.endingRow}
				column={itemObject.position.startingColumn}
				column2={itemObject.position.endingColumn}
			>
				<StyledGridTable.DataWrapper>
					{itemObject.label && (
						<StyledGridTable.PrimaryText id={itemObject.id}>{itemObject.label}</StyledGridTable.PrimaryText>
					)}
					{itemObject.totalItem && (
						<StyledGridTable.TotalItem>
							{itemObject.totalItem.value} {itemObject.totalItem.name}
						</StyledGridTable.TotalItem>
					)}

					{itemObject.showCondition !== undefined ? (
						<StyledGridTable.SecondaryText>
							{itemObject.showCondition ? itemObject.value : '-'}
						</StyledGridTable.SecondaryText>
					) : (
						<StyledGridTable.SecondaryText preWrap={itemObject.preWrap}>
							{itemObject.value}
						</StyledGridTable.SecondaryText>
					)}
				</StyledGridTable.DataWrapper>
			</StyledGridTable.InfoCell>
		);
	});
}

function getTableButtons(buttonList: GridTableButton[] | undefined) {
	if (!buttonList) {
		return;
	}

	return buttonList.map(
		(buttonObject, index) =>
			buttonObject.isVisible && (
				<ButtonProps.Secondary
					key={`tableButton-${index}`}
					orange={buttonObject.orange}
					red={buttonObject.red}
					green={buttonObject.green}
					white={buttonObject.white}
					onClick={buttonObject.func}
				>
					<ReactSVG src={buttonObject.iconPath} />
					{buttonObject.label}
				</ButtonProps.Secondary>
			),
	);
}
