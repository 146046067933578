import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import handleFetch from '../services/handle-fetch';
import { ApiArrayPayload, ApiGetRequest, Response, ResponseArray } from '../typings';

import { StoreModel } from '.';
import {
	createTatumEnvironmentApi,
	CreateTatumEnvironmentRequest,
	deleteTatumEnvironmentApi,
	DeleteTatumEnvironmentRequest,
	getTatumEnvironmentApi,
	getTatumEnvironmentListApi,
	GetTatumEnvironmentRequest,
	TatumEnvironment,
	updateTatumEnvironmentApi,
	UpdateTatumEnvironmentRequest,
} from '../api/tatumEnvironmentApi';

export interface TatumEnvironmentModel {
	// state
	envList: ApiArrayPayload<TatumEnvironment> | null;

	// actions
	save: Action<TatumEnvironmentModel, ApiArrayPayload<TatumEnvironment> | null>;
	clear: Action<TatumEnvironmentModel>;

	// thunks
	getTatumEnvironmentList: Thunk<TatumEnvironmentModel, ApiGetRequest, {}, StoreModel, ResponseArray<TatumEnvironment>>;
	getTatumEnvironment: Thunk<
		TatumEnvironmentModel,
		GetTatumEnvironmentRequest,
		{},
		StoreModel,
		Response<TatumEnvironment>
	>;
	createTatumEnvironment: Thunk<
		TatumEnvironmentModel,
		CreateTatumEnvironmentRequest,
		{},
		StoreModel,
		Response<TatumEnvironment>
	>;
	updateTatumEnvironment: Thunk<
		TatumEnvironmentModel,
		UpdateTatumEnvironmentRequest,
		{},
		StoreModel,
		Response<TatumEnvironment>
	>;
	deleteTatumEnvironment: Thunk<
		TatumEnvironmentModel,
		DeleteTatumEnvironmentRequest,
		{},
		StoreModel,
		Response<TatumEnvironment>
	>;

	// listeners
	onCreateTatumEnvironment: ThunkOn<TatumEnvironmentModel, {}, StoreModel>;
	onUpdateTatumEnvironment: ThunkOn<TatumEnvironmentModel, {}, StoreModel>;
	onDeleteTatumEnvironment: ThunkOn<TatumEnvironmentModel, {}, StoreModel>;
}

const tatumEnvironment: TatumEnvironmentModel = {
	// state
	envList: null,

	// actions
	save: action((state, payload) => {
		state.envList = payload;
	}),
	clear: action((state) => {
		state.envList = null;
	}),

	// thunks
	getTatumEnvironmentList: thunk(async (actions, payload) => {
		actions.clear();
		const result = await handleFetch<ApiArrayPayload<TatumEnvironment>>(getTatumEnvironmentListApi(payload));

		actions.save(result.payload);

		return result;
	}),
	getTatumEnvironment: thunk(async (_actions, payload) => {
		return await handleFetch<TatumEnvironment>(getTatumEnvironmentApi(payload));
	}),
	createTatumEnvironment: thunk(async (_actions, payload) => {
		return await handleFetch<TatumEnvironment>(createTatumEnvironmentApi(payload));
	}),
	updateTatumEnvironment: thunk(async (_actions, payload) => {
		return await handleFetch<TatumEnvironment>(updateTatumEnvironmentApi(payload));
	}),
	deleteTatumEnvironment: thunk(async (_actions, payload) => {
		return await handleFetch<TatumEnvironment>(deleteTatumEnvironmentApi(payload));
	}),

	// listeners
	onCreateTatumEnvironment: thunkOn(
		(actions, _storeActions) => actions.createTatumEnvironment,
		(actions, _payload) => {
			actions.getTatumEnvironmentList({});
		},
	),
	onUpdateTatumEnvironment: thunkOn(
		(actions, _storeActions) => actions.updateTatumEnvironment,
		(actions, _payload) => {
			actions.getTatumEnvironmentList({});
		},
	),
	onDeleteTatumEnvironment: thunkOn(
		(actions, _storeActions) => actions.deleteTatumEnvironment,
		(actions, _payload) => {
			actions.getTatumEnvironmentList({});
		},
	),
};

export default tatumEnvironment;
