import styled, { css } from 'styled-components/macro';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

export const EscrowSupportModalWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	text-align: left;
	font-family: secondary;
	font-size: 14px;
	line-height: 21px;

	h2 {
		font-size: 16px;
		font-family: primaryMedium;
		line-height: 21px;
	}
	span {
		text-align: right;
		font-family: secondary;
		font-size: 14px;
		line-height: 21px;
	}

	p {
		padding-bottom: 20px;
	}

	@media (max-width: 1024px) {
		padding: 0 30px;
	}
`;

export const ModalButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	button {
		margin-left: 14px;
	}
`;

export const EscrowStatusWrapper = styled.div<{ problem: boolean }>`
	background: ${(props) => (props.problem ? Color.RED_1 : Color.GRAY_3)};
	border: 1px solid ${(props) => (props.problem ? Color.RED_2 : Color.GRAY_5)};
	padding: 22px 64px;
	border-radius: 4px;
	font-family: secondary;
	font-size: 14px;
	margin: 24px 0;

	span {
		font-size: 16px;
		font-family: primaryMedium;
	}
	${media.maxSize800`
	  flex-direction: column;
	  padding: 22px 26px;
  `}
`;
export const EscrowStatusButtons = styled.div`
	display: flex;
	button {
		margin: 5px;
	}
	${media.maxSize800`
	  justify-content: flex-end;
  `}
`;
export const EscrowStatusHeader = styled.div<{ problem: boolean }>`
	width: 100%;
	overflow: none;
	position: relative;
	padding-right: 30px;
	h2 {
		font-size: 16px;
		font-family: primaryMedium;
		line-height: 21px;
		margin-bottom: 6px;
		${(props) =>
			props.problem &&
			css`
				color: ${Color.RED_2};
			`}
	}
	svg {
		position: absolute;
		left: -34px;
		height: 24px;
		width: 24px;
	}
	${media.maxSize800`
	  width: 100%;
	  padding-left: 34px;
	  svg {
		  left: 0;
	  }
  `}
`;
export const EscrowStatusText = styled.div`
	display: flex;
	overflow: none;
	p {
		height: 100%;
		margin: auto 0;
		padding-right: 14px;
		flex-shrink: 1;
		max-width: 40%;
		font-family: secondary;
		strong {
			font-family: secondary;
		}
	}
	${EscrowStatusButtons} {
		flex: 1;
	}
	${media.maxSize800`
	  width: 100%;
	  padding-left: 34px;
	  flex-direction: column;
	  p {
		flex: 1;
		max-width: 100%;
		margin-top: 0;
		margin-bottom: 40px;
	}
  `}
`;
