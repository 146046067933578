import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { UpdateSupportEmailResponse, User } from '../../../../api/userApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import {
	EscrowSupportModalWrapper,
	ModalButtonWrapper,
} from '../../../../components/escrow-state-display/EscrowStateDisplayStyle';
import { FieldOptionProps } from '../../../../components/field/Field';
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import Notification from '../../../../components/notification/Notification';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import HookSelectField from '../../../../components/react-hook-form/hook-select-field/HookSelectField';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import ReactHookForm, {
	HookFormColumn,
	HookFormSection,
	HookFormSeparator,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import {
	Currency,
	Scopes,
	Validations,
	AtmPaymentsDefault,
	UserRole,
	UserLoginType,
	MAX_LOGIN_CODE_LENGTH,
} from '../../../../constants';
import { Color } from '../../../../gfx/constants';
import { H1 } from '../../../../gfx/globals';
import assertUnreachable from '../../../../services/assert-unreachable';
import { getBaseCurrencyOptions } from '../../../../services/get-base-currency-options';
import { hasPermission } from '../../../../services/has-permission';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { ApiResponse } from '../../../../typings';
import { GoogleBox, QrText, TitleWrapper } from '../SettingsStyle';

export enum MerchantProfileViewModalType {
	LOGIN_CODE = 'LOGIN_CODE',
}

interface MerchantProfileFields {
	baseCurrency: FieldOptionProps;
	supportEmail: string;
	atmPaymentsDefault: AtmPaymentsDefault;
	loginCode: string;
}

function MerchantProfileView() {
	const [errorMessage, setErrorMessage] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalType, setModalType] = useState<MerchantProfileViewModalType | null>(null);
	const [isActionLoading, setIsActionLoading] = useState(false);

	const { activeViewer, verifications } = useStoreState((state) => ({
		activeViewer: state.viewer.activeViewer,
		verifications: state.verification.verifications,
	}));

	const { updateUser, updateSupportEmail } = useStoreActions((actions) => ({
		...actions.viewer,
	}));

	const merchantProfileValidationSchema = Yup.object<MerchantProfileFields>().shape({
		supportEmail: Validations.INVALID_EMAIL,
		baseCurrency: Yup.mixed().required('Please choose the currency'),
		atmPaymentsDefault: Yup.mixed().required(),
		loginCode: Yup.string()
			.test('isRequired', 'Verification code is required', (value) => {
				if (
					/* !askLoginCode */ !isModalOpen ||
					(isModalOpen && modalType !== MerchantProfileViewModalType.LOGIN_CODE)
				) {
					return true;
				}

				if (
					/* askLoginCode && !value */ isModalOpen &&
					modalType === MerchantProfileViewModalType.LOGIN_CODE &&
					!value
				) {
					return false;
				}

				return true;
			})
			.test('isLongEnough', 'Verification code should contain 6 characters', (value) => {
				if (
					/* !askLoginCode */ !isModalOpen ||
					(isModalOpen && modalType !== MerchantProfileViewModalType.LOGIN_CODE)
				) {
					return true;
				}

				if (
					/* askLoginCode && !value */ isModalOpen &&
					modalType === MerchantProfileViewModalType.LOGIN_CODE &&
					!value
				) {
					return false;
				}

				if (
					/* askLoginCode */ (isModalOpen &&
						modalType === MerchantProfileViewModalType.LOGIN_CODE &&
						value.length > MAX_LOGIN_CODE_LENGTH) ||
					value.length < MAX_LOGIN_CODE_LENGTH
				) {
					return false;
				}

				return true;
			}),
	});

	const defaultValues = {
		baseCurrency: { label: activeViewer?.baseCurrency, value: activeViewer?.baseCurrency },
		supportEmail: activeViewer?.supportEmail,
		atmPaymentsDefault: activeViewer?.atmPaymentsDefault,
		loginCode: '',
	};
	const methods = useForm<MerchantProfileFields>({
		resolver: yupResolver(merchantProfileValidationSchema),
		defaultValues: defaultValues,
		shouldFocusError: true,
		shouldUnregister: false,
		mode: 'onChange',
	});

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setModalType(null);
	};

	const getLoginCodeModal = () => (
		<Modal
			isOpen={true}
			ariaHideApp={false}
			onRequestClose={() => {
				handleCloseModal();
			}}
			small
			hasCloseButton
		>
			<EscrowSupportModalWrapper>
				<h2>Enter Google Authenticator code to confirm</h2>
				<GoogleBox>
					<QrText>
						<HookBaseField name="loginCode" label="Login code" placeholder="Code" />
					</QrText>
				</GoogleBox>
				<ModalButtonWrapper>
					<Button.Secondary
						white
						onClick={() => {
							methods.trigger('loginCode');
							handleCloseModal();
						}}
					>
						Cancel
					</Button.Secondary>
					<Button.Secondary type={ButtonTypes.SUBMIT} form="merchantProfile" green>
						Confirm
					</Button.Secondary>
				</ModalButtonWrapper>
			</EscrowSupportModalWrapper>
		</Modal>
	);

	const renderModal = () => {
		if (!modalType || !isModalOpen) {
			return;
		}

		switch (modalType) {
			case MerchantProfileViewModalType.LOGIN_CODE:
				return getLoginCodeModal();

			default:
				return assertUnreachable(modalType, `Unhandled discriminated union member: ${JSON.stringify(modalType)}`);
		}
	};

	if (!activeViewer) {
		return <Loader />;
	}

	const handleUserUpdate = async (input: MerchantProfileFields) => {
		let updateSupportEmailResult: ApiResponse<UpdateSupportEmailResponse> | null = null;
		let updateUserResult: ApiResponse<User> | null = null;
		setIsActionLoading(true);
		// user entered new value
		if (
			(input.baseCurrency && activeViewer.baseCurrency !== input.baseCurrency.value) ||
			(input.atmPaymentsDefault !== activeViewer.atmPaymentsDefault && activeViewer.role === UserRole.OWNER)
		) {
			updateUserResult = await updateUser({
				baseCurrency: Currency[input.baseCurrency.value as keyof typeof Currency],
				loginType: activeViewer.loginType,
				atmPaymentsDefault: activeViewer.role === UserRole.OWNER ? input.atmPaymentsDefault : AtmPaymentsDefault.OFF,
				loginCode: input.loginCode !== '' ? input.loginCode : undefined,
			});
		}
		setIsActionLoading(false);
		if (input.supportEmail && activeViewer.supportEmail !== input.supportEmail) {
			updateSupportEmailResult = await updateSupportEmail({
				supportEmail: input.supportEmail,
			});
		}

		if (updateUserResult && updateUserResult.payload && updateUserResult.success) {
			toast.success('Your profile has been successfully updated', {
				toastId: 'profile-updated-successfully',
			});

			return;
		}

		if (updateSupportEmailResult && updateSupportEmailResult.success) {
			toast.success('Your profile has been successfully updated', {
				toastId: 'profile-updated-successfully',
			});

			return;
		}

		if (updateUserResult && updateUserResult.error) {
			const serverValidationErrors = getServerValidationErrors<User>(updateUserResult);
			setErrorMessage(serverValidationErrors.errorMessage);

			return;
		}

		if (updateSupportEmailResult && updateSupportEmailResult.error) {
			const serverValidationErrors = getServerValidationErrors<UpdateSupportEmailResponse>(updateSupportEmailResult);
			setErrorMessage(serverValidationErrors.errorMessage);

			return;
		}
	};

	return (
		<View>
			<TitleWrapper>
				<H1>Merchant profile</H1>
			</TitleWrapper>
			<View.ListHeader hasNoBorder></View.ListHeader>
			{!activeViewer || !verifications ? (
				<Loader />
			) : (
				<PanelNew>
					<FormProvider {...methods}>
						{renderModal()}
						<ReactHookForm onSubmit={methods.handleSubmit(handleUserUpdate)} autoComplete="off">
							<PanelNew.Section first white panelType={PanelSectionType.FORM} separateWithBorder>
								<HookFormSection>
									<HookFormColumn>
										<HookFormSeparator>Support email</HookFormSeparator>
										{hasPermission([Scopes.USERS, Scopes.UPDATE_SUPPORT_EMAIL]) ? (
											<HookBaseField name="supportEmail" label="Support email" />
										) : (
											<HookBaseField name="supportEmail" label="Support email" disabled />
										)}
										<Notification noIcon>
											Contact information here will be displayed on your Dagpay invoices and can improve conversion
											rates and customer satisfaction
										</Notification>
									</HookFormColumn>
									<HookFormColumn>
										<HookFormSeparator>Currency pair</HookFormSeparator>
										<HookSelectField
											label="Local currency"
											name="baseCurrency"
											isSearchable={true}
											options={getBaseCurrencyOptions({ current: activeViewer.baseCurrency })}
										/>
										<Notification noIcon>
											Value of your dagcoins (base currency) in your preferred local currency
										</Notification>
									</HookFormColumn>
								</HookFormSection>
								{errorMessage && <View.Error>{errorMessage}</View.Error>}
							</PanelNew.Section>
							<PanelNew.Section gray last panelType={PanelSectionType.BUTTON}>
								{(methods.getValues().atmPaymentsDefault !== activeViewer.atmPaymentsDefault ||
									methods.getValues().baseCurrency.value !== activeViewer.baseCurrency) &&
								activeViewer.loginType === UserLoginType.AUTHENTICATOR ? (
									<Button
										isDisabled={!methods.formState.isDirty || isActionLoading}
										disabled={!methods.formState.isDirty || isActionLoading}
										alignedRight
										type={ButtonTypes.BUTTON}
										onClick={() => {
											setIsModalOpen(true);
											setModalType(MerchantProfileViewModalType.LOGIN_CODE);
										}}
									>
										{!isActionLoading ? 'Save changes' : <Loader size={20} color={Color.WHITE} width={120.5} />}
									</Button>
								) : (
									<Button
										isDisabled={!methods.formState.isDirty || isActionLoading || methods.formState.isSubmitting}
										disabled={!methods.formState.isDirty || isActionLoading || methods.formState.isSubmitting}
										alignedRight
										type={ButtonTypes.SUBMIT}
									>
										{!isActionLoading ? 'Save changes' : <Loader size={20} color={Color.WHITE} width={120.5} />}
									</Button>
								)}
							</PanelNew.Section>
						</ReactHookForm>
					</FormProvider>
				</PanelNew>
			)}
		</View>
	);
}

export default withRouter(MerchantProfileView);
