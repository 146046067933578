import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSVG from 'react-svg';
import Tooltip, { TooltipPlace, TooltipTheme } from '../tooltip/Tooltip';

import {
	AddOnWrapper,
	SubLabelWrapper,
	BaseInput,
	Border,
	Error,
	FieldWrapper,
	Label,
	LabelLink,
	LabelTooltipIcon,
} from './HookFieldsStyle';

interface CustomProps {
	name: string;
	placeholder?: string;
	label?: string;
	optional?: boolean;
	optionalBubble?: boolean;
	value?: string;
	readOnly?: boolean;
	addonNode?: React.ReactNode;
	subLabel?: React.ReactNode;
	type?: string;
	disabled?: boolean;
	labelLink?: string;
	labelLinkText?: string;
	size?: number;
	tabIndex?: number;
	addonSize?: number;
	tooltipContent?: JSX.Element | string;
	tooltipTheme?: TooltipTheme;
	tooltipPlace?: TooltipPlace;
	prefix?: string;
	subLabelClass?: string;
	hookBaseWidth?: string;
	hookBaseBackground?: string;
	addonHasToggle?: boolean;
	autocomplete?: string;
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & CustomProps;

export default function HookBaseField(props: Props) {
	const {
		name,
		placeholder,
		label,
		optional,
		optionalBubble,
		value,
		readOnly,
		addonNode,
		subLabel,
		disabled,
		labelLink,
		labelLinkText = 'Learn more',
		type,
		size,
		tabIndex,
		tooltipContent,
		tooltipPlace,
		tooltipTheme,
		subLabelClass,
		hookBaseWidth,
		hookBaseBackground,
		addonHasToggle,
		autocomplete,
		...rest
	} = props;
	const { register } = useFormContext();

	return (
		<FieldWrapper className={subLabelClass}>
			<Label optional={optional} optionalBubble={optionalBubble}>
				{label}
				{labelLink && (
					<LabelLink href={labelLink} target="_blank">
						{labelLinkText}
					</LabelLink>
				)}
				{tooltipContent && (
					<>
						<LabelTooltipIcon /* data-event="click" */ data-tip data-for={name}>
							<ReactSVG src="/files/svg/private/Info.svg" />
						</LabelTooltipIcon>
						<Tooltip tipId={name} content={tooltipContent} theme={tooltipTheme} place={tooltipPlace} />
					</>
				)}
			</Label>
			{props.prefix && <SubLabelWrapper>{props.prefix}</SubLabelWrapper>}
			<Border size={size} className={hookBaseWidth}>
				<BaseInput
					name={name}
					autocomplete={autocomplete}
					ref={register}
					spellCheck={false}
					placeholder={placeholder}
					value={value}
					readOnly={readOnly}
					type={type}
					step="any"
					onBlur={(e: React.ChangeEvent<HTMLInputElement>) => (e.currentTarget.scrollLeft = 0)}
					disabled={disabled}
					tabIndex={tabIndex}
					{...rest}
				/>
				{addonNode && (
					<AddOnWrapper
						addonSize={props.addonSize}
						hookBaseBackground={props.hookBaseBackground}
						addonHasToggle={props.addonHasToggle}
					>
						{addonNode}
					</AddOnWrapper>
				)}
			</Border>
			{subLabel && <SubLabelWrapper className={subLabelClass}>{subLabel}</SubLabelWrapper>}
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}
