import TypedAxios from 'restyped-axios';
import { ApiArrayPayload, ApiGetRequest, ApiResponse } from '../typings';
import { EnvironmentType } from './environmentApi';
import { User } from './userApi';

export const api = TypedAxios.create<TatumEnvironmentApi>();

const SCHEMA_PATH = '/tatum-environment';

export interface TatumEnvironmentApi {
	[SCHEMA_PATH]: {
		GET: {
			query: ApiGetRequest;
			response: ApiResponse<ApiArrayPayload<TatumEnvironment>>;
		};
		POST: {
			body: CreateTatumEnvironmentRequest;
			response: ApiResponse<TatumEnvironment>;
		};
		PUT: {
			body: UpdateTatumEnvironmentRequest;
			response: ApiResponse<TatumEnvironment>;
		};
	};

	'/tatum-environment/:environmentId': {
		GET: {
			query: GetTatumEnvironmentRequest;
			response: ApiResponse<TatumEnvironment>;
		};

		DELETE: {
			body: DeleteTatumEnvironmentRequest;
			response: ApiResponse<TatumEnvironment>;
		};
	};
}

export enum TatumEnvironmentType {
	TATUM = 'TATUM',
}

export enum TatumEnvironmentSubType {
	TRON = 'TRON',
}

export interface TatumEnvironment {
	id: string;
	userId: string;
	creatorId: string;
	creator?: User;
	masterAddress: string;
	withdrawalAddress: string;
	withdrawalAccountId: string;
	secret: string;
	apiKey: string;
	masterWalletPrivateKey: string;
	email: string;
	name: string;
	description?: string;
	maxPoolSize: number;
	poolBufferSize: number;
	fee: number;
	paymentThreshold: number;
	gasPumpAddressExpiryTimeInHours: number;
	statusUrl: string;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
	integrationType: EnvironmentType;
	tatumEnvironmentType: TatumEnvironmentSubType;
}

export interface CreateTatumEnvironmentRequest {
	masterAddress: string;
	withdrawalAddress: string;
	withdrawalAccountId: string;
	apiKey: string;
	masterWalletPrivateKey: string;
	email: string;
	name: string;
	description?: string;
	maxPoolSize: number;
	poolBufferSize: number;
	fee: number;
	paymentThreshold: number;
	gasPumpAddressExpiryTimeInHours: number;
	statusUrl: string;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
}

export interface GetTatumEnvironmentRequest {
	environmentId: string;
}

export interface UpdateTatumEnvironmentRequest {
	id: string;
	masterAddress: string;
	withdrawalAddress: string;
	withdrawalAccountId: string;
	apiKey: string;
	masterWalletPrivateKey: string;
	email: string;
	name: string;
	description: string | null;
	maxPoolSize: number;
	poolBufferSize: number;
	fee: number;
	paymentThreshold: number;
	gasPumpAddressExpiryTimeInHours: number;
	statusUrl: string;
	redirectSuccessUrl: string;
	redirectCancelUrl: string;
	redirectFailUrl: string;
}

export interface DeleteTatumEnvironmentRequest {
	environmentId: string;
}

/* helpers */

interface Description {
	name: string;
	help?: string;
	download?: string;
}

type TatumEnvironmentDescription = {
	[environmentType in keyof typeof TatumEnvironmentSubType]: Description;
};

const environmentDescriptions: TatumEnvironmentDescription = {
	[TatumEnvironmentSubType.TRON]: {
		name: 'Tron',
	},
};

export function getEnvInfo(type: TatumEnvironmentSubType) {
	return environmentDescriptions[type];
}

/* API-s */

export const getTatumEnvironmentApi = async (params: GetTatumEnvironmentRequest) => {
	const response = await api.get<'/tatum-environment/:environmentId'>(`${SCHEMA_PATH}/${params.environmentId}`);

	return response.data;
};

export const getTatumEnvironmentListApi = async (params: ApiGetRequest) => {
	const response = await api.get(SCHEMA_PATH, {
		params: { ...params },
	});

	return response.data;
};

export const createTatumEnvironmentApi = async (params: CreateTatumEnvironmentRequest) => {
	const response = await api.post(SCHEMA_PATH, {
		...params,
	});
	return response.data;
};

export const updateTatumEnvironmentApi = async (params: UpdateTatumEnvironmentRequest) => {
	const response = await api.put(SCHEMA_PATH, {
		...params,
	});
	return response.data;
};

export const deleteTatumEnvironmentApi = async (params: DeleteTatumEnvironmentRequest) => {
	const response = await api.delete<'/tatum-environment/:environmentId'>(`${SCHEMA_PATH}/${params.environmentId}`);
	return response.data;
};
