import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { Invoice } from '../../../../../../api/invoiceApi';
import ColoredInvoiceState from '../../../../../../components/colored-invoice-state/ColoredInvoiceState';
import InvoicePrice from '../../../../../../components/invoice-price/InvoicePrice';
import Loader from '../../../../../../components/loader/Loader';
import Table from '../../../../../../components/table/Table';
import WithPermission from '../../../../../../components/with-permission/WithPermission';
import { GLOBAL_DATE_FORMAT, RoutesUrls, Scopes } from '../../../../../../constants';
import { TransactionColSizes } from '../../../../../../gfx/constants';
import { ColumnedFlex, H2, HorizontalLine, Light } from '../../../../../../gfx/globals';
import getInvoiceType from '../../../../../../services/get-invoice-type';
import Notification from '../../../../../../components/notification/Notification';
import { BorderSpacer } from './LatestTransactionsStyle';

interface Props {
	invoices: Invoice[];
}

const LatestTransactions = (props: Props) => {
	if (!props.invoices) {
		return <Loader />;
	}
	return (
		<>
			<H2 alignLeft>Latest successful transactions</H2>
			<BorderSpacer></BorderSpacer>
			<Table.LatestTransactions>
				<Table.Thead hiddenOnSmallerScreen>
					<tr>
						<th>Date & Time</th>
						<th>Type</th>
						<th>Status</th>
						<th>Amount</th>
					</tr>
				</Table.Thead>
				<tbody>
					{props.invoices.map((invoice) => (
						<React.Fragment key={invoice.id}>
							<Table.TrPrimary>
								<Table.FixedWidthTd cellWidth={TransactionColSizes.DATE_AND_TIME}>
									<Table.DataWrapper data-label="Date & Time">
										<ColumnedFlex>
											{dayjs(invoice.createdDate).format(GLOBAL_DATE_FORMAT)}
											<Light>{dayjs(invoice.createdDate).format('HH:mm')}</Light>
										</ColumnedFlex>
									</Table.DataWrapper>
								</Table.FixedWidthTd>
								<Table.FixedWidthTd cellWidth={TransactionColSizes.TYPE}>
									<Table.DataWrapper data-label="Type">{getInvoiceType(invoice.type)}</Table.DataWrapper>
								</Table.FixedWidthTd>
								<Table.FixedWidthTd cellWidth={TransactionColSizes.STATUS}>
									<Table.DataWrapper data-label="Status">
										<ColoredInvoiceState state={invoice.state} />
									</Table.DataWrapper>
								</Table.FixedWidthTd>

								<Table.FixedWidthTd cellWidth={TransactionColSizes.AMOUNT}>
									<Table.DataWrapper data-label="Amount">
										<InvoicePrice
											currencyAmount={invoice.currencyAmount}
											coinAmount={invoice.coinAmount}
											currency={invoice.currency}
										/>
									</Table.DataWrapper>
								</Table.FixedWidthTd>
							</Table.TrPrimary>
						</React.Fragment>
					))}
				</tbody>
			</Table.LatestTransactions>
			{props.invoices.length === 0 && (
				<>
					<HorizontalLine />
					<Notification center>
						You haven't made or received any transactions yet. Start by{' '}
						<WithPermission permissions={[Scopes.POS, Scopes.CREATE_POS_INVOICE]}>
							creating your first <Link to={RoutesUrls.POS_CHECKOUT}>POS checkout</Link>,{' '}
						</WithPermission>
						<WithPermission permissions={[Scopes.EMAIL_INVOICES, Scopes.CREATE_EMAIL_INVOICE]}>
							send an <Link to={RoutesUrls.EMAIL_INVOICES}>email invoice</Link>{' '}
						</WithPermission>
						<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT]}>
							or integrate your website using the <Link to={RoutesUrls.MERCHANT_TOOLS}>Merchant tools</Link>
						</WithPermission>
					</Notification>
				</>
			)}
		</>
	);
};

export default LatestTransactions;
