import React from 'react';
import styled from 'styled-components/macro';

import { Currency } from '../../constants';
import { ColumnedFlex, Light } from '../../gfx/globals';
import round from '../../services/round';

interface Props {
	currencyAmount: number;
	coinAmount: number;
	currency: Currency | null;
}

export const DagAmount = styled.div`
	line-height: 26px;
	font-size: 16px;
	font-family: Primary;
	font-weight: 500;
`;

export default function InvoicePrice(props: Props) {
	const { coinAmount, currency, currencyAmount } = props;

	if (currency === Currency.DAG) {
		return (
			<DagAmount>
				{coinAmount} <span>DAG</span>
			</DagAmount>
		);
	}

	return (
		<ColumnedFlex>
			<DagAmount>
				{coinAmount} <span>DAG</span>
			</DagAmount>
			<Light>{`${round(currencyAmount)} ${currency}`}</Light>
		</ColumnedFlex>
	);
}
