import { FieldArray, FieldArrayRenderProps } from 'formik';
import { FormikFieldState } from 'formik-fields';
import React, { useEffect, useRef, useState } from 'react';
import ReactSVG from 'react-svg';

import { FieldTypes } from '../../constants';
import { Color } from '../../gfx/constants';
import { CheckboxText } from '../checkbox/CheckboxStyle';
import Field, { MultiSelectFilter } from '../field/Field';

import {
	DropSearch,
	MultiSelectDropDown,
	MultiSelectLabel,
	MultiSelectWrap,
	NoMatchingItems,
	ScrollableElement,
	SearchWrap,
	SelectableElement,
	StyledMultiSelect,
	SelectArrow,
	RotateArrow,
} from './MultiSelectFieldStyle';

interface Props {
	formikField: FormikFieldState<MultiSelectFilter[]>;
	searchBar: boolean;
	placeholder?: string;
}

interface MultiSelectDropdownListProps extends Props {
	arrayHelpers: FieldArrayRenderProps;
}
interface SearchBarProps extends MultiSelectDropdownListProps {
	searchRef: React.MutableRefObject<HTMLInputElement | null>;
}

export default function MultiSelectField(props: Props) {
	const { formikField, placeholder } = props;
	const [dropdownRef] = useState(React.createRef<HTMLDivElement>());
	const [optionsVisible, setOptionsVisible] = useState(false);

	const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
		const currentElement = event.target;

		if (
			dropdownRef &&
			dropdownRef.current &&
			currentElement instanceof Node &&
			!dropdownRef.current.contains(currentElement)
		) {
			setOptionsVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const getFieldLabelInfo = () => {
		const checkedOptionList = formikField.value.filter((option) => option.checked === true);
		const everythingSelected = checkedOptionList.length === formikField.value.length;

		if (everythingSelected) {
			return {
				label: 'all',
				count: 1,
			};
		}
		return checkedOptionList.length > 1
			? { label: 'multiple', count: checkedOptionList.length }
			: checkedOptionList.length === 1
			? {
					label: checkedOptionList[0].label,
					count: checkedOptionList.length,
			  }
			: {
					label: placeholder,
					count: 1,
					color: Color.GRAY_TEXT,
			  };
	};

	const handleMultiFieldClick = () => {
		setOptionsVisible(!optionsVisible);

		if (!optionsVisible) {
			formikField.setValue(
				formikField.value.map((option) => {
					return { ...option, hidden: false };
				}),
			);
		}
	};

	const fieldLabelInfo = getFieldLabelInfo();

	return (
		<FieldArray
			name={formikField.name}
			render={(arrayHelpers) => (
				<MultiSelectWrap ref={dropdownRef}>
					<StyledMultiSelect onClick={handleMultiFieldClick}>
						<MultiSelectLabel color={fieldLabelInfo.color} countVisible={fieldLabelInfo.count > 1}>
							{fieldLabelInfo.label === 'merchant'
								? 'Integration'
								: fieldLabelInfo.label === 'pos'
								? 'POS'
								: fieldLabelInfo.label}
							<span>{fieldLabelInfo.count}</span>
						</MultiSelectLabel>
					</StyledMultiSelect>
					{optionsVisible && (
						<MultiSelectDropDown>
							<MultiSelectDropdownList {...props} arrayHelpers={arrayHelpers} />
						</MultiSelectDropDown>
					)}
					<SelectArrow onClick={handleMultiFieldClick}>
						{optionsVisible ? (
							<RotateArrow>
								<ReactSVG src="/files/svg/private/Arrow.svg" />
							</RotateArrow>
						) : (
							<ReactSVG src="/files/svg/private/Arrow.svg" />
						)}
					</SelectArrow>
				</MultiSelectWrap>
			)}
		/>
	);
}

function MultiSelectDropdownList(props: MultiSelectDropdownListProps) {
	const { arrayHelpers, formikField, searchBar } = props;

	const searchRefContainer = useRef<HTMLInputElement | null>(null);

	const hasNoMatchingItems = () => {
		const noVisibleElements = !formikField.value.some((option) => option.hidden === false);

		return noVisibleElements && <NoMatchingItems>No matching {formikField.name}</NoMatchingItems>;
	};

	const handleOnChange = (field: MultiSelectFilter) => {
		const indexOfAll = formikField.value.findIndex((option) => option.value === 'ALL');

		if (field.value === 'ALL') {
			// value before checking or unchecking
			const isAllChecked = field.checked === true;

			formikField.setValue(
				formikField.value.map((option) => {
					return { ...option, checked: !isAllChecked };
				}),
			);
		}

		if (field.value !== 'ALL') {
			arrayHelpers.replace(formikField.value.indexOf(field), { ...field, checked: !field.checked });

			// only possible unchecked are All and the field
			const everythingSelected =
				formikField.value.filter((option) => option.checked === true).length === formikField.value.length - 2 &&
				!field.checked;

			// to hide All option from search
			const searchEnabled = formikField.value.some((option) => option.hidden === true);

			arrayHelpers.replace(indexOfAll, {
				value: formikField.value[indexOfAll].value,
				label: formikField.value[indexOfAll].label,
				checked: everythingSelected,
				hidden: searchEnabled,
			});
		}

		// move focus back to search
		if (searchRefContainer && searchRefContainer.current) {
			searchRefContainer.current.focus();
		}
	};

	return (
		<>
			{searchBar && <SearchBar {...props} searchRef={searchRefContainer} />}
			{searchBar && hasNoMatchingItems()}
			<ScrollableElement>
				{formikField.value.map((field, index) => {
					return (
						<SelectableElement key={`box-${index}`} hidden={field.hidden}>
							<Field
								formikField={formikField}
								type={FieldTypes.CHECKBOX}
								checked={field.checked}
								hasErrorField={false}
								onChange={() => handleOnChange(field)}
							>
								<CheckboxText isBlack>
									{field.label === 'merchant'
										? 'Integration'
										: field.label === 'pos'
										? 'POS'
										: truncateIfLonger(field.label, 35)}
								</CheckboxText>
							</Field>
						</SelectableElement>
					);
				})}
			</ScrollableElement>
		</>
	);
}

function SearchBar(props: SearchBarProps) {
	const { formikField, searchRef, arrayHelpers /* , displayList */ } = props;

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = event.target.value.toLowerCase();

		formikField.setValue(
			formikField.value.map((option) => {
				// hide everything that doest contain search and hide All anyways
				const isHidden = searchValue.length > 0 && (option.label.indexOf(searchValue) === -1 || option.value === 'ALL');

				return { ...option, hidden: isHidden };
			}),
		);
	};

	const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// Allow selecting with enter when something on search field
		if (event.keyCode === 13 && searchRef && searchRef.current && searchRef.current.value !== '') {
			// disable submiting form
			event.preventDefault();

			// always toggle only first search item
			const firstVisible = formikField.value.find((option) => option.hidden === false);

			// allow selecting only when search has result
			if (!firstVisible) {
				return;
			}

			const indexOfFirstVisible = formikField.value.indexOf(firstVisible);

			// sort the list to ensure user will see selected option in front of the srollable list
			formikField.setValue(
				getSortedOptionsList(
					formikField.value.map((option, index) => {
						return {
							...option,
							checked: indexOfFirstVisible !== index ? option.checked : !option.checked,
							hidden: false,
						};
					}),
				),
			);

			// check if all options are selected
			const checkAll =
				formikField.value.filter((option) => option.value !== 'ALL' && option.checked === false).length === 1 &&
				formikField.value[indexOfFirstVisible].checked === false;

			const indexOfAll = formikField.value.findIndex((option) => option.value === 'ALL');

			arrayHelpers.replace(indexOfAll, {
				...formikField.value[indexOfAll],
				checked: checkAll,
				hidden: false,
			});

			// clear search field
			if (searchRef && searchRef.current) {
				searchRef.current.value = '';
			}
		}
	};

	return (
		<DropSearch>
			<SearchWrap>
				<ReactSVG src="/files/svg/icons/Search.svg"></ReactSVG>
				<input
					autoFocus
					ref={searchRef}
					type="text"
					placeholder={`Search for ${formikField.name}`}
					onChange={handleOnChange}
					onKeyDown={handleOnKeyDown}
				/>
			</SearchWrap>
		</DropSearch>
	);
}

function getSortedOptionsList(optionsList: MultiSelectFilter[]) {
	const copy = [...optionsList];

	const sortedCheckedOptionList = copy
		.filter((option) => option.checked === true && option.value !== 'ALL')
		.sort(sortFunction);
	const sortedUnCheckedOptionList = copy
		.filter((option) => option.checked === false && option.value !== 'ALL')
		.sort(sortFunction);

	const sortedArr = sortedCheckedOptionList.concat(sortedUnCheckedOptionList);

	sortedArr.unshift(optionsList.filter((option) => option.value === 'ALL')[0]);

	return sortedArr;
}

function sortFunction(a: MultiSelectFilter, b: MultiSelectFilter) {
	return a.label.toUpperCase().localeCompare(b.label.toUpperCase());
}

function truncateIfLonger(label: string, charAmount: number) {
	return label.length > charAmount ? `${label.substr(0, charAmount - 1)} ...` : label;
}
