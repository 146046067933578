import { PrivateRoute } from '../views/private/routes';

import { hasPermission } from './has-permission';

export default function getMergedRoutes(routes: PrivateRoute[]) {
	const mergedRoutes = routes.reduce((list: PrivateRoute[], parentRoute) => {
		if (hasPermission(parentRoute.permissions)) {
			list.push(parentRoute);
		}

		if (parentRoute.children) {
			parentRoute.children.forEach((childRoute) => {
				if (hasPermission(childRoute.permissions)) {
					list.push(childRoute);
				}
			});
		}

		return list;
	}, []);

	return mergedRoutes;
}
