import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import { EmailInvoice } from '../../../../api/invoiceApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import CalculatedInvoiceTotal from '../../../../components/calculated-invoice-total/CalculatedInvoiceTotal';
import { FieldOptionProps } from '../../../../components/field/Field';
import Form from '../../../../components/form/Form';
import Loader from '../../../../components/loader/Loader';
import Notification from '../../../../components/notification/Notification';
import View from '../../../../components/view/View';
import {
	Currency,
	EMAIL_INVOICE_VALID_MAX_SECONDS,
	EMAIL_INVOICE_VALID_MIN_SECONDS,
	RoutesUrls,
	UserRole,
} from '../../../../constants';
import { H1, H2, HorizontalLine } from '../../../../gfx/globals';
import { getBaseCurrencyOptions } from '../../../../services/get-base-currency-options';
import { getWalletOptions } from '../../../../services/get-wallet-options';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';

import Fade from 'react-reveal';
import HookDatePicker from '../../../../components/react-hook-form/HookDatePicker';
import ReactHookForm, {
	HookFormColumn,
	HookFormCurrencySection,
	HookFormSection,
	NotificationBelowInput,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import {} from '../../../../components/react-hook-form/HookFieldsStyle';
import HookCcField from '../../../../components/react-hook-form/HookCcField';
import { FormProvider, useForm } from 'react-hook-form';
import HookSelectField from '../../../../components/react-hook-form/hook-select-field/HookSelectField';
import { yupResolver } from '@hookform/resolvers/yup';
import { Color } from '../../../../gfx/constants';
import { AddOnNode } from '../../../../components/react-hook-form/hook-addon-fields/HookAddonFieldStyles';
import HookAddonSelectField from '../../../../components/react-hook-form/hook-addon-fields/HookAddonSelectField';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import HookTextAreaField from '../../../../components/react-hook-form/HookTextAreaField';
import { DealTotalText, EscrowTotalWrapper, TotalItem } from '../escrow/EscrowNewDealViewStyle';
import PreviousLink from '../../../../components/previous-link/PreviousLink';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { BusinessTierLimitsResponse } from '../../../../api/authApi';
import { Rates } from '../../../../api/ratesApi';
import { LimitNotificationText } from '../pos-checkout/PosCheckoutViewStyle';
import ReactSVG from 'react-svg';
dayjs.extend(duration);

export const InvoiceTotalAmount = styled.div`
	color: ${Color.OPTIONAL_TEXT};
	display: flex;
	flex-direction: column;
	font-size: 15px;
	font-weight: 500;
	line-height: 18px;
	margin-right: 100px;
	width: 200px;
	margin-left: auto;
	text-align: left;
	margin-bottom: 84px;

	span {
		margin-bottom: 11px;
		font-family: PrimaryMedium;
	}
`;

export const ShowCc = styled.div`
	div {
		height: auto !important;
	}
	div * {
		height: unset !important;
	}
`;

export const SpinnerWrap = styled.div`
	div {
		border-color: ${Color.WHITE};
	}
`;

export interface OneTimeInvoiceFields {
	to: string;
	subject: string;
	dueDate: Date;
	tax: number | null;
	paymentId: string;
	currency: FieldOptionProps;
	walletId: FieldOptionProps | null;
	cc: string;
	description: string;
	currencyAmount: number | null;
}

interface RouteParams {
	id?: string;
}

export enum InvoiceType {
	RECURRING = 'RECURRING',
	ONE_TIME = 'ONE_TIME',
}

function OneTimeInvoiceDetailView(props: RouteProps<RouteParams>) {
	const [isMobile, setIsMobile] = useState(false);

	const handleResize = () => {
		if (window.innerWidth < 1024) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		handleResize();
	});

	// const [errorMessage, setErrorMessage] = useState('');
	const [isCcVisible, setIsCcVisible] = useState(false);
	const [ccEmails, setCcEmails] = useState<string[]>([]);
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [viewerLimits, setViewerLimits] = useState<BusinessTierLimitsResponse | null>(null);
	const [walletOptions, setWalletOptions] = useState<FieldOptionProps[] | null>(null);

	const { wallets, rates, activeViewer } = useStoreState((state) => ({
		rates: state.rates.rates,
		activeViewer: state.viewer.activeViewer,
		wallets: state.wallet.wallets,
	}));

	const { getRates, createEmailInvoice, getWallets, getViewerBusinessLimits } = useStoreActions((actions) => ({
		...actions.rates,
		...actions.invoice,
		...actions.wallet,
		...actions.viewer,
	}));

	useEffect(() => {
		const fetchWallets = async () => {
			await getWallets({});
		};
		const fetchViewerLimits = async () => {
			if (!viewerLimits) {
				setViewerLimits((await getViewerBusinessLimits()).payload);
			}
		};
		if (!wallets) {
			fetchWallets();
		}
		if (!viewerLimits) {
			fetchViewerLimits();
		}
		if (wallets) {
			setWalletOptions(getWalletOptions(wallets));
		}
	}, [wallets, getWallets, getViewerBusinessLimits, viewerLimits]);

	useEffect(() => {
		const fetchRates = async () => {
			await getRates();
		};
		fetchRates();
	}, [getRates]);

	const defaultValues = {
		currency: {
			label: activeViewer ? activeViewer.baseCurrency : 'DAG',
			value: activeViewer ? activeViewer.baseCurrency : 'DAG',
		},
		to: '',
		cc: '',
		subject: '',
		dueDate: new Date(),
		tax: null,
		paymentId: '',
		walletId: wallets ? getWalletOptions(wallets).filter((w) => w.isDefault)[0] : null,
		description: '',
		currencyAmount: null,
	};

	const maxDueDate = dayjs().add(EMAIL_INVOICE_VALID_MAX_SECONDS, 'seconds').toDate();
	const userRole = activeViewer ? activeViewer.role : null;
	const isWalletSelectVisible =
		userRole &&
		(userRole === UserRole.OWNER ||
			userRole === UserRole.ADMIN ||
			userRole === UserRole.ADVANCED_TEAM_MEMBER ||
			userRole === UserRole.TEAM_MEMBER);

	const oneTimeInvoiceValidationSchema = Yup.object<OneTimeInvoiceFields>().shape({
		to: Yup.string()
			.email('Invalid email')
			.max(255, 'The maximum length is 255 characters')
			.required('Email is required'),
		subject: Yup.string()
			.max(100, 'The maximum length is 100 characters')
			.trim()
			.required('Subject cannot be empty or contain only spaces'),
		dueDate: Yup.date().typeError('Date is required').required('Due date is required'),
		paymentId: Yup.string().max(128, 'Custom ID cannot be longer than 128 characters').notRequired(),
		currencyAmount: Yup.number()
			.typeError('Must be a number with a dot as a decimal separator')
			.min(0.000001, 'Minimum amount of 0.000001 is required')
			.max(10000000, 'Maximum amount of 10000000 is allowed')
			.required('Amount is required')
			.test({
				name: 'validate-limit',
				test: function (currencyAmountValue) {
					var limitAmount = userVolumeLimits;
					return currencyAmountValue >= limitAmount && limitAmount < 0
						? this.createError({
								message: `Remaining monthly volume until limit ${limitAmount.toFixed(2)}`,
								path: 'currencyAmount',
						  })
						: true;
				},
			}),
		tax: Yup.number()
			.typeError('Must be a number with a dot as a decimal separator')
			.min(0.01, 'Minimum amount is 0.01 ')
			.max(100.0, 'Maximum amount is 100')
			.nullable(true)
			.notRequired()
			.transform((currentValue, originalValue) => (originalValue === '' ? null : currentValue)), //typerrror considers empty string to be NaN instead of null,
		cc: Yup.string().email('Invalid email'),
		currency: Yup.mixed(),
		walletId: Yup.object<FieldOptionProps>().typeError('Please choose the wallet'),
		description: Yup.string().max(1024, 'The maximum length is 1024 characters'),
	});

	const methods = useForm<OneTimeInvoiceFields>({
		resolver: yupResolver(oneTimeInvoiceValidationSchema),
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	const currencyAmountWatch = methods.watch('currencyAmount', 0);
	const taxWatch = methods.watch('tax', 0);
	const currencyField = methods.watch().currency;

	const userVolumeLimits =
		viewerLimits && viewerLimits.volumeLimit.dag !== null && rates !== null
			? (viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
					rates[currencyField.value as keyof Rates] -
			  (currencyAmountWatch
					? taxWatch
						? parseFloat(currencyAmountWatch.toString()) +
						  parseFloat(currencyAmountWatch.toString()) * (parseFloat(taxWatch.toString()) / 100)
						: currencyAmountWatch
					: 0)
			: 0;

	if (!rates || !activeViewer || !wallets || !walletOptions) {
		return <Loader />;
	}

	const handleSaveInvoice = async (input: OneTimeInvoiceFields) => {
		const { dueDate, currency, ...newInput } = input;
		let uniqueCcEmails: string[] = [];

		if (!newInput.walletId) {
			return methods.setError('walletId', { message: 'Please choose the wallet', type: 'manual' });
		}

		const isSameDay = dayjs(dueDate).isSame(dayjs(), 'day');
		const durationUntilEndOfDay = dayjs.duration(dayjs().endOf('day').subtract(1, 'minute').diff(dayjs()));
		const durationUntilDueDate = dayjs.duration(dayjs(dueDate).diff(dayjs()));

		const duration = isSameDay ? durationUntilEndOfDay : durationUntilDueDate;

		if (isCcVisible) {
			const ccEmailsCopy = ccEmails.slice();

			// grab email which is not inserted with space or comma
			if (newInput.cc && newInput.cc.length !== 0) {
				ccEmailsCopy.push(newInput.cc);
			}

			// remove duplicates if any
			uniqueCcEmails = [...new Set(ccEmailsCopy)];
			uniqueCcEmails = uniqueCcEmails.filter((ccEmail) => ccEmail !== newInput.to);
		}
		// const currencyAmount = parseFloat(typeof newInput.currencyAmount === 'string' ? newInput.currencyAmount : '0');
		// let tax = parseFloat(typeof newInput.tax === 'string' ? newInput.tax : '0');
		// tax = !newInput.tax || isNaN(newInput.tax) ? 0 : tax;

		setIsActionLoading(true);
		const result = await createEmailInvoice({
			...newInput,
			currency: Currency[currencyField.value as keyof typeof Currency],
			currencyAmount: input.currencyAmount ? input.currencyAmount : 0,
			tax: newInput.tax ? newInput.tax : 0,
			walletId: newInput.walletId
				? newInput.walletId.value.toString()
				: (walletOptions && walletOptions.filter((w) => w.isDefault)[0].value.toString()) || '',
			validForSeconds:
				duration.asSeconds() < EMAIL_INVOICE_VALID_MIN_SECONDS ? EMAIL_INVOICE_VALID_MIN_SECONDS : duration.asSeconds(),
			cc: uniqueCcEmails,
		});
		setIsActionLoading(false);

		if (result.success) {
			props.history.push(RoutesUrls.EMAIL_INVOICES);
			toast.success(`Invoice sent!`);

			return;
		}

		if (result.error) {
			const serverValidationErrors = getServerValidationErrors<EmailInvoice>(result);
			for (var key in serverValidationErrors.errors) {
				methods.setError(key as never, { message: serverValidationErrors.errors[key] });
			}
			return false;
		}
	};

	return (
		<View>
			<H1>Create new invoice</H1>
			<View.ListHeader hasNoBorder></View.ListHeader>
			<FormProvider {...methods}>
				<ReactHookForm onSubmit={methods.handleSubmit(handleSaveInvoice)} autoComplete="off">
					<PanelNew>
						<PanelNew.Section first white panelType={PanelSectionType.FORM}>
							<H2>Sender & Receiver details</H2>
							<HorizontalLine />
							<Notification>By {activeViewer && activeViewer.companyName}</Notification>
							<HookFormSection>
								<HookFormColumn>
									<HookBaseField
										label="To"
										name="to"
										placeholder="Email address"
										addonSize={116}
										addonNode={
											<AddOnNode toggle={isCcVisible} onClick={() => setIsCcVisible(!isCcVisible)}>
												{isCcVisible ? (
													<>
														Hide Cc <ReactSVG src="/files/svg/private/EyeClose.svg" />
													</>
												) : (
													<>
														Add Cc <ReactSVG src="/files/svg/private/EyeOpen.svg" />
													</>
												)}
											</AddOnNode>
										}
										addonHasToggle={true}
										hookBaseBackground="white"
										tabIndex={1}
									/>
								</HookFormColumn>
								{isCcVisible && isMobile && (
									<ShowCc>
										<Fade collapse when={isCcVisible}>
											<HookFormSection>
												<HookCcField label="Cc recipients" name="cc" setCcEmails={setCcEmails} ccEmails={ccEmails} />
												<View.Description left>Add Cc recipients separated by space or comma key</View.Description>
											</HookFormSection>
										</Fade>
									</ShowCc>
								)}
								<HookFormColumn>
									<HookBaseField name="subject" label="Subject" tabIndex={2} />
								</HookFormColumn>
							</HookFormSection>
							{isCcVisible && !isMobile && (
								<ShowCc>
									<Fade collapse when={isCcVisible}>
										<HookFormSection>
											<HookCcField label="Cc recipients" name="cc" setCcEmails={setCcEmails} ccEmails={ccEmails} />
											<View.Description left>Add Cc recipients separated by space or comma key</View.Description>
										</HookFormSection>
									</Fade>
								</ShowCc>
							)}
							<H2>Invoice details</H2>
							<HorizontalLine />
							<HookFormSection>
								<HookFormColumn>
									<HookDatePicker
										id="date"
										name="dueDate"
										label="Due date"
										minDate={dayjs().startOf('day').toDate()}
										maxDate={maxDueDate}
									/>
									<HookFormCurrencySection>
										<HookFormColumn>
											<HookBaseField
												name="currencyAmount"
												label="Amount to pay"
												addonNode={
													<HookAddonSelectField
														name="currency"
														options={getBaseCurrencyOptions({ current: activeViewer.baseCurrency })}
														tabIndex={3}
														selectedColor={Color.GRAY_2}
														focusedColor={Color.GRAY_0}
														isSearchable
														formatOptionLabel={(option: any, { context }: any) => {
															return context === 'menu' ? option.label : option.label.split(' - ')[0];
														}}
													/>
												}
												addonSize={90}
												tabIndex={5}
											/>
											{methods.errors.currencyAmount === undefined
												? viewerLimits &&
												  viewerLimits.volumeLimit.dag !== null && (
														<NotificationBelowInput>
															Remaining monthly volume until limit{' '}
															<LimitNotificationText
																error={
																	(viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
																		rates[currencyField.value as keyof Rates] -
																		(currencyAmountWatch
																			? taxWatch
																				? //Hookform does not enforce interface types properly
																				  parseFloat(currencyAmountWatch.toString()) +
																				  parseFloat(currencyAmountWatch.toString()) *
																						(parseFloat(taxWatch.toString()) / 100)
																				: currencyAmountWatch
																			: 0) <
																	0
																}
															>
																{(
																	(viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
																		rates[currencyField.value as keyof Rates] -
																	(currencyAmountWatch
																		? taxWatch
																			? //Hookform does not enforce interface types properly
																			  parseFloat(currencyAmountWatch.toString()) +
																			  parseFloat(currencyAmountWatch.toString()) *
																					(parseFloat(taxWatch.toString()) / 100)
																			: currencyAmountWatch
																		: 0)
																).toFixed(2)}{' '}
																<strong>{currencyField.label}</strong>
															</LimitNotificationText>
														</NotificationBelowInput>
												  )
												: null}
										</HookFormColumn>
										<HookFormColumn>
											<Form.TaxSection>
												<HookBaseField
													name="tax"
													label="Include tax"
													addonNode={'%'}
													addonSize={48}
													tabIndex={6}
													size={104}
												/>
											</Form.TaxSection>
										</HookFormColumn>
									</HookFormCurrencySection>
								</HookFormColumn>
								<HookFormColumn>
									<HookBaseField name="paymentId" label="Custom ID or reference nr" optional tabIndex={4} />
									{isWalletSelectVisible && (
										<HookSelectField
											label="Wallet"
											name="walletId"
											options={walletOptions}
											tabIndex={7}
											selectedColor={Color.GRAY_2}
											focusedColor={Color.GRAY_0}
											isSearchable
										/>
									)}
								</HookFormColumn>
							</HookFormSection>
							<HookTextAreaField name="description" label="Description" tabIndex={8} rows={3} />
							{/* {errorMessage && <View.Error customLinkColor={Color.RED_ERROR}>{errorMessage}</View.Error>} */}
						</PanelNew.Section>
						<PanelNew.Section middle gray separateWithBorder>
							<EscrowTotalWrapper>
								<H2>Invoice total</H2>
								<HorizontalLine />
								<TotalItem>
									<DealTotalText>Total Amount</DealTotalText>
									<CalculatedInvoiceTotal
										rates={rates}
										tax={taxWatch ? taxWatch : 0}
										currencyAmount={currencyAmountWatch ? currencyAmountWatch : 0}
										currency={Currency[currencyField.value as keyof typeof Currency]}
									/>
								</TotalItem>
							</EscrowTotalWrapper>
						</PanelNew.Section>
						<PanelNew.Section last gray panelType={PanelSectionType.BUTTON}>
							<PreviousLink title="Back" to={RoutesUrls.EMAIL_INVOICES} />
							<PanelNew.ActionButtonsWrapper>
								<Button.Secondary green type={ButtonTypes.SUBMIT} isDisabled={isActionLoading} tabIndex={9}>
									{!isActionLoading ? 'Send invoice' : <Loader size={20} color={Color.WHITE} width={120.5} />}
								</Button.Secondary>
							</PanelNew.ActionButtonsWrapper>
						</PanelNew.Section>
					</PanelNew>
				</ReactHookForm>
			</FormProvider>
		</View>
	);
}

export default withRouter(OneTimeInvoiceDetailView);
