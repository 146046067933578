import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';
import { BusinessVerification, VerificationStatus } from '../../api/verificationApi';

import { RoutesUrls, UserRole, VerificationState } from '../../constants';
import { Color } from '../../gfx/constants';
import { media } from '../../services/media';
import isParentVerified from '../../services/is-parent-verified';
import isUserVerified from '../../services/is-user-verified';
import { useStoreActions, useStoreState } from '../../services/store';
import { CloseButton } from '../modal/Modal';

const VerificationParagraph = styled.div`
	padding: 0 20px;
	color: ${Color.WHITE};
	font-family: Secondary;
`;

const VerificationReminderWrapper = styled.div<{ rejectionReminder?: boolean }>`
	display: flex;
	align-items: center;
	background-color: ${(props) => (props.rejectionReminder ? Color.RED_2 : Color.ORANGE)};
	min-height: 56px;
	justify-content: center;
	font-family: Secondary;
	font-size: 16px;
	font-weight: 400;
	line-height: 32px;

	a {
		font-family: Secondary;
		font-weight: bold;
		color: ${Color.WHITE};
		text-decoration: none;
		display: inline-flex;
		svg {
			vertical-align: middle;
		}
		div {
			width: auto;
			margin: auto 5px;
		}
	}

	${media.maxSize800`
		font-size: 14px;
		line-height: 14px;
		min-height: unset;
		padding: 10px 0px;
	`}
`;

function VerificationReminder() {
	const { wallets, activeViewer, verifications } = useStoreState((state) => ({
		wallets: state.wallet.wallets,
		activeViewer: state.viewer.activeViewer,
		verifications: state.verification.verifications,
	}));

	// import from '../../../services/store'
	const { getVerifications, getParentUserVerifications, disableVerificationNotification } = useStoreActions(
		(actions) => ({
			...actions.verification,
		}),
	);

	useEffect(() => {
		const fetchVerifications = async () => {
			if (activeViewer!.parentId) {
				await getParentUserVerifications({
					overrideVerificationsState: true,
				});
			} else {
				await getVerifications({});
			}
		};
		if (!verifications) {
			fetchVerifications();
		}
	}, [getVerifications, verifications, activeViewer, getParentUserVerifications]);

	if (!activeViewer || !verifications) {
		return null;
	}

	const isOwner = activeViewer.role === UserRole.OWNER;

	const shouldShowWalletReminder = () => {
		if (wallets && wallets.itemCount >= 2) {
			return wallets.items.every((item) => item.isDefault === false);
		}

		return false;
	};

	const reminderText = {
		parent: 'Dagpay account access limited',
		user: 'Complete your Dagpay account setup to start accepting Dagcoin payments',
	};

	const expirationReminderText = {
		user: 'Your identity verification is about to expire!',
	};

	const disableRejectNotification = async (verification: BusinessVerification | null) => {
		if (verification) {
			await disableVerificationNotification({ verificationId: verification.id });
		}
	};

	const shouldShowParentReminder = isOwner && !isParentVerified();
	const shouldShowUserReminder = isOwner && !isUserVerified();
	const shouldShowExpirationReminder =
		isOwner &&
		(verifications.identity?.identityVerificationStatus === VerificationStatus.DOCUMENT_EXPIRING_VERIFIED ||
			verifications.identity?.identityVerificationStatus === VerificationStatus.VERIFICATION_EXPIRING_VERIFIED);

	return (
		<>
			{shouldShowParentReminder && (
				<VerificationReminderWrapper>
					<VerificationParagraph className="verificationReminder">{reminderText.parent}</VerificationParagraph>
				</VerificationReminderWrapper>
			)}

			{shouldShowUserReminder && !shouldShowParentReminder && (
				<VerificationReminderWrapper>
					<VerificationParagraph>{reminderText.user}</VerificationParagraph>
				</VerificationReminderWrapper>
			)}
			{shouldShowExpirationReminder && (
				<VerificationReminderWrapper>
					<VerificationParagraph>
						{expirationReminderText.user}{' '}
						<a
							href={`${process.env.REACT_APP_VERIFY_ONCE_URL}${verifications.identity?.transactionId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							Update your verification <ReactSVG src="/files/svg/private/forwardWhiteArrow.svg" />
						</a>
					</VerificationParagraph>
				</VerificationReminderWrapper>
			)}

			{!shouldShowExpirationReminder &&
				verifications &&
				verifications.tiers?.TIER2?.state === VerificationState.REJECTED &&
				verifications.tiers?.TIER3 &&
				JSON.parse(verifications?.tiers?.TIER3?.info as string).shouldShowNotification !== false &&
				isOwner === true && (
					<VerificationReminderWrapper rejectionReminder>
						<VerificationParagraph>
							Tier 2 (business address proof) verification rejected!{' '}
							<Link to={RoutesUrls.VERIFY_BUSINESS_ADDRESS_DOCUMENTS}>
								Review verification <ReactSVG src="/files/svg/forwardArrow.svg" />
							</Link>
						</VerificationParagraph>
						<CloseButton
							closeButtonColor={Color.WHITE}
							onClick={() => disableRejectNotification(verifications.tiers?.TIER2)}
						/>
					</VerificationReminderWrapper>
				)}
			{!shouldShowExpirationReminder &&
				verifications &&
				verifications.tiers?.TIER2?.state !== VerificationState.REJECTED &&
				verifications.tiers?.TIER3?.state === VerificationState.REJECTED &&
				JSON.parse(verifications?.tiers?.TIER3?.info as string).shouldShowNotification !== false &&
				isOwner === true && (
					<VerificationReminderWrapper rejectionReminder>
						<VerificationParagraph>
							Tier 3 (additional business proof) verification rejected!{' '}
							<Link to={RoutesUrls.VERIFY_BUSINESS_PROOF_OF_SHARES_DOCUMENTS}>
								Review verification <ReactSVG src="/files/svg/forwardArrow.svg" />
							</Link>
						</VerificationParagraph>
						<CloseButton
							closeButtonColor={Color.WHITE}
							onClick={() => disableRejectNotification(verifications.tiers?.TIER3)}
						/>
					</VerificationReminderWrapper>
				)}

			{!shouldShowExpirationReminder &&
				verifications &&
				verifications.tiers?.TIER2?.state !== VerificationState.REJECTED &&
				verifications.tiers?.TIER3?.state === VerificationState.REJECTED &&
				JSON.parse(verifications?.tiers?.TIER3?.info as string).shouldShowNotification !== false &&
				isOwner === false && (
					<VerificationReminderWrapper rejectionReminder>
						<VerificationParagraph>Tier 2 (business address proof) verification rejected!</VerificationParagraph>
						<CloseButton
							closeButtonColor={Color.WHITE}
							onClick={() => disableRejectNotification(verifications.tiers?.TIER3)}
						/>
					</VerificationReminderWrapper>
				)}
			{!shouldShowExpirationReminder &&
				verifications.tiers?.TIER2?.state === VerificationState.REJECTED &&
				verifications.tiers?.TIER3 &&
				JSON.parse(verifications?.tiers?.TIER3?.info as string).shouldShowNotification !== false &&
				isOwner === false && (
					<VerificationReminderWrapper rejectionReminder>
						<VerificationParagraph>Tier 3 (additional business proof) verification rejected!</VerificationParagraph>
						<CloseButton
							closeButtonColor={Color.WHITE}
							onClick={() => disableRejectNotification(verifications.tiers?.TIER2)}
						/>
					</VerificationReminderWrapper>
				)}

			{shouldShowWalletReminder() && (
				<VerificationReminderWrapper>
					<VerificationParagraph>
						A default wallet needs to be selected or added in order to continue accepting Point of Sale and email
						invoice payments. <Link to={RoutesUrls.WALLETS}>Choose default wallet now 🡆</Link>
					</VerificationParagraph>
				</VerificationReminderWrapper>
			)}
		</>
	);
}

export default VerificationReminder;
