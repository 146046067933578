import styled from 'styled-components/macro';

export const QrCodeWrapper = styled.div`
	font-size: 12px;
	div:after,
	div:before {
		display: none;
	}
	div {
		margin-bottom: 10px;
	}
`;

export const FormWrapper = styled.div`
	p {
		color: #253a67;
		font-size: 14px;
		line-height: 21px;
	}
	h2 {
		color: #142652;
		font-size: 20px;
		line-height: 22px;
	}
`;

export const Strong = styled.span`
	font-weight: 800;
`;
