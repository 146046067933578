import * as React from 'react';
import ReactSVG from 'react-svg';

import Panel from '../../../../components/panel/Panel';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { RoutesUrls, Scopes } from '../../../../constants';
import { H1, H2 } from '../../../../gfx/globals';

export default () => (
	<View noUnderlineForLinks>
		<H1 left>Merchant tools</H1>
		<View.Description left>
			Integrations and tools for integrating Dagpay to your website and merchant discovery
		</View.Description>

		<Panel.AnchorWrapper
			href={process.env.REACT_APP_MERCHANT_PUBLIC_URL ? process.env.REACT_APP_MERCHANT_PUBLIC_URL : '/'}
			target="_blank"
		>
			<Panel infoPanel>
				<Panel.Icon>
					<ReactSVG src="/files/svg/private/merchant-tools/icon-merchant-finder-glass.svg" />
				</Panel.Icon>
				<Panel.SectionWrapper>
					<H2>Merchant finder</H2>
					<Panel.Text>
						Sign up with your Dagpay account and add your e-commerce or retail business listing to Dag Merchant Finder
						to let your customers discover you’re accepting Dagcoin payments.
					</Panel.Text>
				</Panel.SectionWrapper>
			</Panel>
		</Panel.AnchorWrapper>

		<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
			<Panel.LinkWrapper to={RoutesUrls.ENVIRONMENTS}>
				<Panel infoPanel>
					<Panel.Icon>
						<ReactSVG src="/files/svg/private/merchant-tools/icon-integrations.svg" />
					</Panel.Icon>

					<Panel.SectionWrapper>
						<H2>Integrations </H2>
						<Panel.Text>
							Add, revoke, or modify Dagpay environments for custom API or e-commerce plugin integrations.
						</Panel.Text>
					</Panel.SectionWrapper>
				</Panel>
			</Panel.LinkWrapper>
		</WithPermission>

		<WithPermission
			permissions={[Scopes.POS, Scopes.POS_PAIRING, Scopes.GET_POS_PAIRINGS, Scopes.GENERATE_POS_PAIRING_CODE]}
		>
			<Panel.LinkWrapper to={RoutesUrls.POS_PAIRINGS}>
				<Panel infoPanel>
					<Panel.Icon>
						<ReactSVG src="/files/svg/private/merchant-tools/Dagpay-checkout-app.svg" />
					</Panel.Icon>

					<Panel.SectionWrapper>
						<H2>Dagpay POS app</H2>
						<Panel.Text>
							Download and install the Dagpay POS app on your Android or iOS device and start accepting Dagcoin payments
							easily in your brick-and-mortar business
						</Panel.Text>
					</Panel.SectionWrapper>
				</Panel>
			</Panel.LinkWrapper>
		</WithPermission>
	</View>
);
