import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Fade from 'react-reveal';
import ReactSVG from 'react-svg';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components/macro';
import * as Yup from 'yup';

import { Invoice } from '../../../../../api/invoiceApi';
import Button, { ButtonTypes } from '../../../../../components/button/Button';
import Modal from '../../../../../components/modal/Modal';
import Notification from '../../../../../components/notification/Notification';
import HookBaseField from '../../../../../components/react-hook-form/HookBaseField';
import HookCcField from '../../../../../components/react-hook-form/HookCcField';
import ReactHookForm from '../../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../../components/view/View';
import { H1, FadeInWrapper } from '../../../../../gfx/globals';
import { useStoreActions } from '../../../../../services/store';

const AddOnNode = styled.a<{ toggle: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	font-family: PrimaryMedium;
	width: 85px;

	::after {
		content: '';
		width: 0px;
		right: 0px;
		height: 0px;
		margin-left: 10px;
		position: relative;
		border-style: solid;
		border-width: 7px 5px 0px;
		border-color: rgb(4, 212, 91) transparent transparent;
	}

	${(props) =>
		props.toggle === true &&
		css`
			::after {
				border-width: 0px 5px 7px;
				border-color: transparent transparent rgb(4, 212, 91);
			}
		`}
`;

export const ShowCc = styled.div`
	div {
		height: auto !important;
	}
	div * {
		height: unset !important;
	}
`;

export interface SendConfirmationReceiptFields {
	to: string;
	cc: string;
}

interface Props {
	transaction: Invoice;
	handleCloseModal: () => void;
}

export default function SendConfirmationReceiptModal(props: Props) {
	const { transaction, handleCloseModal } = props;
	const [isCcVisible, setIsCcVisible] = useState(false);
	const [ccEmails, setCcEmails] = useState<string[]>([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { sendInvoiceReceipt } = useStoreActions((actions) => ({
		...actions.invoice,
	}));

	const schema = Yup.object().shape({
		to: Yup.string().email('Invalid email').required('Email is required'),
		cc: Yup.string().email('Invalid email'),
	});

	const defaultValues = {
		to: '',
		cc: '',
	};

	const methods = useForm<SendConfirmationReceiptFields>({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		shouldFocusError: false,
	});

	const handleSendReceipt = async (input: SendConfirmationReceiptFields) => {
		let uniqueCcEmails: string[] = [];

		if (isCcVisible) {
			const ccEmailsCopy = ccEmails.slice();

			// grab email which is not inserted with space or comma
			if (input.cc && input.cc.length !== 0) {
				ccEmailsCopy.push(input.cc);
			}

			// remove duplicates if any
			uniqueCcEmails = [...new Set(ccEmailsCopy)];
			uniqueCcEmails = uniqueCcEmails.filter((ccEmail) => ccEmail !== input.to);
		}

		try {
			setIsLoading(true);
			const response = await sendInvoiceReceipt({
				invoiceId: transaction.id,
				to: input.to,
				cc: uniqueCcEmails,
			});
			setIsLoading(false);

			if (response.success) {
				handleCloseModal();
				toast.success(`Confirmation receipt successfully sent`);

				return;
			}

			if (!response.success && response.validationErrors.length > 0) {
				setErrorMessage('Invalid CC email');

				return;
			}

			if (!response.success) {
				setErrorMessage('Sending invoice receipt failed');

				return;
			}
		} catch (error) {
			handleCloseModal();
			toast.success(`Sending invoice receipt failed, ${error}`);
		}
	};

	return (
		<Modal
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
			isOpen={true}
			onRequestClose={handleCloseModal}
			hasCloseButton
		>
			<FormProvider {...methods}>
				<ReactHookForm onSubmit={methods.handleSubmit(handleSendReceipt)}>
					<H1>Send confirmation receipt No. {transaction && transaction.no}</H1>
					<Notification>Insert email address(es) to send a confirmation receipt</Notification>
					<HookBaseField
						label="To"
						name="to"
						placeholder="Email address"
						addonNode={
							<AddOnNode toggle={isCcVisible} onClick={() => setIsCcVisible(!isCcVisible)}>
								{isCcVisible ? (
									<>
										Hide Cc <ReactSVG src="/files/svg/private/EyeClose.svg" />
									</>
								) : (
									<>
										Add Cc <ReactSVG src="/files/svg/private/EyeOpen.svg" />
									</>
								)}
							</AddOnNode>
						}
						addonSize={120}
						addonHasToggle={true}
						hookBaseBackground="white"
					/>
					{isCcVisible && (
						<ShowCc>
							<Fade collapse when={isCcVisible}>
								<FadeInWrapper>
									<HookCcField label="Cc recipients" name="cc" setCcEmails={setCcEmails} ccEmails={ccEmails} />
									<View.Description left>Add Cc recipients separated by space or comma key</View.Description>
								</FadeInWrapper>
							</Fade>
						</ShowCc>
					)}
					{errorMessage.length > 0 && <View.Error center>{errorMessage}</View.Error>}
					<Modal.Buttons>
						<Button.Secondary white onClick={handleCloseModal}>
							Cancel
						</Button.Secondary>
						<Button type={ButtonTypes.SUBMIT} isDisabled={isLoading}>
							Send receipt
						</Button>
					</Modal.Buttons>
				</ReactHookForm>
			</FormProvider>
		</Modal>
	);
}
