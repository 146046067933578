import React, { useState } from 'react';
import { ContentTitle, SecondaryViewWrapper } from '../ExternalInvoiceViewStyle';
import { useStoreActions } from '../../../../services/store';
import { ExternalInvoiceCurrency } from '../../../../api/externalInvoiceApi';
import { Color } from '../../../../gfx/constants';
import ReactSVG from 'react-svg';
import { withRouter } from 'react-router';
import { RouteProps } from '../../../../typings';
import * as qs from 'query-string';
import { UpdateContentName } from '../ExternalInvoiceView';
import { ClipLoader } from 'react-spinners';
import { Box, Container, Icon, Label, SelectCurrencyWrapper } from './ExternalInvoiceSelectCurrencyViewStyle';
import ExternalInvoiceHeader from '../../../../components/ext-invoice-header/ExternalInvoiceHeader';
import { FaqLinks } from '../../../../constants';
import Button from '../../../../components/button/Button';
import { toast } from 'react-toastify';

interface SelectableCrypto {
	label: string;
	iconSrc: string;
	key: ExternalInvoiceCurrency;
}

const cryptocurrencies: SelectableCrypto[] = [
	{
		label: 'Bitcoin',
		iconSrc: '/files/svg/ext-invoice/Bitcoin.svg',
		key: ExternalInvoiceCurrency.BTC,
	},
	{
		label: 'Bitcoin Cash',
		iconSrc: '/files/svg/ext-invoice/BitcoinCash.svg',
		key: ExternalInvoiceCurrency.BCH,
	},
	{
		label: 'Ethereum',
		iconSrc: '/files/svg/ext-invoice/Ethereum.svg',
		key: ExternalInvoiceCurrency.ETH,
	},
	{
		label: 'Litecoin',
		iconSrc: '/files/svg/ext-invoice/Litecoin.svg',
		key: ExternalInvoiceCurrency.LTC,
	},
	{
		label: 'USD Coin (USDC)',
		iconSrc: '/files/svg/ext-invoice/UsdCoin.svg',
		key: ExternalInvoiceCurrency.USDC,
	},
	{
		label: 'Tether (USDT)',
		iconSrc: '/files/svg/ext-invoice/Tether.svg',
		key: ExternalInvoiceCurrency.USDT,
	},
];

export interface SelectCryptoCurrencyProps extends RouteProps {
	extInvoiceId: string | null;
	extInvoiceCustomerName: string | null;
	extInvoiceCustomerEmail: string | null;
	extInvoicePaymentCurrency: ExternalInvoiceCurrency | null;
	showHeader?: boolean;
}

const ExternalInvoiceSelectCurrencyView = (props: SelectCryptoCurrencyProps) => {
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const { extInvoiceId, extInvoiceCustomerEmail, extInvoiceCustomerName, extInvoicePaymentCurrency, showHeader } =
		props;

	const { updateExternalInvoice } = useStoreActions((actions) => ({
		...actions.externalInvoice,
	}));

	// parse search string
	const { redirectUrl }: { redirectUrl?: string; update?: UpdateContentName } = qs.parse(props.location.search);

	const getCleanedUrlPath = () => {
		// combine "clean" search string
		const searchString = redirectUrl ? `?${qs.stringify({ redirectUrl })}` : '';

		// join qs string parts
		return `${props.location.pathname}${searchString}`;
	};

	const handleCryptoSelect = async (crypto: SelectableCrypto, index: number) => {
		// not loaded
		if (!extInvoiceId) {
			return;
		}

		// same currency chosen
		if (crypto.key === extInvoicePaymentCurrency) {
			// for loading spinner
			setSelectedIndex(index);

			// back to qr view
			props.history.goBack();

			return;
		}

		// set for loading and disabling others
		setSelectedIndex(index);

		// attempt to update external invoice info
		const response = await updateExternalInvoice({
			externalInvoiceId: extInvoiceId,
			customerEmail: extInvoiceCustomerEmail,
			customerName: extInvoiceCustomerName,
			paymentCurrency: ExternalInvoiceCurrency[crypto.key],
		});

		// handle failure response
		if (!response.success) {
			toast.warn('Could not update invoice info');

			// clear loader and enable
			setSelectedIndex(null);

			return;
		}

		// remove update search param to get back to pending content
		const cleanedUrlPath = getCleanedUrlPath();

		// was clean already
		if (!cleanedUrlPath) {
			// clear loader and enable
			setSelectedIndex(null);

			return;
		}

		// add cleaned url to history stack
		props.history.push(cleanedUrlPath);
	};

	const renderClickable = (crypto: SelectableCrypto, index: number) => {
		const isInitiallySelected = !!extInvoicePaymentCurrency && !!crypto.key && crypto.key === extInvoicePaymentCurrency;
		const isSelected = typeof selectedIndex === 'number' && selectedIndex === index;
		const somethingSelected = typeof selectedIndex === 'number';
		const allowClick = !isSelected && !somethingSelected;

		return (
			<Box
				initiallySelected={isInitiallySelected}
				disabled={somethingSelected}
				selected={isSelected}
				key={`cur-${index}`}
				onClick={() => (allowClick ? handleCryptoSelect(crypto, index) : null)}
			>
				<Icon>
					<ReactSVG src={crypto.iconSrc} />
				</Icon>
				<Label>{crypto.label}</Label>
				{isSelected && <ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={19} />}
			</Box>
		);
	};

	return (
		<SecondaryViewWrapper disableStyles={!showHeader}>
			{showHeader && (
				<ExternalInvoiceHeader disableLogoClick hasWhiteBackground helpLink={FaqLinks.externalInvoiceHelp} />
			)}
			<SelectCurrencyWrapper>
				<ContentTitle>Select cryptocurrency for payment</ContentTitle>
				<Container>{cryptocurrencies.map((crypto, index) => renderClickable(crypto, index))}</Container>
			</SelectCurrencyWrapper>
			<div>
				<Button
					onClick={() => {
						// get clean url path
						const clean = getCleanedUrlPath();

						props.history.push(
							`${clean}&${qs.stringify({
								update: UpdateContentName.DETAILS,
							})}`, // TODO: what if search has no content: ;
						);
					}}
					centered
					transparent
					noBorder
					underLined
					color={Color.GRAY_12}
				>
					Back
				</Button>
			</div>
		</SecondaryViewWrapper>
	);
};

export default withRouter(ExternalInvoiceSelectCurrencyView);
