import React from 'react';
import styled from 'styled-components/macro';
import Logo from '../logo/Logo';

export const SidebarHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

interface Props {
	helpLink?: string;
	logoClickLink?: string;
	hasWhiteBackground?: boolean;
	disableLogoClick?: boolean;
}

const ExternalInvoiceHeader = (props: Props) => {
	const { logoClickLink, hasWhiteBackground: hasWhiteBackgorund, disableLogoClick } = props;
	return (
		<SidebarHeader>
			<Logo to={logoClickLink} hasWhiteBackground={hasWhiteBackgorund} notClickable={disableLogoClick} />
		</SidebarHeader>
	);
};

export default ExternalInvoiceHeader;
