import styled from 'styled-components/macro';
import { Color } from '../../../../gfx/constants';

export const ContentWrap = styled.div<{ color?: Color }>`
	margin: auto;

	strong {
		color: ${({ color }) =>
			color === 'ORANGE'
				? Color.ORANGE
				: color === 'GREEN_3'
				? Color.GREEN_3
				: color === 'RED_ERROR'
				? Color.RED_3
				: Color.ORANGE};
	}
`;

export const StatusSvgWrap = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 15px;
	margin-top: 10px;
`;

export const LoaderWrap = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

export const RedirectButtonWrapper = styled.div`
	width: 100%;
	text-align: center;
	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;

	@media (max-width: 768px) {
		position: relative;
		bottom: 0px;
		margin-bottom: 20px;
	}
`;

export const RedirectButton = styled.button`
	border-radius: 0px 25px 0px 25px;
	height: 48px;
	background: rgba(0, 187, 104, 1);
	color: ${Color.WHITE};
	font-size: 14px;
	line-height: 48px;
	width: 230px;
	font-weight: 600;
	border: none;
	box-shadow: 0px 9px 12px 0px rgb(0 187 104 / 30%);
	margin-top: 25px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 15px auto 0px;
	cursor: pointer;

	svg {
		margin-right: 8px;
		margin-bottom: -2px;
	}
`;
