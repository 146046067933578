import React from 'react';
import { css, StyledComponentProps } from 'styled-components';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

interface TableProps {
	children: JSX.Element[];
}

interface TableLastHiddenTrProps {
	disabled?: boolean;
}

interface NoLabelDataWrapperProps {
	justifyOption: 'flex-start' | 'center' | 'flex-end';
	wordBrake?: 'normal' | 'break-all' | 'keep-all';
}

interface FixedWidthTdProps {
	cellWidth: number;
	noWhiteSpaceWrap?: boolean;
	noBeforeElement?: boolean;
	noPadding?: boolean;
	hideOnSmallerScreen?: boolean;
}

interface DataWrapperProps {
	wordBrake?: 'normal' | 'break-all' | 'keep-all';
}

const LatestTransactions = styled.table`
	thead tr th:first-child {
		padding-left: 20px;
	}

	tbody tr td:first-child {
		padding-left: 20px;
	}

	border-collapse: separate;
	border-spacing: 0 2px;
	font-size: 14px;
	margin: 0;
	padding: 0;
	text-align: left;
	width: 100%;

	a {
		color: #707070;
		font-family: PrimaryMedium;
		text-decoration: none;
	}

	th {
		padding: 10px 8px;
		text-align: left;
		color: ${Color.GRAY_8};
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
	}

	${media.maxSize800`
		border: 0;
		border-spacing: 0;
	`}
`;

const WalletTd = styled.td`
	&:after {
		${media.maxSize800`
			margin-right: 5px;
			text-align: left;
		`}
	}
`;

const Tag = styled.span`
	margin-left: 10px;
	top: 0;
	padding: 5px 10px;
	background: ${Color.GRAY_3};
	border-radius: 4px;
	color: ${Color.GRAY_10};
	font-size: 14px;
	font-family: Primary;
	text-transform: capitalize;
`;

const StyledTable = styled.table`
	border-collapse: separate;
	border-spacing: 0 2px;
	font-size: 14px;
	margin: 0;
	padding: 0;
	text-align: left;
	width: 100%;

	a {
		color: #707070;
		font-family: PrimaryMedium;
		text-decoration: none;
	}

	th {
		padding: 10px 8px;
		text-align: left;
		color: ${Color.GRAY_8};
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
	}

	td {
		${Tag} {
			${media.maxSize800`
				margin-right: auto;
				border: 3 px solid red;
			`}
		}
	}

	${WalletTd} {
		span {
			word-break: break-all;
			word-wrap: anywhere;
		}
	}

	${media.maxSize800`
		border: 0;

		border-spacing: 0;
	`}
`;

const UserNameTd = styled.td`
	${media.maxSize800`
		display: flex;
		flex-direction: column-reverse !important;
		align-items: flex-start !important;
		padding: 20px 20px 11px 20px !important;

		:after {
			content: attr(data-label);
			color: #576483;
			float: left;
			font-family: PrimaryMedium;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 14px;
			margin-bottom: 14px;
		}

	`}
`;

const UserName = styled.span`
	font-family: PrimaryMedium;
	font-size: 14px;
	color: ${Color.GRAY_11};
	padding: 1px 0;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 550px;

	@media (max-width: 1300px) {
		max-width: 280px;
	}
	@media (max-width: 900px) {
		max-width: 180px;
	}
`;

const Email = styled.span`
	font-family: Primary;
	font-size: 12px;
	color: ${Color.GRAY_8};
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 550px;

	@media (max-width: 1300px) {
		max-width: 280px;
	}
	@media (max-width: 900px) {
		max-width: 180px;
	}
`;

const Arrow = styled.div<{ opened: boolean }>`
	position: relative;
	height: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0;
	cursor: pointer;
	padding: 0;
	user-select: none;

	/* svg arrow */
	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& > div div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& > div div svg {
		height: 14px;
		width: 14px;
		transform: ${(props) => (props.opened ? 'rotate(90deg)' : 'rotate(270deg)')};

		path {
			fill: ${Color.GRAY_6};
		}

		${media.maxSize800`
			position: absolute;
			top: 11px;
			right: 14px;
		`}
	}
	/* svg arrow */

	${media.maxSize800`
		justify-content: flex-start;
		width: 124px;
		height: 36px;
		padding-left: 16px;
		border: 1px solid ${Color.GRAY_4};
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		color: #576483;
		font-size: 16px;
		line-height: 19px;
	`}
`;

const ClickableTd = styled.td<{ cellWidth?: number; visibleOnSmallerScreen?: boolean }>`
	cursor: pointer;
	position: relative;
	width: 94px;
	text-align: center;

	${media.maxSize800`
		width: 100%;

	${(props: any) =>
		!props.visibleOnSmallerScreen &&
		css`
			display: none !important;
		`}
		`}

	${(props) =>
		props.cellWidth &&
		css`
			width: ${props.cellWidth}%;
		`}
`;

const FixedWidthTd = styled.td<FixedWidthTdProps>`
	${(props) =>
		props.noWhiteSpaceWrap &&
		css`
			white-space: nowrap;
		`}

	${(props) =>
		props.cellWidth &&
		css`
			width: ${props.cellWidth}%;
		`}

	${(props) =>
		props.noBeforeElement &&
		css`
			::before {
				display: none;
			}
		`}

		${(props) =>
		props.noPadding &&
		css`
			padding: 0 !important;
		`}

	
		${(props) =>
		!!props.hideOnSmallerScreen &&
		css`
			${media.maxSize800`
				display: none !important;
			`}
		`}

	${media.maxSize800`
		font-size: 0.8em;
		width: auto;
		min-height: 42px;
	`}
`;

const TransparentTd = styled.td`
	div:first-child {
		background-color: transparent !important;
	}
`;

const DataWrapper = styled.div<DataWrapperProps>`
	${(props) =>
		props.wordBrake &&
		css`
			word-break: ${props.wordBrake};
		`}

	${media.maxSize800`
		height: 100%;
		width: 100%;
		padding: 11px 14px;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		line-height: 16px;
		color: ${Color.GRAY_14};
		
		:after {
			content: attr(data-label);
			color: #576483;
			float: left;
			font-family: PrimaryMedium;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 14px;
			white-space: nowrap;
			margin-right: 10px;
			margin-bottom: 0px !important;
		}
	`}

	${media.maxMobile`
		flex-wrap: wrap;
		align-items: flex-start;

		:after{
			margin-bottom: 10px;
		}

		${Tag}{
			margin: 0 0 10px 0;
		}
	`}
`;

const NoLabelDataWrapper = styled.div<NoLabelDataWrapperProps>`
	${(props) =>
		props.wordBrake &&
		css`
			word-break: ${props.wordBrake};
		`}

	${media.maxSize800`
		height: 100%;
		width: 100%;
		padding: 11px 14px;
		display: flex;
		flex-direction: row;
		justify-content: ${(props: NoLabelDataWrapperProps) => props.justifyOption};
		align-items: center;
		font-size: 14px;
		line-height: 16px;
		color: ${Color.GRAY_14};
	`}
`;

const SeeMoreTd = styled.td<{ cellWidth?: number; hideOnSmallerScreen?: boolean }>`
	padding: 0 !important;
	width: 5%;
	max-width: 5%;

	:before {
		display: none;
	}

	${media.maxSize800`
			max-width: 100%;
			font-size: 0.8em;
			width: auto;
			padding: 32px 20px 12px 20px !important;

			:after{
				/* separation line */
				position: absolute;
				top: 16px;
				content: '';
				width: calc(100% - 40px);
				border-top: 1px solid ${Color.GRAY_4};
				/* separation line */
			}
		`}
	${(props) =>
		!!props.hideOnSmallerScreen &&
		css`
			${media.maxSize800`
			padding: 0 !important;
				padding-bottom: 20px !important;

				div{
					display: none;
				}

				:after{
					display: none;
				}
			`}
		`}
`;

const TrPrimary = styled.tr`
	&.opened td {
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}
	td {
		background: white;
		max-height: 61px;
		border: 1px solid ${Color.GRAY_5};
		border-style: solid none;
		height: 48px;
		padding: 5px 8px;
		word-break: normal;
		color: ${Color.BLUE_9};
		font-size: 14px;
		line-height: 16px;
		position: relative;
	}

		&:last-child {
			&::before {
				display: none;
			}
		}
	}

	td:first-child {
		padding-left: 15px;
		border-left-style: solid;
		border-radius: 8px 0 0 8px;
	}

	td:last-child {
		border-right-style: solid;
		border-radius: 0 8px 8px 0;
	}
	
	td:last-child:before {
		display: none
	}

	td:before {
		content: '';
		position: absolute;
		right: 0;
		top: 0px;
		bottom: 0px;
		margin: auto;
		width: 1px;
		height: 24px;
		background: ${Color.GRAY_3};
	}

	${media.maxSize800`
		td:first-child {
			padding-left: 20px;
		}

		td {
			padding: 0 20px;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-between;
			font-size: 0.8em;
			border-style: none solid;
			max-height: none;
			height: auto;
		}

		td:first-child {
			border-top-style: solid;
			border-radius: 4px 4px 0 0;
			padding-top: 14px;
			margin-top: 4px;
		}

		td:last-child {
			border-bottom-style: solid;
			border-radius: 0 0 4px 4px;
			padding-bottom: 20px;
		}

		td:before {
			display: none;
		}

		${FixedWidthTd} {
			:nth-child(odd) {
			
				${DataWrapper} {
					background: ${Color.GRAY_1};
					border-radius: 4px;
					align-items: center;
				}
								
				${NoLabelDataWrapper} {
					background: ${Color.GRAY_1};
					border-radius: 4px;
				}
			}

			:nth-child(4) {
				
				${DataWrapper} {
					padding: 4px 14px;
				}
			}
		}
		${TransparentTd} {
			:nth-child(odd) {
			
				${DataWrapper} {
					background: ${Color.GRAY_1};
					border-radius: 4px;
					align-items: center;
				}
								
				${NoLabelDataWrapper} {
					background: ${Color.GRAY_1};
					border-radius: 4px;
				}
			}

			:nth-child(4) {
				
				${DataWrapper} {
					padding: 4px 14px;
				}
			}
		}
			 
		.opened {
			border-radius: 0 !important;
		}
	`}
`;

const LastHiddenTr = styled.tr<TableLastHiddenTrProps>`
	border-bottom: 3px solid rgba(220, 221, 224, 0.5);

	${(props) =>
		props.disabled &&
		css`
			border-bottom: none;
		`};
`;

const TrSeparator = styled.tr`
	height: 1px !important;
`;

const TrSeparatorBorder = styled.div`
	height: 1px;
	background-color: rgba(220, 221, 224, 0.5);
	margin: 0 auto;
`;

const ConditionalBeforeTd = styled.td<{ noBeforeElement?: boolean }>`
	${(props) =>
		props.noBeforeElement &&
		css`
			::before {
				display: none;
			}
		`}
`;

const ClickableTdWrapper = styled.div`
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 12px;
		width: 1px;
		height: 44px;
		background: rgba(220, 221, 224, 0.5);
	}
`;

const Thead = styled.thead<{ hiddenOnSmallerScreen?: boolean }>`

	th:first-child {
		padding-left: 15px;
	}
	color: ${Color.BLUE_10};

	${(props) =>
		props.hiddenOnSmallerScreen &&
		css`
			${media.maxSize800`
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		`}
		`}
`;

const MobileThead = styled.thead`
	color: ${Color.BLUE_10};
	font-family: PrimaryMedium;

	tr {
		${media.maxSize800`
			display: flex;
			justify-content: space-between;
			border-bottom: none;
		`}
	}
`;

const WhiteThead = styled.thead`
	background: #f0f0f0;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	font-weight: 600;

	tr {
		${media.maxSize800`
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		`}
	}
`;

const HiddenDetails = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 3px;
	padding-top: 3px;

	span {
		color: ${Color.BLUE_10};
		display: block;
		font-size: 15px;
		font-family: PrimaryMedium;
		line-height: 18px;
		padding-bottom: 5px;
	}
`;

const SmallText = styled.div`
	color: #2d2d2d;
	font-family: Secondary;
	font-size: 13px;
	line-height: 19px;
	word-break: break-all;
`;

const HiddenSettings = styled.div`
	position: absolute;
	background: white;
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;
	padding: 10px 10px 10px 10px;
	z-index: 100;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
	width: 136px;
	right: 5px;
	top: 40px;
	button {
		background: none !important;
		border: none;
		padding: 0 !important;
	}
	${media.maxSize800`
		right: 20px;
		top: 40px;
	`}

	${media.maxMobile`
		left: 40px;
		top: 50px;
	`}
`;

const HiddenSettingsItem = styled.div<{ isDelete?: boolean; isDisable?: boolean; isEnable?: boolean }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 13px;
	font-weight: 500;
	line-height: 30px;

	div {
		width: 18px;
		height: 18px;
		min-width: 22px;
		text-align: center;
		margin: 0 auto;

		svg {
			width: 18px;
			float: left;
		}
		path,
		circle {
			fill: #cfd0df;
		}
		circle + path {
			fill: white;
		}
	}

	span {
		color: ${Color.GRAY_MENULINK};
		width: 75%;
	}

	&:hover {
		g,
		path {
			fill: ${Color.ORANGE};
		}

		${(props) =>
			props.isEnable &&
			css`
				g,
				path {
					fill: ${Color.GREEN_3};
				}
			`}

		${(props) =>
			props.isDisable &&
			css`
				circle,
				path {
					fill: ${Color.RED_2};
				}

				circle + path {
					fill: white;
				}
			`}

		${(props) =>
			props.isDelete &&
			css`
				path {
					fill: ${Color.RED_2};
				}
			`}

		span {
			font-family: PrimaryMedium;
		}
	}
`;

const SettingsIcon = styled.div`
	cursor: pointer;
	text-align: center;
	width: 25px;
	margin: 0 auto;

	svg {
		margin-top: 2px;
	}

	${media.maxSize800`
		margin: unset;
	`}
`;

const TdInsideTitle = styled.span`
	padding-bottom: 4px;
	font-family: PrimaryMedium;
`;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	justify-content: center;

	svg {
		width: 20px;
		fill: #A7B2CC !important;
		float: unset !important;
	}

	svg path {
		fill: #A7B2CC;
	}

	* {
		text-align: center;
	}

	a:first-child {
		width: 100%;
	}
`;

const Table = (props: StyledComponentProps<'table', TableProps, TableProps, never>) => <StyledTable {...props} />;

Table.TdInsideTitle = TdInsideTitle;
Table.SettingsIcon = SettingsIcon;
Table.HiddenSettingsItem = HiddenSettingsItem;
Table.HiddenSettings = HiddenSettings;
Table.DataWrapper = DataWrapper;
Table.NoLabelDataWrapper = NoLabelDataWrapper;
Table.TrPrimary = TrPrimary;
Table.Arrow = Arrow;
Table.Tag = Tag;
Table.LastHiddenTr = LastHiddenTr;
Table.TrSeparator = TrSeparator;
Table.TrSeparatorBorder = TrSeparatorBorder;
Table.ClickableTd = ClickableTd;
Table.ClickableTdWrapper = ClickableTdWrapper;
Table.Thead = Thead;
Table.MobileThead = MobileThead;
Table.WhiteThead = WhiteThead;
Table.HiddenDetails = HiddenDetails;
Table.SmallText = SmallText;
Table.WalletTd = WalletTd;
Table.FixedWidthTd = FixedWidthTd;
Table.TransparentTd = TransparentTd;
Table.SeeMoreTd = SeeMoreTd;
Table.UserNameTd = UserNameTd;
Table.UserName = UserName;
Table.Email = Email;
Table.ConditionalBeforeTd = ConditionalBeforeTd;
Table.LatestTransactions = LatestTransactions;
Table.ActionButtons = ActionButtons;

export default Table;
