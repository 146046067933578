import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import * as Yup from 'yup';

import { RecurringInvoice } from '../../../../api/invoiceApi';
import Button, { ButtonTypes } from '../../../../components/button/Button';
import CalculatedInvoiceTotal from '../../../../components/calculated-invoice-total/CalculatedInvoiceTotal';
import { FieldOptionProps } from '../../../../components/field/Field';
import Form from '../../../../components/form/Form';
import Loader from '../../../../components/loader/Loader';
import PanelNew, { PanelSectionType } from '../../../../components/panel-new/PanelNew';
import PreviousLink from '../../../../components/previous-link/PreviousLink';
import { AddOnNode } from '../../../../components/react-hook-form/hook-addon-fields/HookAddonFieldStyles';
import HookBaseField from '../../../../components/react-hook-form/HookBaseField';
import HookCcField from '../../../../components/react-hook-form/HookCcField';
import HookDatePicker from '../../../../components/react-hook-form/HookDatePicker';
import HookTimePicker from '../../../../components/react-hook-form/HookTimePicker';
import ReactHookForm, {
	HookFormColumn,
	HookFormCurrencySection,
	HookFormSection,
	NotificationBelowInput,
} from '../../../../components/react-hook-form/ReactHookFormStyle';
import View from '../../../../components/view/View';
import Fade from 'react-reveal';
import {
	Currency,
	EMAIL_INVOICE_VALID_MAX_SECONDS,
	EMAIL_INVOICE_VALID_MIN_SECONDS,
	INVOICE_DELAY_MINUTES,
	MAX_PAYMENT_DUE_AMOUNT,
	RoutesUrls,
	UserRole,
} from '../../../../constants';
import { H1, H2, HorizontalLine } from '../../../../gfx/globals';
import { getBaseCurrencyOptions } from '../../../../services/get-base-currency-options';

import { getWalletOptions } from '../../../../services/get-wallet-options';
import { getServerValidationErrors } from '../../../../services/input-error-utils';
import { media } from '../../../../services/media';
import { useStoreActions, useStoreState } from '../../../../services/store';
import { RouteProps } from '../../../../typings';
import HookAddonSelectField from '../../../../components/react-hook-form/hook-addon-fields/HookAddonSelectField';
import { Color } from '../../../../gfx/constants';
import HookSelectField from '../../../../components/react-hook-form/hook-select-field/HookSelectField';
import { DealTotalText, EscrowTotalWrapper, TotalItem } from '../escrow/EscrowNewDealViewStyle';
import HookRadioButtonGroup from '../../../../components/react-hook-form-vertical-radiogroup/HookVerticalRadioGroup';
import HookTextAreaField from '../../../../components/react-hook-form/HookTextAreaField';

import { BusinessTierLimitsResponse } from '../../../../api/authApi';
import { Rates } from '../../../../api/ratesApi';
import { LimitNotificationText } from '../pos-checkout/PosCheckoutViewStyle';
import ReactSVG from 'react-svg';

dayjs.extend(duration);

export const FieldsWrapper = styled.div`
	display: flex;

	${media.maxDesktop`
		flex-direction: column;
	`}
`;

interface RouteParams {
	id?: string;
}

export interface RecurringInvoiceFields {
	to: string;
	subject: string;
	tax: number | null;
	paymentId: string;
	currency: FieldOptionProps;
	walletId: FieldOptionProps | null;
	cc: string;
	description: string;
	currencyAmount: number | null;
	startDate: Date;
	startTime: Date | null;
	endDate: Date | null;
	sendIntervalSeconds: string | null;
	paymentDue: number | null;
}

const yearDurationInSeconds = dayjs.duration(dayjs().endOf('year').diff(dayjs().startOf('year'))).asSeconds();
const monthsInYear = 12;
const quartersInYear = 4;

const monthInSeconds = (yearDurationInSeconds / monthsInYear).toFixed(0);
const quarterInSeconds = (yearDurationInSeconds / quartersInYear).toFixed(0);
const weekInSeconds = (604800).toFixed(0);
const twoWeeksInSeconds = (1209600).toFixed(0);

export const recurringInvoiceSendIntervals = [
	{ label: 'Week', value: weekInSeconds },
	{ label: '2 weeks', value: twoWeeksInSeconds },
	{ label: 'Month', value: monthInSeconds },
	{ label: 'Quarter', value: quarterInSeconds },
];

function RecurringInvoiceDetailView(props: RouteProps<RouteParams>) {
	const [errorMessage, setErrorMessage] = useState('');
	const [isCcVisible, setIsCcVisible] = useState(false);
	const [ccEmails, setCcEmails] = useState<string[]>([]);
	const [isActionLoading, setIsActionLoading] = useState(false);
	const [walletOptions, setWalletOptions] = useState<FieldOptionProps[] | null>(null);
	const [viewerLimits, setViewerLimits] = useState<BusinessTierLimitsResponse | null>(null);

	const { wallets, rates, activeViewer } = useStoreState((state) => ({
		rates: state.rates.rates,
		activeViewer: state.viewer.activeViewer,
		wallets: state.wallet.wallets,
	}));

	const { getRates, createRecurringInvoice, getWallets, getViewerBusinessLimits } = useStoreActions((actions) => ({
		...actions.rates,
		...actions.invoice,
		...actions.wallet,
		...actions.viewer,
	}));

	useEffect(() => {
		const fetchWallets = async () => {
			await getWallets({});
		};
		const fetchViewerLimits = async () => {
			if (!viewerLimits) {
				setViewerLimits((await getViewerBusinessLimits()).payload);
			}
		};
		if (!wallets) {
			fetchWallets();
		}
		if (!viewerLimits) {
			fetchViewerLimits();
		}
		if (wallets) {
			setWalletOptions(getWalletOptions(wallets));
		}
	}, [wallets, getWallets, getViewerBusinessLimits, viewerLimits]);

	useEffect(() => {
		const fetchRates = async () => {
			await getRates();
		};
		fetchRates();
	}, [getRates]);

	const userRole = activeViewer ? activeViewer.role : null;
	const isWalletSelectVisible =
		userRole &&
		(userRole === UserRole.OWNER ||
			userRole === UserRole.ADMIN ||
			userRole === UserRole.ADVANCED_TEAM_MEMBER ||
			userRole === UserRole.TEAM_MEMBER);

	const saveRecurringEmailInvoice = async (input: RecurringInvoiceFields) => {
		const { paymentDue, ...newInput } = input;
		let duration = null;
		let validForSeconds = EMAIL_INVOICE_VALID_MAX_SECONDS;
		let uniqueCcEmails: string[] = [];
		let time = input.startTime ? input.startTime.getTime() : 0;
		let startDateWithTime = dayjs(input.startDate).startOf('day').add(time, 'millisecond');
		if (input.startDate && dayjs(input.startDate.toISOString()).startOf('day').add(1, 'day').isBefore(dayjs())) {
			return methods.setError('startDate', { message: 'Send date is past current date. Please choose new date.' });
		}

		if (startDateWithTime && startDateWithTime.isBefore(dayjs())) {
			return methods.setError('startTime', {
				message: 'Send time is past current date & time. Please choose new date.',
			});
		}

		if (!newInput.sendIntervalSeconds) {
			return methods.setError('sendIntervalSeconds', { message: 'Please choose the interval' });
		}

		if (!newInput.walletId) {
			return methods.setError('walletId', { message: 'Please choose the wallet' });
		}

		if (input.paymentDue) {
			duration = dayjs.duration(dayjs().add(input.paymentDue, 'days').diff(dayjs()));

			validForSeconds =
				duration.asSeconds() < EMAIL_INVOICE_VALID_MIN_SECONDS ? EMAIL_INVOICE_VALID_MIN_SECONDS : duration.asSeconds();
		}

		const remainder = INVOICE_DELAY_MINUTES - (dayjs().minute() % INVOICE_DELAY_MINUTES);
		const roundedCurrentDate = dayjs().add(remainder, 'minutes');
		const fixedStartDate =
			startDateWithTime && startDateWithTime.toDate() > dayjs().toDate() ? roundedCurrentDate : startDateWithTime;
		const startDate = fixedStartDate && fixedStartDate.toISOString();
		const endDate = input.endDate && input.endDate.toISOString();

		if (isCcVisible) {
			const ccEmailsCopy = ccEmails.slice();

			// grab email which is not inserted with space or comma
			if (input.cc && input.cc.length !== 0) {
				ccEmailsCopy.push(input.cc);
			}

			// remove duplicates if any
			uniqueCcEmails = [...new Set(ccEmailsCopy)];
			uniqueCcEmails = uniqueCcEmails.filter((ccEmail) => ccEmail !== input.to);
		}

		setIsActionLoading(true);
		const result = await createRecurringInvoice({
			...newInput,
			currency: Currency[input.currency.value as keyof typeof Currency],
			currencyAmount: newInput.currencyAmount ? newInput.currencyAmount : 0,
			tax: newInput.tax ? newInput.tax : 0,
			walletId: newInput.walletId
				? newInput.walletId.value.toString()
				: (walletOptions && walletOptions.filter((w) => w.isDefault)[0].value.toString()) || '',
			sendIntervalSeconds: parseInt(newInput.sendIntervalSeconds, 10),
			validForSeconds,
			cc: uniqueCcEmails,
			startDate: startDate || '',
			endDate: endDate || '',
		});
		setIsActionLoading(false);

		if (result.success) {
			props.history.push(RoutesUrls.RECURRING_INVOICES);
			toast.success(`Recurring invoice is created successfully.`);

			return;
		}

		if (result.error) {
			const serverValidationErrors = getServerValidationErrors<RecurringInvoice>(result);
			let errorString = '';
			for (var key in serverValidationErrors.errors) {
				errorString += `, ${key}: ${serverValidationErrors.errors[key]}`;
			}
			setErrorMessage(`${serverValidationErrors.errorMessage} - ${errorString}`);
			toast.error(`Failed to create recurring invoice.`);
			return;
		}
	};

	const defaultValues = {
		currency: {
			label: activeViewer ? activeViewer.baseCurrency : 'DAG',
			value: activeViewer ? activeViewer.baseCurrency : 'DAG',
		},
		to: '',
		cc: '',
		subject: '',
		dueDate: new Date(),
		tax: null,
		paymentId: '',
		walletId: wallets ? getWalletOptions(wallets).filter((w) => w.isDefault)[0] : null,
		description: '',
		currencyAmount: null,
		startTime:
			dayjs().get('minute') >= 30
				? dayjs().minute(0).second(0).add(1, 'hour').toDate()
				: dayjs().minute(0).second(0).add(30, 'minute').toDate(),
		startDate: dayjs().toDate(),
		endDate: null,
		sendIntervalSeconds: '604800',
		paymentDue: 30,
	};

	const recurringInvoiceValidationSchema = Yup.object<RecurringInvoiceFields>().shape({
		to: Yup.string()
			.email('Invalid email')
			.max(255, 'The maximum length is 255 characters')
			.required('Email is required'),
		subject: Yup.string()
			.max(100, 'The maximum length is 100 characters')
			.trim()
			.required('Subject cannot be empty or contain only spaces'),
		currencyAmount: Yup.number()
			.typeError('Must be a number with a dot as a decimal separator')
			.min(0.000001, 'Minimum amount of 0.000001 is required')
			.max(10000000, 'Maximum amount of 10000000 is allowed')
			.required('Amount is required')
			.test({
				name: 'validate-limit',
				test: function (currencyAmountValue) {
					var limitAmount = userVolumeLimits;
					return currencyAmountValue >= limitAmount && limitAmount < 0
						? this.createError({
								message: `Remaining monthly volume until limit ${limitAmount.toFixed(2)}`,
								path: 'currencyAmount',
						  })
						: true;
				},
			}),
		startDate: Yup.date().typeError('Please choose a valid date').required('Date to send is required'),
		startTime: Yup.date()
			.typeError('Please choose a valid date')
			.required('Time to send is required')
			.test('isValidTime', 'Send time cannot be in the past', function (this: Yup.TestContext, value: Date) {
				return (
					dayjs(this.parent.startDate)
						.startOf('day')
						.add(value.getHours(), 'hour')
						.add(value.getMinutes(), 'minute')
						.toDate() > dayjs().toDate()
				);
			}),
		endDate: Yup.date()
			.typeError('Please choose a valid date')
			.required('Time to end is required')
			.min(Yup.ref('startDate'), `"Until" must be later than "Date to send"`),
		paymentId: Yup.string().max(128, 'Custom ID cannot be longer than 128 characters').notRequired(),
		currency: Yup.mixed(),
		cc: Yup.string().email('Invalid email'),
		tax: Yup.number()
			.typeError('Must be a number with a dot as a decimal separator')
			.min(0.000001, 'Minimum amount is 0.000001 ')
			.max(100, 'Maximum amount is 100')
			.nullable(true)
			.notRequired()
			.transform((currentValue, originalValue) => (originalValue === '' ? null : currentValue)), //typerrror considers empty string to be NaN instead of null
		sendIntervalSeconds: Yup.mixed().required('Please choose the interval'),
		walletId: Yup.object<FieldOptionProps>().typeError('Please choose the wallet'),
		paymentDue: Yup.number()
			.typeError('Must be positive full number')
			.positive('Must be a positive number')
			.integer('Must be a full number')
			.max(MAX_PAYMENT_DUE_AMOUNT, 'Payment due should not be more than 30 days')
			.nullable(true),
		description: Yup.string().max(1024, 'The maximum length is 1024 characters').required('Description can`t be empty'),
	});

	const methods = useForm<RecurringInvoiceFields>({
		resolver: yupResolver(recurringInvoiceValidationSchema),
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	const currencyAmountWatch = methods.watch('currencyAmount', 0);
	const taxWatch = methods.watch('tax', 0);
	const currencyWatch = methods.watch('currency', {
		label: activeViewer ? activeViewer.baseCurrency : 'DAG',
		value: activeViewer ? activeViewer.baseCurrency : 'DAG',
	});
	const currencyField = methods.watch().currency;

	if (!rates || !activeViewer || !wallets || !walletOptions || isActionLoading) {
		return <Loader />;
	}

	const userVolumeLimits =
		viewerLimits && viewerLimits.volumeLimit.dag !== null && rates !== null
			? (viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
					rates[currencyField.value as keyof Rates] -
			  (currencyAmountWatch
					? taxWatch
						? parseFloat(currencyAmountWatch.toString()) +
						  parseFloat(currencyAmountWatch.toString()) * (parseFloat(taxWatch.toString()) / 100)
						: currencyAmountWatch
					: 0)
			: 0;

	return (
		<View>
			<H1>Create new recurring invoice</H1>
			<View.ListHeader hasNoBorder></View.ListHeader>
			<FormProvider {...methods}>
				<ReactHookForm onSubmit={methods.handleSubmit(saveRecurringEmailInvoice)} autoComplete="off">
					<PanelNew>
						<PanelNew.Section first white panelType={PanelSectionType.FORM}>
							<H2>Sender & Receiver details</H2>
							<HorizontalLine />
							<HookFormSection>
								<HookFormColumn>
									<HookBaseField
										label="To"
										name="to"
										placeholder="Email address"
										addonSize={116}
										addonNode={
											<AddOnNode toggle={isCcVisible} onClick={() => setIsCcVisible(!isCcVisible)}>
												{isCcVisible ? (
													<>
														Hide Cc <ReactSVG src="/files/svg/private/EyeClose.svg" />
													</>
												) : (
													<>
														Add Cc <ReactSVG src="/files/svg/private/EyeOpen.svg" />
													</>
												)}
											</AddOnNode>
										}
										addonHasToggle={true}
										hookBaseBackground="white"
										tabIndex={1}
									/>
								</HookFormColumn>
								<HookFormColumn>
									<HookBaseField name="subject" label="Subject" tabIndex={2} />
								</HookFormColumn>
							</HookFormSection>
							<Fade collapse when={isCcVisible}>
								<HookFormSection>
									<HookCcField label="Cc recipients" name="cc" setCcEmails={setCcEmails} ccEmails={ccEmails} />
									<View.Description left>Add Cc recipients separated by space or comma key</View.Description>
								</HookFormSection>
							</Fade>
							<H2>Invoice details</H2>
							<HorizontalLine />
							<HookFormSection>
								<HookFormColumn>
									<HookFormCurrencySection>
										<HookFormColumn>
											<HookDatePicker
												id="date"
												name="startDate"
												label="Date to send"
												minDate={dayjs().startOf('day').toDate()}
												maxDate={dayjs().add(1, 'year').toDate()}
											/>
											<HookDatePicker
												id="date"
												name="endDate"
												label="Until"
												minDate={dayjs(methods.watch().startDate || undefined)
													.add(1, 'day')
													.toDate()}
												maxDate={dayjs().add(1, 'year').toDate()}
											/>
										</HookFormColumn>
										<HookFormColumn>
											<HookTimePicker name="startTime" label="Time" size={104} />
										</HookFormColumn>
									</HookFormCurrencySection>
								</HookFormColumn>
								<HookFormColumn>
									<HookRadioButtonGroup
										name="sendIntervalSeconds"
										label="Send new invoice every"
										selectOptions={recurringInvoiceSendIntervals}
									/>

									<HookBaseField name="paymentDue" label="Payment due" addonNode={'Days'} addonSize={62} size={124} />
								</HookFormColumn>
							</HookFormSection>
							<HookFormSection>
								<HookFormColumn>
									<HookBaseField name="paymentId" label="Custom ID or reference nr" optional tabIndex={4} />
									<HookFormCurrencySection>
										<HookFormColumn>
											<HookBaseField
												name="currencyAmount"
												label="Amount to pay"
												addonNode={
													<HookAddonSelectField
														name="currency"
														options={getBaseCurrencyOptions({ current: activeViewer.baseCurrency })}
														tabIndex={3}
														selectedColor={Color.GRAY_2}
														focusedColor={Color.GRAY_0}
														isSearchable
														formatOptionLabel={(option: any, { context }: any) => {
															return context === 'menu' ? option.label : option.label.split(' - ')[0];
														}}
													/>
												}
												addonSize={90}
												tabIndex={5}
											/>
											{methods.errors.currencyAmount === undefined
												? viewerLimits &&
												  viewerLimits.volumeLimit.dag !== null && (
														<NotificationBelowInput>
															Remaining monthly volume until limit{' '}
															<LimitNotificationText
																error={
																	(viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
																		rates[currencyField.value as keyof Rates] -
																		(currencyAmountWatch
																			? taxWatch
																				? //Hookform does not enforce interface types properly
																				  parseFloat(currencyAmountWatch.toString()) +
																				  parseFloat(currencyAmountWatch.toString()) *
																						(parseFloat(taxWatch.toString()) / 100)
																				: currencyAmountWatch
																			: 0) <
																	0
																}
															>
																{(
																	(viewerLimits.volumeLimit.dag - viewerLimits.monthlyTotalVolume.dag) *
																		rates[currencyField.value as keyof Rates] -
																	(currencyAmountWatch
																		? taxWatch
																			? //Hookform does not enforce interface types properly
																			  parseFloat(currencyAmountWatch.toString()) +
																			  parseFloat(currencyAmountWatch.toString()) *
																					(parseFloat(taxWatch.toString()) / 100)
																			: currencyAmountWatch
																		: 0)
																).toFixed(2)}{' '}
																<strong>{currencyField.label}</strong>
															</LimitNotificationText>
														</NotificationBelowInput>
												  )
												: null}
										</HookFormColumn>
										<HookFormColumn>
											<Form.TaxSection>
												<HookBaseField name="tax" label="Include tax" addonNode={'%'} addonSize={48} tabIndex={6} />
											</Form.TaxSection>
										</HookFormColumn>
									</HookFormCurrencySection>
								</HookFormColumn>
								<HookFormColumn>
									{isWalletSelectVisible && (
										<HookSelectField
											label="Wallet"
											name="walletId"
											options={walletOptions}
											tabIndex={7}
											selectedColor={Color.GRAY_2}
											focusedColor={Color.GRAY_0}
											isSearchable
										/>
									)}
								</HookFormColumn>
							</HookFormSection>
							<HookFormSection>
								<HookTextAreaField label="Description" name="description" tabIndex={8} rows={3} />
							</HookFormSection>
						</PanelNew.Section>
						<PanelNew.Section middle gray separateWithBorder>
							<EscrowTotalWrapper>
								<H2>Invoice total</H2>
								<HorizontalLine />
								<TotalItem>
									<DealTotalText>Total Amount</DealTotalText>
									<CalculatedInvoiceTotal
										rates={rates}
										tax={taxWatch ? taxWatch : 0}
										currencyAmount={currencyAmountWatch ? currencyAmountWatch : 0}
										currency={Currency[currencyWatch.value as keyof typeof Currency]}
									/>
								</TotalItem>
							</EscrowTotalWrapper>
							{errorMessage && <View.Error customLinkColor={Color.RED_ERROR}>{errorMessage}</View.Error>}
						</PanelNew.Section>
						<PanelNew.Section last gray panelType={PanelSectionType.BUTTON}>
							<PreviousLink title="Back" to={RoutesUrls.EMAIL_INVOICES} />
							<PanelNew.ActionButtonsWrapper>
								<Button.Secondary
									green
									type={ButtonTypes.SUBMIT}
									isDisabled={isActionLoading}
									tabIndex={9}
									onClick={methods.handleSubmit(saveRecurringEmailInvoice)}
								>
									{!isActionLoading ? 'Send receipt' : <Loader size={20} color={Color.WHITE} width={120.5} />}
								</Button.Secondary>
							</PanelNew.ActionButtonsWrapper>
						</PanelNew.Section>
					</PanelNew>
				</ReactHookForm>
			</FormProvider>
		</View>
	);
}

export default withRouter(RecurringInvoiceDetailView);
