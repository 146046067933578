import dayjs from 'dayjs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { EmailInvoice, InvoiceState } from '../../../../api/invoiceApi';
import ColoredInvoiceState from '../../../../components/colored-invoice-state/ColoredInvoiceState';
import CreateInvoiceBlock from '../../../../components/create-invoice-block/CreateInvoiceBlock';
import GridTable, { GridTableItem, GridTableType } from '../../../../components/grid-table/GridTable';
import InvoicePrice from '../../../../components/invoice-price/InvoicePrice';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Notification from '../../../../components/notification/Notification';
import Pager from '../../../../components/pager/Pager';
import Table from '../../../../components/table/Table';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { ParentViews, RoutesUrls, Scopes, GLOBAL_DATE_FORMAT } from '../../../../constants';

import { EmailInvoiceColSizes } from '../../../../gfx/constants';
import { Avatar, H1, JustifiedLeftFlex } from '../../../../gfx/globals';
import { ApiArrayPayload } from '../../../../typings';
import { InvoiceWrapper, NotificationRowWrapper, NotificationText, TitleWrapper } from '../escrow/EscrowListViewStyle';

import { OpenedInformation } from './OneTimeInvoicesListView';

interface Props {
	page: number;

	emailInvoices: ApiArrayPayload<EmailInvoice>;
	openedInformationRows: OpenedInformation[];
	isWalletAdded: boolean;
	isVerified: boolean;
	isParentVerified: boolean;
	isTeamMember: boolean;
	isOwner: boolean;
	handlePageChanged(newPage: number): void;
	handleToggleInformation(transactionId: string): void;
}

export default function OneTimeInvoicesViewTpl(props: Props) {
	const {
		emailInvoices,
		page,
		handlePageChanged,

		isWalletAdded,
		isVerified,
		isParentVerified,
		isTeamMember,
		isOwner,
	} = props;

	const onTableArrowClick = (invoiceId: string) => (_e: React.MouseEvent<HTMLElement>) =>
		props.handleToggleInformation(invoiceId);

	const renderInvoiceRows = (emailInvoice: EmailInvoice) => {
		const invoiceStatesToShow = [InvoiceState.PENDING, InvoiceState.PAID_EXPIRED, InvoiceState.PAID];

		const showInvoiceStatus = emailInvoice.invoice && invoiceStatesToShow.includes(emailInvoice.invoice.state);

		return (
			<React.Fragment key={emailInvoice.id}>
				<Table.TrPrimary className={isOpened(emailInvoice.id) ? 'opened' : undefined}>
					<Table.UserNameTd data-label="Receiver">
						<JustifiedLeftFlex>
							<Avatar width={24} height={24} marginRight={10}>
								{emailInvoice.to.charAt(0).toUpperCase()}
							</Avatar>
							{emailInvoice.to}
						</JustifiedLeftFlex>
					</Table.UserNameTd>
					<Table.FixedWidthTd cellWidth={EmailInvoiceColSizes.CREATED}>
						<Table.DataWrapper data-label="Created">
							{dayjs(emailInvoice.createdDate).format(GLOBAL_DATE_FORMAT)}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EmailInvoiceColSizes.DUE_DATE}>
						<Table.DataWrapper data-label="Due date">
							{dayjs(emailInvoice.expiryDate).format(GLOBAL_DATE_FORMAT)}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EmailInvoiceColSizes.STATUS}>
						<Table.DataWrapper data-label="Status">
							<ColoredInvoiceState state={showInvoiceStatus ? emailInvoice.invoice.state : emailInvoice.state} />
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd noWhiteSpaceWrap cellWidth={EmailInvoiceColSizes.AMOUNT}>
						<Table.DataWrapper data-label="Amount">
							<InvoicePrice
								currencyAmount={emailInvoice.amount.total.currency}
								coinAmount={emailInvoice.amount.total.coin}
								currency={emailInvoice.currency}
							/>
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.SeeMoreTd
						className={isOpened(emailInvoice.id) ? 'opened' : undefined}
						cellWidth={EmailInvoiceColSizes.ACTIONS}
					>
						<Table.Arrow
							opened={isOpened(emailInvoice.id)}
							onClick={onTableArrowClick(emailInvoice.id)}
							className="ArrowDown"
						>
							{isOpened(emailInvoice.id) ? 'See less' : 'See more'}
							<ReactSVG src="/files/svg/private/Arrow.svg" />
						</Table.Arrow>
					</Table.SeeMoreTd>
				</Table.TrPrimary>
				{isOpened(emailInvoice.id) && getMoreEmailInvoiceInformation(emailInvoice)}
			</React.Fragment>
		);
	};

	const isOpened = (invoiceId: string) =>
		props.openedInformationRows.filter((obj) => obj.invoiceId === invoiceId && obj.isOpened).length > 0;

	const getMoreEmailInvoiceInformation = (emailInvoice: EmailInvoice) => {
		const itemList: GridTableItem[] = [
			{
				label: 'Subject',
				value: emailInvoice.subject,
				position: { startingColumn: 1, endingColumn: 1, startingRow: 1, endingRow: 1 },
				id: 'subject',
			},
			{
				label: 'Cc recipients',
				value: emailInvoice.cc.length > 0 ? emailInvoice.cc.join('; ') : '-',
				position: { startingColumn: 2, endingColumn: 3, startingRow: 1, endingRow: 1 },
				id: 'cc-recipients',
			},
			{
				position: { startingRow: 2, endingRow: 2, startingColumn: 1, endingColumn: 3 },
				isSeparatorLine: true,
			},
			{
				label: 'Description',
				value: emailInvoice.description,
				position: { startingColumn: 1, endingColumn: 1, startingRow: 3, endingRow: 3 },
				id: 'receiving-wallet',
			},
			{
				label: 'Custom ID or reference nr',
				value: emailInvoice.paymentId ? emailInvoice.paymentId : '-',
				position: { startingColumn: 2, endingColumn: 2, startingRow: 3, endingRow: 3 },
				id: 'custom-id',
			},
			{
				label: 'Subtotal',
				value: `${emailInvoice.amount.total.coin - emailInvoice.amount.tax.coin} DAG`,
				position: { startingColumn: 3, endingColumn: 3, startingRow: 3, endingRow: 3 },
				id: 'subtotal',
			},
			{
				label: 'Description',
				value: emailInvoice.description,
				position: { startingColumn: 1, endingColumn: 2, startingRow: 4, endingRow: 5 },
				showCondition: emailInvoice.description !== undefined,
				preWrap: true,
				id: 'description',
			},
			{
				label: 'Tax',
				value: `${emailInvoice.tax}%`,
				position: { startingColumn: 3, endingColumn: 3, startingRow: 4, endingRow: 4 },
				id: 'tax',
			},
			{
				label: 'Invoice total',
				value: `${emailInvoice.amount.total.currency} ${emailInvoice.currency}`,
				totalItem: {
					name: 'DAG',
					value: emailInvoice.amount.total.coin,
				},
				position: { startingColumn: 3, endingColumn: 3, startingRow: 5, endingRow: 5 },
				id: 'invoice-total',
			},
		];

		return (
			<GridTable opened={isOpened(emailInvoice.id)} tableType={GridTableType.ONE_TIME_INVOICE} tableItems={itemList} />
		);
	};

	return (
		<View>
			<TitleWrapper>
				<H1>Email Invoice</H1>
			</TitleWrapper>

			<NotificationRowWrapper>
				<NotificationText>
					View your created email invoices or create a new one to send to your customer
				</NotificationText>
				{emailInvoices.items.length > 0 && emailInvoices.itemCount > 0
					? isWalletAdded && isVerified && isParentVerified && <CreateInvoiceBlock />
					: ' '}
			</NotificationRowWrapper>

			{!isWalletAdded && (
				<Notification left>
					Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to create new email invoice.
				</Notification>
			)}
			{!isTeamMember && !isVerified && (
				<Notification left>
					Your identity or business is not verified, you can create new email invoice after process is completed.
				</Notification>
			)}
			{!isOwner && !isParentVerified && <Notification left>Dagpay account access limited</Notification>}
			<InvoiceWrapper>
				<WithPermission permissions={[Scopes.RECURRING_INVOICES, Scopes.CREATE_RECURRING_INVOICE]}>
					<NavigationBar parentView={ParentViews.EMAIL_INVOICES} subTitle="Invoices" />
				</WithPermission>

				{emailInvoices.items.length > 0 && emailInvoices.itemCount > 0 ? (
					<WithPermission permissions={[Scopes.EMAIL_INVOICES, Scopes.GET_EMAIL_INVOICES]}>
						<Table>
							<Table.Thead hiddenOnSmallerScreen>
								<tr>
									<th>Receiver</th>
									<th>Created</th>
									<th>Due date</th>
									<th>Status</th>
									<th>Amount</th>
									<th />
								</tr>
							</Table.Thead>
							<tbody>{emailInvoices.items.map((emailInvoice) => renderInvoiceRows(emailInvoice))}</tbody>
						</Table>
					</WithPermission>
				) : (
					<View.NoItemsFound>
						<ReactSVG src="/files/svg/private/NoEmailInvoice.svg" />
						<p>You haven’t created any email invoices yet</p>
						{isVerified && <CreateInvoiceBlock />}
					</View.NoItemsFound>
				)}
				<Pager
					totalItems={emailInvoices.itemCount}
					currentPage={page}
					onChange={handlePageChanged}
					totalPages={emailInvoices.pageCount}
				/>
			</InvoiceWrapper>
		</View>
	);
}
