import { isValidNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { IconPaths } from './components/field/Field';
import { isValidChash } from './services/chash';

/* GENERAL */

export const ITEMS_PER_PAGE_DASHBOARD = 10;
export const ITEMS_PER_PAGE = 25;
export const EMAIL_INVOICE_VALID_MIN_SECONDS = 30;
export const EMAIL_INVOICE_VALID_MAX_SECONDS = 2629746;
export const ACCEPTED_FILE_FORMATS = ['image/jpeg', 'application/pdf', 'image/png'];
export const MAX_FILE_SIZE = 5242880;
export const MIN_FILE_SIZE = 1000;
export const MAX_PAYMENT_DUE_AMOUNT = 30;
export const LENGTH_OF_WALLET_ADDRESS = 32;
export const MAX_LOGIN_CODE_LENGTH = 6;
export const MIN_LOGIN_CODE_LENGTH = 4;
export const INVOICE_DELAY_MINUTES = 30;
export const DAGCOIN_CARD_NUMBER_LENGTH = 16;
export const DAGCOIN_CARD_NUMBER_PART_LENGTH = 4;
export const DAGCOIN_CARD_PIN_CODE_LENGTH = 4;
export const GLOBAL_DATE_FORMAT = 'DD MMM, YYYY';
export const ESCROW_FEE = 0.01 * 2; // transaction fee 2x : in DAGs (Escrow fee of 0.1 removed until legal side is resolved)
export const MICRO_CONVERSION_RATE = 1_000_000;
export const FREELANCE_1_LIMIT = 1000;
export const TIER_1_LIMIT = 1000;
export const TIER_2_LIMIT = 10000;
export const TIER_3_LIMIT = undefined;
export const WS_RPC_PING_INTERVAL = 10_000;
export const WS_RPC_MAX_PING_FAILURES = 3;
export const WS_RPC_MAX_RECONNECT_FAILURES = 10;

export enum CardStates {
	DEFAULT = 'DEFAULT',
	SUCCESS = 'SUCCESS',
	CONFIRMING = 'CONFIRMING',
	CARD_NOT_FOUND_OR_ACTIVE = 'CARD_NOT_FOUND_OR_ACTIVE',
	CARD_HASH_INVALID = 'CARD_HASH_INVALID',
	CARD_PAYMENT_FAILURE = 'CARD_PAYMENT_FAILURE',
	CARD_NO_FUNDS = 'CARD_NO_FUNDS',
	CARD_OVER_DAILY_SPEND_LIMIT_STORE = 'CARD_OVER_DAILY_SPEND_LIMIT_STORE',
	CARD_OVER_DAILY_SPEND_LIMIT_ONLINE = 'CARD_OVER_DAILY_SPEND_LIMIT_ONLINE',
	BAD_REQUEST = 'BAD_REQUEST',
	OTHER_ERROR = 'OTHER_ERROR',
}

export enum TagInputCodes {
	ENTER = 188,
	SPACE = 13,
}

export enum AppPublicUrls {
	TEST = 'https://test.dagpay.io/',
	LIVE = 'https://dagpay.io/',
}

export enum DashboardFilters {
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	QUARTER = 'QUARTER',
	YEAR = 'YEAR',
	ALL = 'ALL',
}

export enum CamelCasedIntegrationTypes {
	CUSTOM = 'Custom',
	MAGENTO = 'Magento',
	WOOCOMMERCE = 'WooCommerce',
	EASY_DIGITAL_DOWNLOADS = 'EasyDigitalDownloads',
	OPENCART = 'OpenCart',
	PRESTASHOP = 'PrestaShop',
	TATUM = 'Tatum',
}

export interface Links {
	title: string;
	to: string;
	icon?: string;
}

/* ROUTES */

export enum RoutesUrls {
	/* PUBLIC */

	LOGIN = '/login',
	SIGN_UP = '/sign-up',
	SIGN_UP_CREATE_ACCOUNT = '/sign-up/create-account',
	SIGN_UP_FILL_DETAILS = '/sign-up/fill-details',
	PASSWORD_RECOVERY = '/password-recovery',
	RESET_PASSWORD = '/reset-password',
	EMAIL_VERIFIED = '/email-verified',
	EMAIL_INVOICE_EXPIRED = '/email-invoice-expired',
	MERCHANT_LIMIT_EXCEEDED = '/merchant-limit-exceeded',
	REGISTER_INVITE = '/register-invite',
	DOCUMENTATION = '/documentation',
	ESCROW_BUYER_DETAILS = '/escrow',
	EXTERNAL_INVOICE = '/external-invoice',
	CRYPTO_PAYMENTS = '/crypto-payments',

	/* PRIVATE */

	PRIVATE = '/private',
	DASHBOARD = '/private/dashboard',
	EMAIL_INVOICES = '/private/merchant/email-invoices',
	EMAIL_INVOICES_DETAIL = '/private/merchant/email-invoices/detail',
	RECURRING_INVOICES = '/private/merchant/email-invoices/recurring',
	RECURRING_INVOICES_DETAIL = '/private/merchant/email-invoices/recurring/detail',
	POS_CHECKOUT = '/private/merchant/pos-checkout',
	TRANSACTIONS = '/private/merchant/transactions',
	ESCROW_DEALS = '/private/merchant/escrow/deals',
	ESCROW_SELLER_DEALS = '/private/merchant/escrow/deals/seller',
	ESCROW_BUYER_DEALS = '/private/merchant/escrow/deals/buyer',
	ESCROW_NEW_DEAL = '/private/merchant/escrow/deals/new',
	MERCHANT_TOOLS = '/private/merchant/merchant-tools',
	POS_PAIRINGS = '/private/merchant/merchant-tools/pos-pairings',
	SETTINGS = '/private/settings',
	MERCHANT_PROFILE = '/private/settings/merchant-profile',
	WALLETS = '/private/settings/wallets',
	WALLETS_DETAIL = '/private/settings/wallets/detail',
	INSTANT_WALLETS_DETAIL = '/private/dashboard/wallets-detail',
	ACCOUNT_DETAILS = '/private/settings/account-details',
	COMPANY_DETAILS = '/private/settings/company-details',
	USERS = '/private/settings/users',
	USERS_DETAIL = '/private/settings/users/detail',
	SECURITY = '/private/settings/security',
	LOGOUT = '/private/logout',
	VERIFY_IDENTITY = '/private/verify-identity',
	VERIFY_BUSINESS = '/private/verify-business',
	VERIFY_FREELANCE = '/private/verify-freelance',
	VERIFY_BUSINESS_DETAILS = '/private/verify-business-details',
	VERIFY_BUSINESS_REGISTRATION_DOCUMENTS = '/private/verify-business-registration-documents',
	VERIFY_BUSINESS_ADDRESS_DOCUMENTS = '/private/verify-business-step-address-documents',
	VERIFY_BUSINESS_PROOF_OF_SHARES_DOCUMENTS = '/private/verify-business-step-operations-documents',
	VERIFY_BUSINESS_TIERS = '/private/verify-business-tiers',
	ENVIRONMENTS = '/private/merchant/merchant-tools/environments',
	CHOOSE_ENVIRONMENT_TYPE = '/private/merchant/merchant-tools/environments/choose-environment-type',
	CHOOSE_TATUM_ENVIRONMENT_TYPE = '/private/merchant/merchant-tools/environments/choose-tatum-environment-type',
	ATMS = '/private/merchant/merchant-tools/atms',
	ATMS_DETAIL = '/private/merchant/merchant-tools/atms/detail',
	VATMS = '/private/merchant/merchant-tools/vatms',
	VATMS_DETAIL = '/private/merchant/merchant-tools/vatms/detail',
	ENVIRONMENTS_DETAIL = '/private/merchant/merchant-tools/environments/detail',
	TATUM_ENVIRONMENT_DETAIL = '/private/merchant/merchant-tools/tatum-environment/detail',
	LIMITS = '/private/settings/customer-limits',
	LIMITS_DETAIL = '/private/settings/customer-limits/detail',
}

/* INVITE */

export enum InviteStatus {
	UNKNOWN = 'UNKNOWN',
	EXPIRED = 'EXPIRED',
	FAILED = 'FAILED',
	SENT = 'SENT',
}

export enum InvitedUserStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	UNKNOWN = 'UNKNOWN',
	EXPIRED = 'EXPIRED',
	FAILED = 'FAILED',
	SENT = 'SENT',
}

export enum InvitedUserType {
	INVITE = 'INVITE',
	USER = 'USER',
}

export enum InvitedUsersFilterStatus {
	ALL = 'ALL',
	ACTIVE = 'ACTIVE',
	INVITED = 'SENT',
	INACTIVE = 'INACTIVE',
	EXPIRED = 'EXPIRED',
}

/* USER */

export enum BusinessType {
	FREELANCE = 'FREELANCE',
	BUSINESS = 'BUSINESS',
}

export enum UserLoginType {
	PASSWORD = 'PASSWORD',
	EMAIL = 'EMAIL',
	AUTHENTICATOR = 'AUTHENTICATOR',
	UNKNOWN = 'UNKNOWN',
}

export enum InvitedUserRole {
	ADMIN = 'ADMIN',
	ADVANCED_TEAM_MEMBER = 'ADVANCED_TEAM_MEMBER',
	TEAM_MEMBER = 'TEAM_MEMBER',
	PENDING_ADMIN = 'PENDING_ADMIN',
}

export enum UserRole {
	OWNER = 'OWNER',
	ADMIN = 'ADMIN',
	ADVANCED_TEAM_MEMBER = 'ADVANCED_TEAM_MEMBER',
	TEAM_MEMBER = 'TEAM_MEMBER',
	POS = 'POS',
	PENDING_ADMIN = 'PENDING_ADMIN',
}

export enum AtmPaymentsDefault {
	OFF = 'OFF',
	ASK = 'ASK',
	ENABLED = 'ENABLED',
}

export enum UserStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export enum Scopes {
	/* authentication */
	AUTHENTICATION = 'authentication',
	ENABLE_AUTHENTICATOR = 'enable-authenticator',
	RESEND_EMAIL_VERIFICATION = 'resend-email-verification',

	/* email invoices */
	EMAIL_INVOICES = 'email-invoices',
	CREATE_EMAIL_INVOICE = 'create-email-invoice',
	GET_EMAIL_INVOICE = 'get-email-invoice',
	GET_EMAIL_INVOICES = 'get-email-invoices',

	/* environments */
	ENVIRONMENTS = 'environments',
	CREATE_ENVIRONMENT = 'create-environment',
	GET_ENVIRONMENT = 'get-environment',
	GET_ENVIRONMENTS = 'get-environments',
	UPDATE_ENVIRONMENT = 'update-environment',
	DELETE_ENVIRONMENT = 'delete-environment',
	REGENERATE_ENVIRONMENT_SECRET = 'regenerate-environment-secret',

	/* invoices */
	INVOICES = 'invoices',
	CREATE_INVOICE = 'create-invoice',
	GET_INVOICE = 'get-invoice',
	GET_INVOICES = 'get-invoices',

	/* merchant-finder */
	MERCHANT_FINDER_UPDATE = 'merchant-finder-update',

	/* pos */
	POS = 'pos',
	POS_PAIRING = 'pos-pairing',
	REVOKE_POS_PAIRING = 'revoke-pos-pairing',
	GENERATE_POS_PAIRING_CODE = 'generate-pos-pairing-code',
	GET_POS_PAIRINGS = 'get-pos-pairing',
	CREATE_POS_INVOICE = 'create-pos-invoice',
	GET_POS_INVOICE = 'get-pos-invoice',
	GET_POS_INVOICES = 'get-pos-invoices',

	/* recurring invoices */
	RECURRING_INVOICES = 'recurring-invoices',
	CREATE_RECURRING_INVOICE = 'create-recurring-invoice',
	GET_RECURRING_INVOICE = 'get-recurring-invoice',
	GET_RECURRING_INVOICES = 'get-recurring-invoices',
	CANCEL_RECURRING_INVOICE = 'cancel-recurring-invoice',

	/* users */
	USERS = 'users',
	CHANGE_PASSWORD = 'change-password',
	GET_AUTH_QR = 'get-auth-qr',
	UPDATE_USER = 'update-user',
	UPDATE_SUPPORT_EMAIL = 'update-support-email',

	/* verify */
	VERIFY = 'verify',
	VERIFY_IDENTITY = 'verify-identity',
	VERIFY_BUSINESS = 'verify-business',
	GET_IDENTITY_VERIFICATION = 'get-identity-verification',
	GET_BUSINESS_VERIFICATION = 'get-business-verification',

	/* viewer */
	VIEWER = 'viewer',
	VIEWER_INFO = 'viewer-info',
	VIEWER_STATISTICS = 'viewer-statistics',

	/* wallets */
	WALLETS = 'wallets',
	CREATE_WALLET = 'create-wallet',
	GET_WALLET = 'get-wallet',
	GET_DEFAULT_WALLET = 'get-default-wallet',
	GET_WALLETS = 'get-wallets',
	UPDATE_WALLET = 'update-wallet',
	DELETE_WALLET = 'delete-wallet',

	/* customer limits */
	CUSTOMER_LIMITS = 'customer-limits',
	CREATE_CUSTOMER_LIMIT = 'create-customer-limit',
	GET_CUSTOMER_LIMIT = 'get-customer-limit',
	GET_CUSTOMER_LIMITS = 'get-customer-limits',
	UPDATE_CUSTOMER_LIMIT = 'update-customer-limit',
	DELETE_CUSTOMER_LIMIT = 'delete-customer-limit',

	/* utm */
	UTM = 'utm',

	/* invites */
	INVITES = 'invites',
	INVITED_USERS = 'invited-users',
	UPDATE_INVITED_USER = 'update-invited-user',
	CREATE_INVITE = 'create-invite',
	GET_INVITE = 'get-invite',
	GET_INVITES = 'get-invites',
	GET_INVITED_USER = 'get-invited-user',
	GET_INVITED_USERS = 'get-invited-users',
	DELETE_INVITE = 'delete-invite',
	DISABLE_INVITED_USER = 'disable-invited-user',
	ENABLE_INVITED_USER = 'enable-invited-user',

	/* ATM */
	ATMS = 'atms',
	CREATE_ATM = 'create-atm',
	GET_ATM = 'get-atm',
	GET_ATMS = 'get-atms',
	UPDATE_ATM = 'update-atm',
	DELETE_ATM = 'delete-atm',

	/* VATM */
	VATMS = 'vatms',
	CREATE_VATM = 'create-vatm',
	GET_VATM = 'get-vatm',
	GET_VATMS = 'get-vatms',
	UPDATE_VATM = 'update-vatm',
	DELETE_VATM = 'delete-vatm',

	/* discount */
	DISCOUNTS = 'discounts',
	CREATE_DISCOUNT = 'create-discount',
	GET_DISCOUNT = 'get-discount',
	GET_DISCOUNT_LIST = 'get-discount-list',
	UPDATE_DISCOUNT = 'update-discount',

	/* escrow */
	ESCROW = 'escrow',
	CREATE_ESCROW = 'create-escrow',
	GET_ESCROW = 'get-escrow',
	GET_ESCROWS = 'get-escrows',
	UPDATE_ESCROW = 'update-escrow',
}

/* VERIFICATION */
export enum BusinessTier {
	FREELANCE = 'FREELANCE',
	TIER_1 = 'TIER_1',
	TIER_2 = 'TIER_2',
	TIER_3 = 'TIER_3',
}

export enum VerificationType {
	VERIFY_IDENTIFICATION = 'VERIFY_IDENTIFICATION',
	VERIFY_BUSINESS = 'VERIFY_BUSINESS',
	FREELANCE = 'FREELANCE',
	TIER_1 = 'TIER_1',
	TIER_2 = 'TIER_2',
	TIER_3 = 'TIER_3',
}

export enum VerificationState {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

export enum VerificationDocumentType {
	PASSPORT = 'PASSPORT',
	NATIONAL_ID = 'NATIONAL_ID',
	DRIVERS_LICENCE = 'DRIVERS_LICENCE',
}

/* FIELD */

export enum FieldTypes {
	TEXT = 'text',
	TEXTAREA = 'textarea',
	SELECT = 'select',
	CHECKBOX = 'checkbox',
	PHONE = 'phone',
	EMAIL = 'email',
	NUMBER = 'number',
	PASSWORD = 'password',
	DROPZONE = 'dropzone',
	TAG = 'tag',
	DATE = 'date',
	RADIO = 'radio',
	MULTISELECT = 'multiselect',
	DECIMAL = 'decimal',
}

export enum FieldIconTypes {
	EMAIL = 'EMAIL',
	PASSWORD = 'PASSWORD',
	USER = 'USER',
	ADDRESS = 'ADDRESS',
	COMPANY = 'COMPANY',
	VERIFICATION_CODE = 'VERIFICATION_CODE',
	SUBJECT = 'SUBJECT',
	COINS = 'COINS',
	CALENDAR = 'CALENDAR',
	CALENDAR_DARK = 'CALENDAR_DARK',
	WALLET = 'WALLET',
	BROWSER = 'BROWSER',
	NOTIFICATION = 'NOTIFICATION',
	ENVIRONMENT = 'ENVIRONMENT',
	COMPANY_NAME = 'COMPANY_NAME',
	COMPANY_REG_NUMBER = 'COMPANY_REG_NUMBER',
	POSTAL = 'POSTAL',
	VAT = 'VAT',
	SEARCH = 'SEARCH',
	DISCOUNT = 'DISCOUNT',
}

export const iconPaths: IconPaths = {
	EMAIL: '/files/svg/icons/Email.svg',
	PASSWORD: '/files/svg/icons/Password.svg',
	USER: '/files/svg/icons/User.svg',
	ADDRESS: '/files/svg/icons/Address.svg',
	COMPANY: '/files/svg/icons/Company.svg',
	VERIFICATION_CODE: '/files/svg/icons/VerificationCode.svg',
	SUBJECT: '/files/svg/icons/Subject.svg',
	COINS: '/files/svg/icons/Coins.svg',
	CALENDAR: '/files/svg/icons/Calendar.svg',
	CALENDAR_DARK: '/files/svg/icons/CalendarDark.svg',
	WALLET: '/files/svg/icons/Wallet.svg',
	BROWSER: '/files/svg/icons/Browser.svg',
	NOTIFICATION: '/files/svg/icons/Notification.svg',
	ENVIRONMENT: '/files/svg/icons/Environment.svg',
	COMPANY_NAME: '/files/svg/icons/CompanyName.svg',
	COMPANY_REG_NUMBER: '/files/svg/icons/CompanyRegNumber.svg',
	POSTAL: '/files/svg/icons/Postal.svg',
	VAT: '/files/svg/icons/Vat.svg',
	SEARCH: '/files/svg/icons/Search.svg',
	DISCOUNT: '/files/svg/private/settings/discount.svg',
};

/* VIEWS AND LINKS */

export enum NavSidebarIcons {
	DASHBOARD = 'DASHBOARD',
	EMAIL_INVOICE = 'EMAIL_INVOICE',
	POS = 'POS',
	TRANSACTIONS = 'TRANSACTIONS',
	ESCROW = 'ESCROW',
	MERCHANT_TOOLS = 'MERCHANT_TOOLS',
	SETTINGS = 'SETTINGS',
	DOCUMENTATION = 'DOCUMENTATION',
}

export enum ParentViews {
	EMAIL_INVOICES = 'EMAIL_INVOICES',
	SETTINGS = 'SETTINGS',
	ESCROW_DEALS = 'ESCROW_DEALS',
}

/* VALIDATIONS */

export const Validations = {
	INVALID_EMAIL: Yup.string()
		.trim('Field cannot be left empty or filled with spaces')
		.email('Invalid email')
		.required('Email is required'),
	EMAILS_DO_NOT_MATCH: Yup.string()
		.email('Invalid email')
		.oneOf([Yup.ref('email'), null], 'Emails do not match')
		.required('Email confirmation is required'),
	PASSWORD_NOT_SECURE: Yup.string()
		// TODO: at least 1 number and sequence of 3 chars is not allowed
		// .test('is-secure', 'Password is not secure enough', (value) => value && value !== undefined && owasp.test(value).strong)
		.required('Password is required')
		.test(
			'is-long-enough',
			'Password must contain at least 10 characters',
			(value) => value && value !== undefined && value.length >= 10,
		)
		.test(
			'contains-special',
			'Password must contain special character',
			(value) => value && value !== undefined && /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value),
		)
		.test(
			'contains-uppercase',
			'Password must contain uppercase character',
			(value) => value && value !== undefined && /[A-Z]+/.test(value),
		)
		.test(
			'contains-lowercase',
			'Password must contain lowercase character',
			(value) => value && value !== undefined && /[a-z]+/.test(value),
		),
	PASSWORDS_DO_NOT_MATCH: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords do not match')
		.required('Password confirmation is required'),
	INVALID_PHONE_NUMBER: Yup.mixed()
		.required('Phone number is required')
		.test('is-valid-phone-length', 'Phone number cannot be longer than 16 digits', (value) =>
			value && value !== undefined ? (value as string).length <= 16 : false,
		)
		.test('is-valid-phone', 'Phone number is not valid', (value) =>
			value && value !== undefined ? isValidNumber(value) : false,
		),
	REFERRAL_CODE_NOT_LONG_ENOUGH: Yup.string()
		.test('is-long-enough', 'Referral code should be 8 characters long', (value) => !value || value.length === 8)
		.test('no-empty-spaces', 'Referral code must contain characters', (value) =>
			value && value !== undefined && value.length !== 0 ? value.trim().length !== 0 : true,
		),
	INVALID_WALLET_ADDRESS: Yup.string()
		.required('Wallet address is required')
		.test(
			'is-valid-address',
			'Wallet address is not valid',
			(value) => value && value !== undefined && isValidChash(value, LENGTH_OF_WALLET_ADDRESS),
		),
	AGREEMENT_CHECKED: Yup.boolean().oneOf([true], 'Agreement should be checked'),
	FIRST_NAME_IS_REQUIRED: Yup.string()
		.required('First name is required')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true)
		.max(100, 'First name should not be longer than 100 characters'),
	PASSWORD_IS_REQUIRED: Yup.string().required('Password is required'),
	LAST_NAME_IS_REQUIRED: Yup.string()
		.required('Last name is required')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true)
		.max(100, 'Last name should not be longer than 100 characters'),
	COMPANY_NAME_IS_REQUIRED: Yup.string()
		.required('Company name is required')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true)
		.max(100, 'Company name should not be longer than 100 characters'),
	COUNTRY_IS_REQUIRED: Yup.string().nullable(true).required('Country is required'),
	WALLET_NAME_IS_REQUIRED: Yup.string()
		.required('Name is required')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	WALLET_CURRENCY_IS_REQUIRED: Yup.string()
		.required('Wallet currency is required')
		.trim('Field cannot be left empty or filled with spaces')
		.strict(true),
	DEFAULT_IS_OPTIONAL: Yup.boolean().notRequired(),
};

/* COUNTRIES */

export enum Countries {
	AD = 'Andorra',
	AE = 'United Arab Emirates',
	AF = 'Afghanistan',
	AG = 'Antigua and Barbuda',
	AI = 'Anguilla',
	AL = 'Albania',
	AM = 'Armenia',
	AN = 'Netherlands Antilles',
	AO = 'Angola',
	AQ = 'Antarctica',
	AR = 'Argentina',
	AS = 'American Samoa',
	AT = 'Austria',
	AU = 'Australia',
	AW = 'Aruba',
	AX = 'Åland Islands',
	AZ = 'Azerbaijan',
	BA = 'Bosnia and Herzegovina',
	BB = 'Barbados',
	BD = 'Bangladesh',
	BE = 'Belgium',
	BF = 'Burkina Faso',
	BG = 'Bulgaria',
	BH = 'Bahrain',
	BI = 'Burundi',
	BJ = 'Benin',
	BM = 'Bermuda',
	BN = 'Brunei Darussalam',
	BO = 'Bolivia',
	BR = 'Brazil',
	BS = 'Bahamas',
	BT = 'Bhutan',
	BV = 'Bouvet Island',
	BW = 'Botswana',
	BY = 'Belarus',
	BZ = 'Belize',
	CA = 'Canada',
	CC = 'Cocos (Keeling Islands',
	CD = 'Congo, The Democratic Republic of the',
	CF = 'Central African Republic',
	CG = 'Congo',
	CH = 'Switzerland',
	CI = "Cote D'Ivoire",
	CK = 'Cook Islands',
	CL = 'Chile',
	CM = 'Cameroon',
	CN = 'China',
	CO = 'Colombia',
	CR = 'Costa Rica',
	CU = 'Cuba',
	CV = 'Cape Verde',
	CX = 'Christmas Island',
	CY = 'Cyprus',
	CZ = 'Czech Republic',
	DE = 'Germany',
	DJ = 'Djibouti',
	DK = 'Denmark',
	DM = 'Dominica',
	DO = 'Dominican Republic',
	DZ = 'Algeria',
	EC = 'Ecuador',
	EE = 'Estonia',
	EG = 'Egypt',
	EH = 'Western Sahara',
	ER = 'Eritrea',
	ES = 'Spain',
	ET = 'Ethiopia',
	FI = 'Finland',
	FJ = 'Fiji',
	FK = 'Falkland Islands',
	FM = 'Micronesia, Federated States of',
	FO = 'Faroe Islands',
	FR = 'France',
	GA = 'Gabon',
	GB = 'United Kingdom',
	GD = 'Grenada',
	GE = 'Georgia',
	GF = 'French Guiana',
	GG = 'Guernsey',
	GH = 'Ghana',
	GI = 'Gibraltar',
	GL = 'Greenland',
	GM = 'Gambia',
	GN = 'Guinea',
	GP = 'Guadeloupe',
	GQ = 'Equatorial Guinea',
	GR = 'Greece',
	GS = 'South Georgia and the South Sandwich Islands',
	GT = 'Guatemala',
	GU = 'Guam',
	GW = 'Guinea-Bissau',
	GY = 'Guyana',
	HK = 'Hong Kong',
	HM = 'Heard Island and Mcdonald Islands',
	HN = 'Honduras',
	HR = 'Croatia',
	HT = 'Haiti',
	HU = 'Hungary',
	ID = 'Indonesia',
	IE = 'Ireland',
	IL = 'Israel',
	IM = 'Isle of Man',
	IN = 'India',
	IO = 'British Indian Ocean Territory',
	IQ = 'Iraq',
	IR = 'Iran, Islamic Republic Of',
	IS = 'Iceland',
	IT = 'Italy',
	JE = 'Jersey',
	JM = 'Jamaica',
	JO = 'Jordan',
	JP = 'Japan',
	KE = 'Kenya',
	KG = 'Kyrgyzstan',
	KH = 'Cambodia',
	KI = 'Kiribati',
	KM = 'Comoros',
	KN = 'Saint Kitts and Nevis',
	KP = "Democratic People's Republic of Korea",
	KR = 'Korea, Republic of',
	KW = 'Kuwait',
	KY = 'Cayman Islands',
	KZ = 'Kazakhstan',
	LA = "Lao People's Democratic Republic",
	LB = 'Lebanon',
	LC = 'Saint Lucia',
	LI = 'Liechtenstein',
	LK = 'Sri Lanka',
	LR = 'Liberia',
	LS = 'Lesotho',
	LT = 'Lithuania',
	LU = 'Luxembourg',
	LV = 'Latvia',
	LY = 'Libyan Arab Jamahiriya',
	MA = 'Morocco',
	MC = 'Monaco',
	MD = 'Moldova, Republic of',
	ME = 'Montenegro',
	MG = 'Madagascar',
	MH = 'Marshall Islands',
	MK = 'Macedonia, The Former Yugoslav Republic of',
	ML = 'Mali',
	MM = 'Myanmar',
	MN = 'Mongolia',
	MO = 'Macao',
	MP = 'Northern Mariana Islands',
	MQ = 'Martinique',
	MR = 'Mauritania',
	MS = 'Montserrat',
	MT = 'Malta',
	MU = 'Mauritius',
	MV = 'Maldives',
	MW = 'Malawi',
	MX = 'Mexico',
	MY = 'Malaysia',
	MZ = 'Mozambique',
	NA = 'Namibia',
	NC = 'New Caledonia',
	NE = 'Niger',
	NF = 'Norfolk Island',
	NG = 'Nigeria',
	NI = 'Nicaragua',
	NL = 'Netherlands',
	NO = 'Norway',
	NP = 'Nepal',
	NR = 'Nauru',
	NU = 'Niue',
	NZ = 'New Zealand',
	OM = 'Oman',
	PA = 'Panama',
	PE = 'Peru',
	PF = 'French Polynesia',
	PG = 'Papua New Guinea',
	PH = 'Philippines',
	PK = 'Pakistan',
	PL = 'Poland',
	PM = 'Saint Pierre and Miquelon',
	PN = 'Pitcairn',
	PR = 'Puerto Rico',
	PS = 'Palestinian Territory, Occupied',
	PT = 'Portugal',
	PW = 'Palau',
	PY = 'Paraguay',
	QA = 'Qatar',
	RE = 'Reunion',
	RO = 'Romania',
	RS = 'Serbia',
	RU = 'Russian Federation',
	RW = 'Rwanda',
	SA = 'Saudi Arabia',
	SB = 'Solomon Islands',
	SC = 'Seychelles',
	SD = 'Sudan',
	SE = 'Sweden',
	SG = 'Singapore',
	SH = 'Saint Helena',
	SI = 'Slovenia',
	SJ = 'Svalbard and Jan Mayen',
	SK = 'Slovakia',
	SL = 'Sierra Leone',
	SM = 'San Marino',
	SN = 'Senegal',
	SO = 'Somalia',
	SR = 'Suriname',
	ST = 'Sao Tome and Principe',
	SV = 'El Salvador',
	SY = 'Syrian Arab Republic',
	SZ = 'Swaziland',
	TC = 'Turks and Caicos Islands',
	TD = 'Chad',
	TF = 'French Southern Territories',
	TG = 'Togo',
	TH = 'Thailand',
	TJ = 'Tajikistan',
	TK = 'Tokelau',
	TL = 'Timor-Leste',
	TM = 'Turkmenistan',
	TN = 'Tunisia',
	TO = 'Tonga',
	TR = 'Turkey',
	TT = 'Trinidad and Tobago',
	TV = 'Tuvalu',
	TW = 'Taiwan',
	TZ = 'Tanzania, United Republic of',
	UA = 'Ukraine',
	UG = 'Uganda',
	UM = 'United States Minor Outlying Islands',
	US = 'United States',
	UY = 'Uruguay',
	UZ = 'Uzbekistan',
	VA = 'Holy See (Vatican City State',
	VC = 'Saint Vincent and the Grenadines',
	VE = 'Venezuela',
	VG = 'Virgin Islands, British',
	VI = 'Virgin Islands, U.S.',
	VN = 'Viet Nam',
	VU = 'Vanuatu',
	WF = 'Wallis and Futuna',
	WS = 'Samoa',
	XK = 'Kosovo',
	YE = 'Yemen',
	YT = 'Mayotte',
	ZA = 'South Africa',
	ZM = 'Zambia',
	ZW = 'Zimbabwe',
}

/* Currencies */

export enum Currency {
	DAG = 'DAG',
	AED = 'AED',
	AFN = 'AFN',
	ALL = 'ALL',
	AMD = 'AMD',
	ANG = 'ANG',
	AOA = 'AOA',
	ARS = 'ARS',
	AUD = 'AUD',
	AWG = 'AWG',
	AZN = 'AZN',
	BAM = 'BAM',
	BBD = 'BBD',
	BDT = 'BDT',
	BGN = 'BGN',
	BHD = 'BHD',
	BIF = 'BIF',
	BMD = 'BMD',
	BND = 'BND',
	BOB = 'BOB',
	BRL = 'BRL',
	BSD = 'BSD',
	BTC = 'BTC',
	BTN = 'BTN',
	BWP = 'BWP',
	BYN = 'BYN',
	BZD = 'BZD',
	CAD = 'CAD',
	CDF = 'CDF',
	CHF = 'CHF',
	CLF = 'CLF',
	CLP = 'CLP',
	CNH = 'CNH',
	CNY = 'CNY',
	COP = 'COP',
	CRC = 'CRC',
	CUC = 'CUC',
	CUP = 'CUP',
	CVE = 'CVE',
	CZK = 'CZK',
	DJF = 'DJF',
	DKK = 'DKK',
	DOP = 'DOP',
	DZD = 'DZD',
	EGP = 'EGP',
	ERN = 'ERN',
	ETB = 'ETB',
	EUR = 'EUR',
	FJD = 'FJD',
	FKP = 'FKP',
	GBP = 'GBP',
	GEL = 'GEL',
	GGP = 'GGP',
	GHS = 'GHS',
	GIP = 'GIP',
	GMD = 'GMD',
	GNF = 'GNF',
	GTQ = 'GTQ',
	GYD = 'GYD',
	HKD = 'HKD',
	HNL = 'HNL',
	HRK = 'HRK',
	HTG = 'HTG',
	HUF = 'HUF',
	IDR = 'IDR',
	ILS = 'ILS',
	IMP = 'IMP',
	INR = 'INR',
	IQD = 'IQD',
	IRR = 'IRR',
	ISK = 'ISK',
	JEP = 'JEP',
	JMD = 'JMD',
	JOD = 'JOD',
	JPY = 'JPY',
	KES = 'KES',
	KGS = 'KGS',
	KHR = 'KHR',
	KMF = 'KMF',
	KPW = 'KPW',
	KRW = 'KRW',
	KWD = 'KWD',
	KYD = 'KYD',
	KZT = 'KZT',
	LAK = 'LAK',
	LBP = 'LBP',
	LKR = 'LKR',
	LRD = 'LRD',
	LSL = 'LSL',
	LYD = 'LYD',
	MAD = 'MAD',
	MDL = 'MDL',
	MGA = 'MGA',
	MKD = 'MKD',
	MMK = 'MMK',
	MNT = 'MNT',
	MOP = 'MOP',
	MRO = 'MRO',
	MRU = 'MRU',
	MUR = 'MUR',
	MVR = 'MVR',
	MWK = 'MWK',
	MXN = 'MXN',
	MYR = 'MYR',
	MZN = 'MZN',
	NAD = 'NAD',
	NGN = 'NGN',
	NIO = 'NIO',
	NOK = 'NOK',
	NPR = 'NPR',
	NZD = 'NZD',
	OMR = 'OMR',
	PAB = 'PAB',
	PEN = 'PEN',
	PGK = 'PGK',
	PHP = 'PHP',
	PKR = 'PKR',
	PLN = 'PLN',
	PYG = 'PYG',
	QAR = 'QAR',
	RON = 'RON',
	RSD = 'RSD',
	RUB = 'RUB',
	RWF = 'RWF',
	SAR = 'SAR',
	SBD = 'SBD',
	SCR = 'SCR',
	SDG = 'SDG',
	SEK = 'SEK',
	SGD = 'SGD',
	SHP = 'SHP',
	SLL = 'SLL',
	SOS = 'SOS',
	SRD = 'SRD',
	SSP = 'SSP',
	STD = 'STD',
	STN = 'STN',
	SVC = 'SVC',
	SYP = 'SYP',
	SZL = 'SZL',
	THB = 'THB',
	TJS = 'TJS',
	TMT = 'TMT',
	TND = 'TND',
	TOP = 'TOP',
	TRY = 'TRY',
	TTD = 'TTD',
	TWD = 'TWD',
	TZS = 'TZS',
	UAH = 'UAH',
	UGX = 'UGX',
	USD = 'USD',
	UYU = 'UYU',
	UZS = 'UZS',
	VEF = 'VEF',
	VND = 'VND',
	VUV = 'VUV',
	WST = 'WST',
	XAF = 'XAF',
	XAG = 'XAG',
	XAU = 'XAU',
	XCD = 'XCD',
	XDR = 'XDR',
	XOF = 'XOF',
	XPD = 'XPD',
	XPF = 'XPF',
	XPT = 'XPT',
	YER = 'YER',
	ZAR = 'ZAR',
	ZMW = 'ZMW',
	ZWL = 'ZWL',
}

export const BASE_CURRENCIES = Object.keys(Currency);

export type CurrencyNames = keyof typeof Currency;

export const FaqLinks = {
	externalInvoiceHelp:
		'https://help.dagpay.io/en/articles/5736720-how-do-i-pay-a-dagpay-invoice-in-other-cryptocurrencies',
	cryptoPaymentsHelp:
		'https://help.dagpay.io/en/articles/5736720-how-do-i-pay-a-dagpay-invoice-in-other-cryptocurrencies',
};
