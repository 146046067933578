import { Wallet } from '../api/walletApi';
import { FieldOptionProps } from '../components/field/Field';
import { ApiArrayPayload } from '../typings';

export function getWalletOptions(
	wallets: ApiArrayPayload<Wallet> | null,
	initialWalletId: string | null = null,
): FieldOptionProps[] {
	if (!wallets) {
		return [];
	}

	const { items } = wallets;

	if (!items) {
		return [];
	}

	return items.map((wallet: Wallet) => ({
		label: wallet.name,
		value: wallet.id,
		isDefault: wallet.isDefault,
		isInitialSelected: initialWalletId ? initialWalletId === wallet.id : wallet.isDefault,
	}));
}
