import dayjs from 'dayjs';
import * as H from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { RecurringInvoice, RecurringInvoiceState } from '../../../../api/invoiceApi';
import { Rates } from '../../../../api/ratesApi';
import CalculatedInvoiceTotal from '../../../../components/calculated-invoice-total/CalculatedInvoiceTotal';
import ColoredInvoiceState from '../../../../components/colored-invoice-state/ColoredInvoiceState';
import CreateInvoiceBlock from '../../../../components/create-invoice-block/CreateInvoiceBlock';
import GridTable, { GridTableButton, GridTableItem, GridTableType } from '../../../../components/grid-table/GridTable';
import NavigationBar from '../../../../components/navigation-bar/NavigationBar';
import Notification from '../../../../components/notification/Notification';
import Pager from '../../../../components/pager/Pager';
import Table from '../../../../components/table/Table';
import View from '../../../../components/view/View';
import WithPermission from '../../../../components/with-permission/WithPermission';
import { ParentViews, RoutesUrls, Scopes, GLOBAL_DATE_FORMAT } from '../../../../constants';
import { Avatar, H1, JustifiedLeftFlex } from '../../../../gfx/globals';

import { recurringInvoiceSendIntervals } from './RecurringInvoiceDetailView';
import { OpenedInformation } from './RecurringInvoicesListView';
import getCalculatedInvoiceCoinSubtotal from '../../../../services/get-invoice-coin-subtotal';
import { EmailInvoiceColSizes } from '../../../../gfx/constants';
import { ApiArrayPayload } from '../../../../typings';
import { InvoiceWrapper, NotificationRowWrapper, NotificationText, TitleWrapper } from '../escrow/EscrowListViewStyle';
import { roundCoinAmount } from '../../../../services/round-coin-amount';

interface Props {
	page: number;

	invoices: ApiArrayPayload<RecurringInvoice>;
	openedInformationRows: OpenedInformation[];
	isWalletAdded: boolean;
	rates: Rates;
	isVerified: boolean;
	history: H.History;
	handlePageChanged(newPage: number): void;
	handleToggleInformation(transactionId: string): void;
	handleOpenModal(invoice: RecurringInvoice, index: number): void;
}

export default function EmailInvoicesViewTpl(props: Props) {
	const { invoices, page, handlePageChanged, isWalletAdded, isVerified, handleToggleInformation } = props;

	const isUserReady = isWalletAdded && isVerified;

	const renderRecurringInvoice = (invoice: RecurringInvoice, index: number) => {
		return (
			<React.Fragment key={invoice.id}>
				<Table.TrPrimary className={isOpened(invoice.id) ? 'opened' : undefined}>
					<Table.UserNameTd data-label="Receiver">
						<JustifiedLeftFlex>
							<Avatar width={24} height={24} marginRight={10}>
								{invoice.to.charAt(0).toUpperCase()}
							</Avatar>
							{invoice.to}
						</JustifiedLeftFlex>
					</Table.UserNameTd>
					<Table.FixedWidthTd cellWidth={EmailInvoiceColSizes.CREATED}>
						<Table.DataWrapper data-label="Created">
							{dayjs(invoice.createdDate).format(GLOBAL_DATE_FORMAT)}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EmailInvoiceColSizes.DUE_DATE}>
						<Table.DataWrapper data-label="Active until">
							{invoice.endDate ? dayjs(invoice.endDate).format(GLOBAL_DATE_FORMAT) : '---'}
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EmailInvoiceColSizes.STATUS}>
						<Table.DataWrapper data-label="Status">
							<ColoredInvoiceState state={invoice.state} />
						</Table.DataWrapper>
					</Table.FixedWidthTd>
					<Table.FixedWidthTd cellWidth={EmailInvoiceColSizes.AMOUNT}>
						<Table.DataWrapper data-label="Amount">{getRecurringInvoicePrice(invoice)}</Table.DataWrapper>
					</Table.FixedWidthTd>

					<Table.SeeMoreTd
						className={isOpened(invoice.id) ? 'opened' : undefined}
						cellWidth={EmailInvoiceColSizes.ACTIONS}
					>
						<Table.Arrow
							opened={isOpened(invoice.id)}
							onClick={() => handleToggleInformation(invoice.id)}
							className="ArrowDown"
						>
							{isOpened(invoice.id) ? 'See less' : 'See more'}
							<ReactSVG src="/files/svg/private/Arrow.svg" />
						</Table.Arrow>
					</Table.SeeMoreTd>
				</Table.TrPrimary>
				{isOpened(invoice.id) && getMoreRecurringInvoiceInformation(invoice, index)}
			</React.Fragment>
		);
	};

	const getRecurringInvoicePrice = (transaction: RecurringInvoice) => {
		if (!transaction) {
			return 0;
		}

		const { rates } = props;
		const { tax, currencyAmount, currency } = transaction;

		return <CalculatedInvoiceTotal rates={rates} tax={tax} currencyAmount={currencyAmount} currency={currency} />;
	};

	const getRecurringInvoiceCoinSubtotal = (transaction: RecurringInvoice) => {
		if (!transaction) {
			return 0;
		}

		const { rates } = props;
		const { currencyAmount, currency } = transaction;

		return getCalculatedInvoiceCoinSubtotal({ currencyAmount, currency, rates });
	};

	const isOpened = (invoiceId: string) =>
		props.openedInformationRows.filter((obj) => obj.invoiceId === invoiceId && obj.isOpened).length > 0;

	const getMoreRecurringInvoiceInformation = (invoice: RecurringInvoice, index: number) => {
		const secondsInDay = 86400;
		const paymentDue = Math.floor(invoice.validForSeconds / secondsInDay);
		const isCancelPossible = invoice.state === RecurringInvoiceState.ACTIVE;

		const sendIntervalArray = recurringInvoiceSendIntervals.filter(
			(interval) => interval.value === invoice.sendIntervalSeconds.toFixed(0),
		);
		const subtotalCoinAmount = getRecurringInvoiceCoinSubtotal(invoice);
		const coinTotalAmount = subtotalCoinAmount && (subtotalCoinAmount * (100 + invoice.tax)) / 100;

		const itemList: GridTableItem[] = [
			{
				label: 'Subject',
				value: invoice.subject,
				position: { startingColumn: 1, endingColumn: 1, startingRow: 1, endingRow: 1 },
			},
			{
				label: 'Cc recipients',
				value: invoice.cc.length > 0 ? invoice.cc.join('; ') : '-',
				position: { startingColumn: 2, endingColumn: 5, startingRow: 1, endingRow: 1 },
			},
			{
				position: { startingRow: 2, endingRow: 2, startingColumn: 1, endingColumn: 5 },
				isSeparatorLine: true,
			},
			{
				label: 'Next date to send',
				value: dayjs(invoice.nextSendDate).format(GLOBAL_DATE_FORMAT),
				position: { startingColumn: 1, endingColumn: 1, startingRow: 3, endingRow: 3 },
				showCondition: invoice.state === RecurringInvoiceState.ACTIVE,
			},
			{
				label: 'Payment due',
				value: paymentDue > 1 ? `${paymentDue} days` : '1 day',
				position: { startingColumn: 2, endingColumn: 2, startingRow: 3, endingRow: 3 },
			},
			{
				label: 'Send new invoice every',
				value: sendIntervalArray.length !== 0 ? sendIntervalArray[0].label : '-',
				position: { startingColumn: 3, endingColumn: 4, startingRow: 3, endingRow: 3 },
			},
			{
				label: 'Send until',
				value: dayjs(invoice.endDate).format(GLOBAL_DATE_FORMAT),
				position: { startingColumn: 5, endingColumn: 5, startingRow: 3, endingRow: 3 },
				showCondition: invoice.endDate !== undefined && invoice.endDate !== null,
			},
			{
				position: { startingRow: 4, endingRow: 4, startingColumn: 1, endingColumn: 5 },
				isSeparatorLine: true,
			},
			{
				label: 'Custom ID or reference nr',
				value: invoice.paymentId ? invoice.paymentId : '-',
				position: { startingColumn: 1, endingColumn: 1, startingRow: 5, endingRow: 5 },
			},
			{
				label: 'Receiving wallet',
				value: invoice.wallet ? invoice.wallet.name : '-',
				position: { startingColumn: 5, endingColumn: 5, startingRow: 5, endingRow: 5 },
			},
			{
				position: { startingRow: 6, endingRow: 6, startingColumn: 1, endingColumn: 5 },
				isSeparatorLine: true,
			},
			{
				label: 'Description',
				value: invoice.description,
				position: { startingColumn: 1, endingColumn: 2, startingRow: 7, endingRow: 7 },
				showCondition: invoice.description !== undefined,
			},
			{
				label: 'Subtotal',
				value: `${subtotalCoinAmount && roundCoinAmount(subtotalCoinAmount)} DAG`,
				position: { startingColumn: 3, endingColumn: 3, startingRow: 7, endingRow: 7 },
			},
			{
				label: 'Tax',
				value: `${invoice.tax}%`,
				position: { startingColumn: 4, endingColumn: 4, startingRow: 7, endingRow: 7 },
			},
			{
				label: 'Invoice total',
				value: `${invoice.currencyAmount} ${invoice.currency}`,
				totalItem: {
					name: 'DAG',
					value: coinTotalAmount && roundCoinAmount(coinTotalAmount),
				},
				position: { startingColumn: 5, endingColumn: 5, startingRow: 7, endingRow: 7 },
			},
		];

		const buttonList: GridTableButton[] = [
			{
				label: 'Cancel',
				iconPath: '',
				red: true,
				isVisible: isCancelPossible,
				func: () => props.handleOpenModal(invoice, index),
			},
		];

		return (
			<GridTable
				opened={isOpened(invoice.id)}
				tableType={GridTableType.RECURRING_INVOICE}
				tableItems={itemList}
				tableButtons={buttonList}
			/>
		);
	};

	return (
		<View>
			<TitleWrapper>
				<H1>Email Invoice</H1>
			</TitleWrapper>

			<NotificationRowWrapper>
				<NotificationText>
					View your created email invoices or create a new one to send to your customer
				</NotificationText>
				{isUserReady && <CreateInvoiceBlock />}
			</NotificationRowWrapper>

			{!isWalletAdded && (
				<WithPermission permissions={[Scopes.WALLETS, Scopes.CREATE_WALLET]}>
					<Notification left>
						Please <Link to={RoutesUrls.WALLETS_DETAIL}> add the wallet</Link> to create new recurring invoice.
					</Notification>
				</WithPermission>
			)}
			{!isVerified && (
				<Notification left>
					Your identity or business is not verified, you can create new recurring invoice after process is completed.
				</Notification>
			)}

			<InvoiceWrapper>
				<NavigationBar parentView={ParentViews.EMAIL_INVOICES} subTitle="Recurring invoices" />

				{invoices.itemCount > 0 && invoices.items.length > 0 ? (
					<WithPermission permissions={[Scopes.RECURRING_INVOICES, Scopes.GET_RECURRING_INVOICES]}>
						<Table>
							<Table.Thead hiddenOnSmallerScreen>
								<tr>
									<th>Receiver</th>
									<th>Created</th>
									<th>Active until</th>
									<th>Status</th>
									<th>Amount</th>
									<th />
								</tr>
							</Table.Thead>
							<tbody>{invoices.items.map((invoice, index) => renderRecurringInvoice(invoice, index))}</tbody>
						</Table>
					</WithPermission>
				) : (
					<View.NoItemsFound>
						<ReactSVG src="/files/svg/private/NoEmailInvoice.svg" />
						<p>You haven’t created any recurring invoices yet</p>
					</View.NoItemsFound>
				)}

				<Pager
					totalItems={invoices.itemCount}
					currentPage={page}
					onChange={handlePageChanged}
					totalPages={invoices.pageCount}
				/>
			</InvoiceWrapper>
		</View>
	);
}
