import axios from 'restyped-axios';
import {
	Countries,
	Currency,
	Scopes,
	UserLoginType,
	UserRole,
	UserStatus,
	AtmPaymentsDefault,
	BusinessType,
	BusinessTier,
} from '../constants';
import { ApiResponse } from '../typings';
export const api = axios.create<UserApi>();

export interface UserApi {
	'/users': {
		POST: {
			body: SignUpRequest;
			response: ApiResponse<User>;
		};

		PUT: {
			body: UpdateUserRequest;
			response: ApiResponse<User>;
		};
	};
	'/users/getUserByEmail': {
		GET: {
			body: ChangePasswordRequest;
			response: ApiResponse<User>;
		};
	};

	'/users/getUserByEmail/:email': {
		GET: {
			response: ApiResponse<GetUserByEmailResponse>;
		};
	};

	'/users/password': {
		PUT: {
			body: ChangePasswordRequest;
			response: ApiResponse<User>;
		};
	};

	'/users/update-support-email': {
		PUT: {
			body: UpdateSupportEmailRequest;
			response: ApiResponse<UpdateSupportEmailResponse>;
		};
	};

	'/users/validate-unique-email': {
		POST: {
			body: ValidateUniqueEmailRequest;
			Response: ApiResponse<ValidateUniqueEmailResponse>;
		};
	};
}

export interface SignUpRequest {
	firstName: string;
	lastName: string;
	companyName: string;
	countryCode: string;
	email: string;
	password: string;
	referralCode?: string;
}

export interface GetUserByEmailRequest {
	email: string;
}

export interface ChangePasswordRequest {
	currentPassword: string;
	newPassword: string;
}

export interface UpdateSupportEmailRequest {
	supportEmail: string;
}

export interface UpdateSupportEmailResponse {
	supportEmail: string;
}

export interface ValidateUniqueEmailRequest {
	email: string;
}

export interface ValidateUniqueEmailResponse {
	isValid: boolean;
}
export interface GetUserByEmailResponse {
	id: string;
}

export interface UpdateUserRequest {
	baseCurrency: Currency;
	loginType: UserLoginType;
	atmPaymentsDefault: AtmPaymentsDefault;
	loginCode?: string;
	supportEmail?: string;
	currentPassword?: string;
	newPassword?: string;
}
export interface User {
	id: string;
	parentId: string | null;
	firstName: string;
	lastName: string;
	companyName: string;
	supportEmail: string;
	countryCode: keyof typeof Countries;

	email: string;
	emailVerified: boolean;
	// is always owner account value
	atmPaymentsDefault: AtmPaymentsDefault;
	baseCurrency: Currency;
	role: UserRole;
	scopes: Scopes[];
	loginType: UserLoginType;
	referralCode: string;
	businessType: BusinessType;
	status: UserStatus;
	lastLoginDate: string | null;
	dateOfBirth: string | null;
	customerLimitActive: boolean;
	tatumEnabled: boolean;
	// is always owner account value
	hasActiveAtm: boolean;
	tier: BusinessTier | null;
}

export const signUpApi = async (params: SignUpRequest) => {
	const signUpResponse = await api.post('/users', {
		...params,
	});

	return signUpResponse.data;
};

export const getUserByEmailApi = async (params: GetUserByEmailRequest) => {
	const response = await api.get<'/users/getUserByEmail/:email'>(`/atms/${params.email}`);

	return response.data;
};

export const changePasswordApi = async (params: ChangePasswordRequest) => {
	const changePasswordResponse = await api.put('/users/password', {
		...params,
	});

	return changePasswordResponse.data;
};

export const updateUserApi = async (params: UpdateUserRequest) => {
	const updateUserResponse = await api.put('/users', {
		...params,
	});

	return updateUserResponse.data;
};

export const updateSupportEmailApi = async (params: UpdateSupportEmailRequest) => {
	const updateSupportEmailResponse = await api.put('/users/update-support-email', {
		...params,
	});

	return updateSupportEmailResponse.data;
};

export const validateUniqueEmailApi = async (params: ValidateUniqueEmailRequest) => {
	const validateUniqueEmailResponse = await api.post('/users/validate-unique-email', {
		...params,
	});

	return validateUniqueEmailResponse.data;
};
