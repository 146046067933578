import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactSVG from 'react-svg';
import * as qs from 'query-string';

import { AwaitingPaymentText, PaymentHourGlassLoader } from '../../invoices/InvoiceViewStyle';

import { isMobile, isTablet } from 'react-device-detect';
import { FaqLinks } from '../../../constants';
import { RouteProps } from '../../../typings';
import { CenteredFlex } from '../../../gfx/globals';
import { Color } from '../../../gfx/constants';
import ExternalInvoiceRpcClient, {
	ExternalInvoiceSubscribeMethod,
} from '../../../services/external-invoice-rpc-client';
import { Json } from '../../../services/ws-rpc-client';
import getPaymentCountdown from '../../../services/get-payment-countdown';
import { Invoice } from '../../../api/invoiceApi';
import {
	MaximizedContent,
	CopyBlockWrapper,
	CopyBlockRow,
	CopyBlockRowLabel,
	CopyBlockRowValue,
	CopyBlockRowButton,
	ContentTitle,
	MaximizedText,
	MaximizedTextLight,
	Container,
	Sidebar,
	SidebarBlock,
	SidebarBlockCell,
	CurrencyPair,
	BaseAmount,
	BoldCellItem,
	ExpirationBlock,
	ValueItem,
	CancelModalButtonWrap,
	ExternalInvoiceWrapper,
	UnorderedList,
	ContentWrapper,
	WarningContentWrapper,
	CancelBlock,
	StyledCheckbox,
	StyledCheckboxWrapper,
	ServiceFeeBlock,
	ServiceFeeTitle,
	ServiceFeeContent,
} from './ExternalInvoiceViewStyle';
import getExternalInvoiceGroupedStatus, {
	ExternalInvoiceGroupedStatus,
} from '../../../services/get-external-invoice-grouped-status';
import Button from '../../../components/button/Button';
import { toast } from 'react-toastify';
import CopyButton from '../../../components/copy-button/CopyButton';
import trimStringCenter from '../../../services/trim-string-center';
import { ExternalInvoice, ExternalInvoiceCurrency, ExternalInvoiceStatus } from '../../../api/externalInvoiceApi';
import { useStoreActions, useStoreState } from '../../../services/store';
import ExternalInvoiceDetailsView from './details/ExternalInvoiceDetailsView';
import ExternalInvoiceSelectCurrencyView from './select-currency/ExternalInvoiceSelectCurrencyView';
import Modal from '../../../components/modal/Modal';
import { size1000, size481 } from '../../../services/media';
import ExternalInvoiceHeader from '../../../components/ext-invoice-header/ExternalInvoiceHeader';
import ExternalInvoiceStatusView from './status/ExternalInvoiceStatusView';
import ExternalInvoiceQrModalView from './qr-modal/ExternalInvoiceQrModalView';
import ExternalInvoiceQr from '../../../components/external-invoice-qr/ExternalInvoiceQr';
import Loader from '../../../components/loader/Loader';
import { Label } from '../../../components/react-hook-form/HookFieldsStyle';

export enum UpdateContentName {
	CURRENCY = 'currency',
	DETAILS = 'details',
}

enum ScreenSizeName {
	SMALL = 'SMALL',
	MEDIUM = 'MEDIUM',
	LARGE = 'LARGE',
}

interface RouteParams {
	externalInvoiceId?: string;
	redirectUrl?: string;
}

/* 
	ScreenSizeName.SMALL is related to size481 and isMobile
	ScreenSizeName.MEDIUM is between  size481 and size1000 and related to isTablet
	ScreenSizeName.LARGE is > size1000

	media breaks size481, size800 and size1000

*/
function ExternalInvoiceView(props: RouteProps<RouteParams>) {
	const [timer, setTimer] = useState<JSX.Element | string>('');
	const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
	const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
	const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
	const [screenSizeName, setScreenSizeName] = useState<ScreenSizeName>(ScreenSizeName.LARGE);

	const { externalInvoice, externalInvoiceStatus, isExtInvoiceLoading } = useStoreState((state) => ({
		externalInvoice: state.externalInvoice.externalInvoice,
		externalInvoiceStatus: state.externalInvoice.status,
		isExtInvoiceLoading: state.externalInvoice.isLoading,
	}));

	const {
		getExternalInvoice,
		cancelExternalInvoice,
		expireExternalInvoice,
		setExternalInvoiceGroupedStatus,
		setExternalInvoiceInvoiceReceivedAmount,
	} = useStoreActions((actions) => ({
		...actions.externalInvoice,
	}));

	let rpc: ExternalInvoiceRpcClient | undefined;

	const externalInvoiceId: string | undefined =
		props.match && props.match.params && props.match.params.externalInvoiceId;

	const redirectUrl = qs.parse(props.location.search).redirectUrl as string | undefined;
	const updateContentName = qs.parse(props.location.search).update as string | undefined;

	const checkScreenSize = () => {
		if (window.innerWidth < size481) {
			return setScreenSizeName(ScreenSizeName.SMALL);
		}

		if (window.innerWidth >= size1000) {
			return setScreenSizeName(ScreenSizeName.LARGE);
		}

		return setScreenSizeName(ScreenSizeName.MEDIUM);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setupRpcClient = (externalInvoiceId: string) => {
		// avoid trying to setup the rpc client more than once
		if (rpc !== undefined) {
			return;
		}

		const protocol = process.env.REACT_APP_API_SSL === 'true' ? 'wss' : 'ws';
		const url = `${protocol}://${process.env.REACT_APP_API_URL}/ws`;

		rpc = new ExternalInvoiceRpcClient(url, ExternalInvoiceSubscribeMethod.COINBASE_PAYMENT, externalInvoiceId);

		rpc.handleNewPayment = (rpcNotification: Json) => {
			if (typeof rpcNotification.newStatus !== undefined && typeof rpcNotification.newStatus === 'string') {
				const newStatus = getExternalInvoiceGroupedStatus(rpcNotification.newStatus as ExternalInvoiceStatus);

				const receivedAmount = rpcNotification.receivedAmount as string;

				// change ext invoice state in store
				if (receivedAmount) {
					setExternalInvoiceInvoiceReceivedAmount(Number.parseFloat(receivedAmount));
				}

				// change ext invoice state in store
				if (newStatus) {
					setExternalInvoiceGroupedStatus(newStatus);
				}

				return;
			}
		};
	};

	useEffect(() => {
		const fetchExternalInvoice = async (id: string) => {
			await getExternalInvoice({ externalInvoiceId: id });
		};

		const handleResize = () => {
			window.location.reload();
		};

		if (externalInvoiceId) {
			fetchExternalInvoice(externalInvoiceId);

			setupRpcClient(externalInvoiceId);

			window.addEventListener('resize', handleResize);

			checkScreenSize();
		}

		return () => {
			window.removeEventListener('resize', handleResize);
		};
		/*
			Disabled because this needs to run only once, meeting eslint requirements
			will cause an infinite loop, causing 100% cpu usage
		*/
		// eslint-disable-next-line
	}, []);

	const handleExpiration = async (id: string) => {
		// close cancel modal if still opened
		handleCloseCancelModal();

		const isExpired = await expireExternalInvoice({ externalInvoiceId: id });

		if (!isExpired) {
			toast.error('Failed to expire invoice');

			return;
		}
	};

	useEffect(() => {
		if (externalInvoice) {
			setTimer(
				getPaymentCountdown({
					invoice: externalInvoice as unknown as Invoice,
					onComplete: () => handleExpiration(externalInvoice.id),
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setTimer, externalInvoice, getExternalInvoice, isCancelModalOpen, isWarningModalOpen, isQrModalOpen]);

	useEffect(() => {
		if (
			externalInvoice &&
			externalInvoice.paymentCurrency === 'USDT' &&
			externalInvoice.status === ExternalInvoiceStatus.PENDING
		) {
			setIsWarningModalOpen(true);
		} else if (
			externalInvoice &&
			externalInvoice.paymentCurrency === 'USDC' &&
			externalInvoice.status === ExternalInvoiceStatus.PENDING
		) {
			setIsWarningModalOpen(true);
		} else {
			setIsWarningModalOpen(false);
		}
	}, [externalInvoice]);

	const getReceivedBy = () => (
		<SidebarBlock marginTop={15}>
			<SidebarBlockCell columnStart={1} columnEnd={1} rowStart={1} rowEnd={1}>
				<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
			</SidebarBlockCell>
			<SidebarBlockCell columnStart={2} columnEnd={2} rowStart={1} rowEnd={1}>
				Receiver
			</SidebarBlockCell>
			<SidebarBlockCell paddingTop={15} columnStart={2} columnEnd={2} rowStart={2} rowEnd={2}>
				<BoldCellItem>{!externalInvoice || !externalInvoice.receiver ? '...' : externalInvoice.receiver}</BoldCellItem>
			</SidebarBlockCell>
		</SidebarBlock>
	);

	const getProductDescription = () => (
		<SidebarBlock marginTop={15}>
			<SidebarBlockCell columnStart={1} columnEnd={1} rowStart={1} rowEnd={1}>
				<ReactSVG src="/files/svg/invoice/ReceiverIcon.svg" />
			</SidebarBlockCell>
			<SidebarBlockCell columnStart={2} columnEnd={2} rowStart={1} rowEnd={1}>
				Product
			</SidebarBlockCell>
			<SidebarBlockCell paddingTop={15} columnStart={2} columnEnd={2} rowStart={2} rowEnd={2}>
				<BoldCellItem>
					{externalInvoice && externalInvoice.product ? trimStringCenter(externalInvoice.product, 30) : '...'}
				</BoldCellItem>
			</SidebarBlockCell>
		</SidebarBlock>
	);

	const getAmountInfo = () => {
		/* 
			Payment currency can be optional:
				- display dash if payment currency missing
				- show quote currency in gray
		*/
		return (
			<SidebarBlock marginTop={46}>
				<SidebarBlockCell columnStart={1} columnEnd={1} rowStart={1} rowEnd={1}>
					<ReactSVG src="/files/svg/invoice/WalletGreenIcon.svg" />
				</SidebarBlockCell>
				<SidebarBlockCell columnStart={2} columnEnd={2} rowStart={1} rowEnd={1}>
					Amount
				</SidebarBlockCell>
				<SidebarBlockCell paddingTop={15} columnStart={2} columnEnd={2} rowStart={2} rowEnd={2}>
					<CurrencyPair>
						<BoldCellItem>
							{!externalInvoice
								? '...'
								: !!externalInvoice && !externalInvoice.paymentCurrency
								? '-'
								: `${externalInvoice.paymentAmount} ${externalInvoice.paymentCurrency} ${
										externalInvoice.paymentCurrency === 'USDT' ? '(TRC20)' : ''
								  }`}
						</BoldCellItem>
						<BaseAmount>
							{!externalInvoice ? '...' : `${externalInvoice.baseAmount} ${externalInvoice.baseCurrency}`}
						</BaseAmount>
					</CurrencyPair>
				</SidebarBlockCell>
			</SidebarBlock>
		);
	};

	const getServiceFee = () => {
		if (externalInvoice && externalInvoice.serviceFee !== 0) {
			return (
				<ServiceFeeBlock>
					<ServiceFeeTitle>Includes service fee:</ServiceFeeTitle>
					<ServiceFeeContent>{externalInvoice.serviceFee} %</ServiceFeeContent>
				</ServiceFeeBlock>
			);
		}

		return '';
	};

	const getExpirationBlock = () => {
		// use estate only while pending
		if (externalInvoiceStatus === ExternalInvoiceGroupedStatus.PENDING) {
			return (
				<ExpirationBlock>
					<CenteredFlex>Payment expires in {timer}</CenteredFlex>
					<AwaitingPaymentText>
						Awaiting payment
						<PaymentHourGlassLoader>
							<ClipLoader color={Color.GREEN_3} sizeUnit={'px'} size={20} />
						</PaymentHourGlassLoader>
					</AwaitingPaymentText>
				</ExpirationBlock>
			);
		}

		return '';
	};

	const handleCloseCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const renderConfirmCancelModal = () => {
		return (
			<Modal
				medium
				hasCloseButton
				ariaHideApp={false}
				isOpen={isCancelModalOpen && !!externalInvoice}
				onRequestClose={handleCloseCancelModal}
			>
				<ContentWrapper>
					<h2>Cancel payment</h2>
					<p>Are you sure you want to cancel the payment?</p>
					<CancelModalButtonWrap>
						<Button.Secondary white onClick={handleCloseCancelModal}>
							No, don’t cancel
						</Button.Secondary>
						<Button.Secondary red onClick={handleCancel}>
							Yes, cancel payment
						</Button.Secondary>
					</CancelModalButtonWrap>
				</ContentWrapper>
			</Modal>
		);
	};

	const [noticeModalOpen, setNoticeModalOpen] = useState(true);
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

	const handleCheckboxChange = () => {
		setIsCheckboxChecked(!isCheckboxChecked);
	};

	const invoiceId = externalInvoice ? externalInvoice.id : '';
	const [currentInvoiceId, setCurrentInvoiceId] = useState('');

	useEffect(() => {
		setCurrentInvoiceId(invoiceId);
	}, [invoiceId]);

	const handleCloseWarningModal = () => {
		setNoticeModalOpen(false);
		localStorage.setItem(currentInvoiceId, 'false');
	};

	useEffect(() => {
		const isModalOpen = localStorage.getItem(currentInvoiceId);
		setNoticeModalOpen(isModalOpen === 'false' ? false : true);
	}, [currentInvoiceId, invoiceId]);

	const renderWarningCancelModal = () => {
		return (
			<Modal
				medium
				ariaHideApp={false}
				isOpen={noticeModalOpen && !!externalInvoice}
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
				onRequestClose={handleCloseWarningModal}
			>
				<WarningContentWrapper>
					<h1>Important Payment Notice</h1>
					<p>
						Send only{' '}
						<strong>
							{externalInvoice !== null &&
								` ${
									externalInvoice.paymentCurrency === ExternalInvoiceCurrency.USDC
										? 'USD Coin (USDC)'
										: externalInvoice.paymentCurrency === ExternalInvoiceCurrency.USDT
										? 'Tether (USDT)'
										: `${externalInvoice.paymentCurrency} wallet`
								} `}
						</strong>{' '}
						on the <strong>TRON blockchain (TRC20)</strong> to this address. Sending any other coins may result in
						permanent loss.
						<br />
						<br />
						Ensure that the payment is made for the <strong>exact requested amount</strong>. Note that some exchanges
						may deduct fees from the sent amount, which could result in an underpayment.
					</p>
					<StyledCheckboxWrapper>
						<StyledCheckbox type="checkbox" onChange={handleCheckboxChange} checked={isCheckboxChecked} />
						<Label>Yes, I understand</Label>
					</StyledCheckboxWrapper>
					<CancelModalButtonWrap>
						<Button.Secondary green onClick={handleCloseWarningModal} disabled={!isCheckboxChecked}>
							Continue
						</Button.Secondary>
					</CancelModalButtonWrap>
				</WarningContentWrapper>
			</Modal>
		);
	};

	const handleCancel = async () => {
		if (!externalInvoice) {
			setIsCancelModalOpen(false);
			setIsWarningModalOpen(false);
			return;
		}

		const cancelledExtInvoice = await cancelExternalInvoice({ externalInvoiceId: externalInvoice.id });

		setIsCancelModalOpen(false);
		setIsWarningModalOpen(false);

		if (!cancelledExtInvoice) {
			toast.error('Failed to cancel invoice');

			return;
		}
	};

	const getCancelBlock = () => {
		if (!externalInvoice) {
			return (
				<CancelBlock>
					<MaximizedTextLight>Payments processed by Dpay</MaximizedTextLight>
				</CancelBlock>
			);
		}

		if (externalInvoiceStatus !== ExternalInvoiceGroupedStatus.PENDING) {
			return (
				<CancelBlock>
					<MaximizedTextLight>Payments processed by Dpay</MaximizedTextLight>
				</CancelBlock>
			);
		}

		// different style when only sidebar is displayed
		if (screenSizeName === ScreenSizeName.SMALL || screenSizeName === ScreenSizeName.MEDIUM || isMobile || isTablet) {
			return (
				<CancelBlock>
					<Button
						onClick={() => setIsCancelModalOpen(true)}
						centered
						transparent
						noBorder
						underLined
						color={Color.WHITE}
						marginBottom={20}
						marginTop={20}
					>
						Cancel payment
					</Button>
				</CancelBlock>
			);
		}

		return (
			<CancelBlock>
				<Button onClick={() => setIsCancelModalOpen(true)} centered transparent noBorder underLined color={Color.WHITE}>
					Cancel payment
				</Button>
				<MaximizedTextLight>Payments processed by Dpay</MaximizedTextLight>
			</CancelBlock>
		);
	};

	const getInfoBlock = () => {
		return (
			<UnorderedList>
				{getAmountInfo()}
				{getServiceFee()}
				{getReceivedBy()}
				{getProductDescription()}
			</UnorderedList>
		);
	};

	const getSidebarPayInvoiceButton = () => {
		// device in wrong size for display
		if (screenSizeName !== ScreenSizeName.SMALL || isTablet) {
			return null;
		}

		return (
			<Button onClick={() => setIsQrModalOpen(true)} centered marginTop={20} marginBottom={14}>
				Pay invoice
			</Button>
		);
	};

	const getPaymentSidebar = () => {
		const isSmallDevice =
			screenSizeName === ScreenSizeName.MEDIUM || screenSizeName === ScreenSizeName.SMALL || isMobile || isTablet;
		const updateDetails = !!updateContentName && updateContentName === UpdateContentName.DETAILS;
		const updateCurrency = !!updateContentName && updateContentName === UpdateContentName.CURRENCY;
		const missingDetails = !!externalInvoice && (!externalInvoice.customerName || !externalInvoice.customerEmail);
		const missingCurrency = !!externalInvoice && !externalInvoice.paymentCurrency;

		// missing details in small screen view or update request
		if (isSmallDevice && (updateDetails || missingDetails)) {
			return (
				<ExternalInvoiceDetailsView
					extInvoiceCustomerEmail={externalInvoice && externalInvoice.customerEmail}
					extInvoiceCustomerName={externalInvoice && externalInvoice.customerName}
					extInvoiceId={externalInvoice && externalInvoice.id}
					extInvoicePaymentCurrency={externalInvoice && externalInvoice.paymentCurrency}
					showHeader
				/>
			);
		}

		// missing currency or change attempt
		if (isSmallDevice && (updateCurrency || missingCurrency)) {
			return (
				<ExternalInvoiceSelectCurrencyView
					extInvoiceId={externalInvoice && externalInvoice.id}
					extInvoiceCustomerName={externalInvoice && externalInvoice.customerName}
					extInvoiceCustomerEmail={externalInvoice && externalInvoice.customerEmail}
					extInvoicePaymentCurrency={externalInvoice && externalInvoice.paymentCurrency}
					showHeader
				/>
			);
		}

		return (
			<Sidebar>
				<ExternalInvoiceHeader disableLogoClick helpLink={FaqLinks.externalInvoiceHelp} />
				{getInfoBlock()}
				{(screenSizeName === ScreenSizeName.MEDIUM || isTablet) &&
					externalInvoiceStatus === ExternalInvoiceGroupedStatus.PENDING && (
						<ExternalInvoiceQr
							isDarkBackground={true}
							paymentCurrency={externalInvoice ? externalInvoice.paymentCurrency : undefined}
							qrCodeUrl={externalInvoice ? externalInvoice.qrCodeUrl : undefined}
						/>
					)}
				{getExpirationBlock()}
				{getSidebarPayInvoiceButton()}
				{getCancelBlock()}
				<ExternalInvoiceQrModalView
					handleOnClose={() => setIsQrModalOpen(false)}
					isOpen={!!externalInvoice && isQrModalOpen && screenSizeName === ScreenSizeName.SMALL}
					qrCodeUrl={externalInvoice ? externalInvoice.qrCodeUrl : undefined}
					paymentCurrency={externalInvoice ? externalInvoice.paymentCurrency : undefined}
					paymentAmount={externalInvoice ? externalInvoice.paymentAmount : undefined}
					receivingWallet={externalInvoice ? externalInvoice.receivingWallet : undefined}
				/>
			</Sidebar>
		);
	};

	const getCopyInfoBlock = (extInvoice: ExternalInvoice) => {
		return (
			<CopyBlockWrapper>
				<CopyBlockRow>
					<CopyBlockRowLabel>Amount</CopyBlockRowLabel>
					<CopyBlockRowValue>
						<ValueItem>{extInvoice.paymentAmount}</ValueItem>
						<ValueItem>{extInvoice.paymentCurrency} (TRC20)</ValueItem>
						<CopyButton value={extInvoice.paymentAmount} top={-38} right={-30}>
							<CopyBlockRowButton>COPY</CopyBlockRowButton>
						</CopyButton>
					</CopyBlockRowValue>
				</CopyBlockRow>
				<CopyBlockRow>
					<CopyBlockRowLabel>Address</CopyBlockRowLabel>
					<CopyBlockRowValue>
						<ValueItem>{trimStringCenter(extInvoice.receivingWallet, 15)}</ValueItem>
						<CopyButton value={extInvoice.receivingWallet} top={-38} right={-30}>
							<CopyBlockRowButton>COPY</CopyBlockRowButton>
						</CopyButton>
					</CopyBlockRowValue>
				</CopyBlockRow>
			</CopyBlockWrapper>
		);
	};

	const getLoadingContent = () => {
		return (
			<MaximizedContent>
				<Loader />
			</MaximizedContent>
		);
	};

	const getMaximizedPendingContent = () => {
		// not loaded yet
		if (!externalInvoice || !externalInvoiceStatus) {
			return getLoadingContent();
		}

		// missing contact details
		if (
			!externalInvoice.customerEmail ||
			!externalInvoice.customerName ||
			(!!updateContentName && updateContentName === UpdateContentName.DETAILS)
		) {
			return (
				<ExternalInvoiceDetailsView
					extInvoiceId={externalInvoice.id}
					extInvoiceCustomerName={externalInvoice.customerName}
					extInvoiceCustomerEmail={externalInvoice.customerEmail}
					extInvoicePaymentCurrency={externalInvoice.paymentCurrency}
				/>
			);
		}

		// missing payment currency OR viewer wants to update
		if (!externalInvoice.paymentCurrency || (!!updateContentName && updateContentName === UpdateContentName.CURRENCY)) {
			return (
				<ExternalInvoiceSelectCurrencyView
					extInvoiceId={externalInvoice.id}
					extInvoiceCustomerName={externalInvoice.customerName}
					extInvoiceCustomerEmail={externalInvoice.customerEmail}
					extInvoicePaymentCurrency={externalInvoice.paymentCurrency}
				/>
			);
		}

		return (
			<MaximizedContent>
				<ContentTitle>Pay invoice</ContentTitle>
				<MaximizedText marginTop={10} marginBottom={20}>
					Scan QR code with your crypto wallet to proceed with payment
				</MaximizedText>
				<ExternalInvoiceQr
					isDarkBackground={false}
					paymentCurrency={externalInvoice.paymentCurrency}
					qrCodeUrl={externalInvoice.qrCodeUrl}
				/>
				<MaximizedText strong marginTop={17} marginBottom={10}>
					Or
				</MaximizedText>
				<MaximizedText marginBottom={24}>
					Send <span style={{ fontWeight: 'bold' }}>Tether ({externalInvoice.paymentCurrency})</span> to the address
					below:
				</MaximizedText>

				{getCopyInfoBlock(externalInvoice)}
			</MaximizedContent>
		);
	};

	const getContent = () => {
		// process not completed
		if (externalInvoiceStatus === ExternalInvoiceGroupedStatus.PENDING) {
			return (
				<>
					{getPaymentSidebar()}
					{!isMobile && !isTablet && screenSizeName === ScreenSizeName.LARGE && getMaximizedPendingContent()}
				</>
			);
		}

		// process completed
		return (
			<>
				{!isMobile && !isTablet && screenSizeName === ScreenSizeName.LARGE && getPaymentSidebar()}
				<ExternalInvoiceStatusView
					isExtInvoiceLoading={isExtInvoiceLoading}
					externalInvoiceStatus={externalInvoiceStatus}
					redirectUrl={redirectUrl}
					showHeader={isMobile || isTablet || screenSizeName !== ScreenSizeName.LARGE}
					externalInvoice={externalInvoice}
				/>
			</>
		);
	};

	return (
		<ExternalInvoiceWrapper>
			<Container>
				{getContent()}
				{renderConfirmCancelModal()}
				{renderWarningCancelModal()}
			</Container>
		</ExternalInvoiceWrapper>
	);
}

export default withRouter(ExternalInvoiceView);
