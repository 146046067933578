import React from 'react';
import ReactSVG from 'react-svg';
import ExternalInvoiceHeader from '../../../../components/ext-invoice-header/ExternalInvoiceHeader';
import Loader from '../../../../components/loader/Loader';
import { FaqLinks } from '../../../../constants';
import { ExternalInvoiceGroupedStatus } from '../../../../services/get-external-invoice-grouped-status';
import {
	ContentTitle,
	MaximizedText,
	SecondaryViewWrapper,
	Notification,
	CopyBlockRow,
	CopyBlockRowButton,
	CopyBlockRowLabel,
	CopyBlockRowValue,
	CopyBlockWrapperFullWidth,
	ValueItem,
} from '../ExternalInvoiceViewStyle';
import {
	ContentWrap,
	LoaderWrap,
	StatusSvgWrap,
	RedirectButton,
	RedirectButtonWrapper,
} from './ExternalInvoiceStatusViewStyle';
import { ExternalInvoice } from '../../../../api/externalInvoiceApi';
import CopyButton from '../../../../components/copy-button/CopyButton';
import trimStringCenter from '../../../../services/trim-string-center';
import toFixed from '../../../../services/toFixed';

const externalInvoiceFinalStatus = (extInvoice: ExternalInvoice | undefined | null) => ({
	[ExternalInvoiceGroupedStatus.PAID]: {
		title: (
			<>
				Invoice was <strong>paid!</strong>
			</>
		),
		description: <>Your payment for the invoice has been received. You may navigate back using the button below</>,
		iconSrc: '/files/svg/private/Paid.svg',
		color: 'GREEN_3',
		notification: false,
	},
	[ExternalInvoiceGroupedStatus.CANCELLED]: {
		title: 'Payment cancelled',
		description: <>You may navigate back to the merchant using the button below</>,
		iconSrc: '/files/svg/private/Failed.svg',
		color: 'RED_ERROR',
		notification: false,
	},
	[ExternalInvoiceGroupedStatus.EXPIRED]: {
		title: (
			<>
				Invoice <strong>expired!</strong>
			</>
		),
		description: <>Please return to the merchant and start the payment process again</>,
		iconSrc: '/files/svg/private/Expired.svg',
		color: 'ORANGE',
		notification: false,
	},
	[ExternalInvoiceGroupedStatus.FAILED]: {
		title: (
			<>
				Invoice <strong>failed</strong>
			</>
		),
		description: (
			<>
				Please check your crypto wallet to see if any funds were transferred. If yes, please contact the merchant to
				resolve the issue manually.
			</>
		),
		iconSrc: '/files/svg/private/Failed.svg',
		color: 'RED_ERROR',
		notification: false,
	},
	[ExternalInvoiceGroupedStatus.PENDING]: {
		title: 'Payment is pending',
		description: 'Please wait!',
		iconSrc: '/files/svg/private/dashboard/Pending.svg',
		color: 'ORANGE',
		notification: false,
	},
	[ExternalInvoiceGroupedStatus.UNDERPAID]: {
		title: (
			<>
				Invoice was <strong>underpaid</strong>
			</>
		),
		description: (
			<>
				Your payment for the invoice was less than expected. Please send the remaining amount to the same address below.
			</>
		),
		iconSrc: '/files/svg/private/Underpaid.svg',
		color: 'ORANGE',
		notification: (
			<Notification>
				<h3>
					<ReactSVG src="/files/svg/private/notificationIcon.svg" />
					Invoice has been paid partially
				</h3>
				<p>
					The amount paid for the invoice is{' '}
					<strong>
						{extInvoice ? extInvoice.invoiceReceivedAmount : 0} {extInvoice ? extInvoice.paymentCurrency : 0}
					</strong>
					. However, there is still a remaining balance of{' '}
					<strong>
						{extInvoice ? toFixed(Number.parseFloat(extInvoice!.paymentAmount) - extInvoice.invoiceReceivedAmount) : 0}{' '}
						{extInvoice ? extInvoice.paymentCurrency : 0}
					</strong>{' '}
					to be paid. To complete the payment, please send the remaining amount to the same address.
				</p>
			</Notification>
		),
	},
	[ExternalInvoiceGroupedStatus.OVERPAID]: {
		title: (
			<>
				Invoice was <strong>overpaid</strong>
			</>
		),
		description: (
			<>Your payment for the invoice was more than expected. Please contact Customer Support to request a refund.</>
		),
		iconSrc: '/files/svg/private/Underpaid.svg',
		color: 'ORANGE',
		notification: (
			<Notification>
				<h3>
					<ReactSVG src="/files/svg/private/notificationIcon.svg" />
					Invoice has been overpaid
				</h3>
				<p>
					The amount paid for the invoice is{' '}
					<strong>
						{extInvoice ? extInvoice.invoiceReceivedAmount : 0} {extInvoice ? extInvoice.paymentCurrency : 0}
					</strong>{' '}
					which exceeds the expected amount by{' '}
					<strong>
						{extInvoice ? toFixed(extInvoice.invoiceReceivedAmount - Number.parseFloat(extInvoice.paymentAmount)) : 0}{' '}
						{extInvoice ? extInvoice.paymentCurrency : 0}
					</strong>
					. Please contact the Customer Support to request a refund.
				</p>
			</Notification>
		),
	},
	[ExternalInvoiceGroupedStatus.OVERPAID_EXPIRED]: {
		title: (
			<>
				Invoice was <strong>overpaid</strong>
			</>
		),
		description: (
			<>Your payment for the invoice was more than expected. Please contact Customer Support to request a refund.</>
		),
		iconSrc: '/files/svg/private/Underpaid.svg',
		color: 'ORANGE',
		notification: (
			<Notification>
				<h3>
					<ReactSVG src="/files/svg/private/notificationIcon.svg" />
					Invoice has been paid partially
				</h3>
				<p>
					The amount paid for the invoice is{' '}
					<strong>
						{extInvoice ? extInvoice.invoiceReceivedAmount : 0} {extInvoice ? extInvoice.paymentCurrency : 0}
					</strong>{' '}
					which exceeds the expected amount by{' '}
					<strong>
						{extInvoice ? extInvoice.invoiceReceivedAmount - Number.parseFloat(extInvoice.paymentAmount) : 0}{' '}
						{extInvoice ? extInvoice.paymentCurrency : 0}
					</strong>
					. Please contact the Customer Support to request a refund.
				</p>
			</Notification>
		),
	},
	[ExternalInvoiceGroupedStatus.UNDERPAID_EXPIRED]: {
		title: (
			<>
				Invoice was <strong>underpaid</strong>
			</>
		),
		description: (
			<>Your payment for the invoice was less than expected. Please contact Customer Support to request a refund.</>
		),
		iconSrc: '/files/svg/private/Underpaid.svg',
		color: 'ORANGE',
		notification: (
			<Notification>
				<h3>
					<ReactSVG src="/files/svg/private/notificationIcon.svg" />
					Invoice has been paid partially
				</h3>
				<p>
					The amount paid for the invoice is{' '}
					<strong>
						{extInvoice ? extInvoice.invoiceReceivedAmount : 0} {extInvoice ? extInvoice.paymentCurrency : 0}
					</strong>
					. Unfortunately this invoice can no longer be processed. Please contact the Customer Support to request a
					refund.
				</p>
			</Notification>
		),
	},
});

export interface StatusViewProps {
	isExtInvoiceLoading?: boolean;
	externalInvoiceStatus?: ExternalInvoiceGroupedStatus | null;
	redirectUrl?: string;
	showHeader?: boolean;
	externalInvoice?: ExternalInvoice | null;
}

const getCopyInfoBlock = (extInvoice: ExternalInvoice) => {
	return (
		<CopyBlockWrapperFullWidth>
			<CopyBlockRow>
				<CopyBlockRowLabel>Remaining amount</CopyBlockRowLabel>
				<CopyBlockRowValue>
					<ValueItem>
						{extInvoice ? toFixed(Number.parseFloat(extInvoice!.paymentAmount) - extInvoice.invoiceReceivedAmount) : 0}
					</ValueItem>
					<ValueItem>{extInvoice ? extInvoice.paymentCurrency : 0}</ValueItem>
					<CopyButton value={extInvoice.paymentAmount} top={-38} right={-30}>
						<CopyBlockRowButton>COPY</CopyBlockRowButton>
					</CopyButton>
				</CopyBlockRowValue>
			</CopyBlockRow>
			<CopyBlockRow>
				<CopyBlockRowLabel>{` Address`}</CopyBlockRowLabel>
				<CopyBlockRowValue>
					<ValueItem>{trimStringCenter(extInvoice.receivingWallet, 15)}</ValueItem>
					<CopyButton value={extInvoice.receivingWallet} top={-38} right={-30}>
						<CopyBlockRowButton>COPY</CopyBlockRowButton>
					</CopyButton>
				</CopyBlockRowValue>
			</CopyBlockRow>
		</CopyBlockWrapperFullWidth>
	);
};

const ExternalInvoiceStatusView = (props: StatusViewProps) => {
	const { showHeader, redirectUrl, externalInvoiceStatus, isExtInvoiceLoading, externalInvoice } = props;
	const finalStatus = externalInvoiceFinalStatus(externalInvoice);

	return (
		<SecondaryViewWrapper disableStyles={!showHeader}>
			{showHeader && (
				<ExternalInvoiceHeader disableLogoClick hasWhiteBackground helpLink={FaqLinks.externalInvoiceHelp} />
			)}
			{isExtInvoiceLoading ||
				(externalInvoiceStatus &&
					finalStatus[externalInvoiceStatus].notification &&
					finalStatus[externalInvoiceStatus].notification)}
			{isExtInvoiceLoading || !externalInvoiceStatus ? (
				<LoaderWrap>
					<Loader />
				</LoaderWrap>
			) : (
				<ContentWrap color={finalStatus[externalInvoiceStatus].color}>
					<StatusSvgWrap>
						<ReactSVG src={finalStatus[externalInvoiceStatus].iconSrc} />
					</StatusSvgWrap>
					<ContentTitle>{finalStatus[externalInvoiceStatus].title}</ContentTitle>
					{externalInvoiceStatus === ExternalInvoiceGroupedStatus.UNDERPAID ? (
						<>
							<MaximizedText marginTop={17} marginBottom={20}>
								{finalStatus[externalInvoiceStatus].description}
							</MaximizedText>
							{externalInvoice && getCopyInfoBlock(externalInvoice)}
						</>
					) : (
						<MaximizedText marginTop={17} marginBottom={109}>
							{finalStatus[externalInvoiceStatus].description}
						</MaximizedText>
					)}
					<RedirectButtonWrapper>
						<RedirectButton onClick={() => (window.location.href = `${redirectUrl}`)}>
							<ReactSVG src="/files/svg/private/homeIcon.svg" />
							Return to merchant
						</RedirectButton>
					</RedirectButtonWrapper>
				</ContentWrap>
			)}
		</SecondaryViewWrapper>
	);
};

export default ExternalInvoiceStatusView;
