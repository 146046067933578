import * as React from 'react';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';

const Separator = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: calc(27px + 36px);

	::after {
		content: '';
		position: absolute;
		top: 100%;
		right: 0;
		margin-top: 27px;
		width: 100%;
		height: 1px;
		background: #d7ddeb;
	}
`;

const Title = styled.div`
	font-family: PrimaryMedium;
	font-size: 16px;
	letter-spacing: 0px;
	line-height: 22px;
	color: #0b132b;
`;

interface Props {
	title: string;
	iconSrc?: string;
}

export default function FormSeparator(props: Props) {
	const { title, iconSrc } = props;

	return (
		<Separator>
			{iconSrc && <ReactSVG src={iconSrc} />}
			<Title>{title}</Title>
		</Separator>
	);
}
