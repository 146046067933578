import { ExternalInvoiceStatus } from '../api/externalInvoiceApi';
import assertUnreachable from './assert-unreachable';

export enum ExternalInvoiceGroupedStatus {
	PENDING = 'PENDING',
	PAID = 'PAID',
	CANCELLED = 'CANCELLED',
	EXPIRED = 'EXPIRED',
	FAILED = 'FAILED ',
	OVERPAID = 'OVERPAID',
	UNDERPAID = 'UNDERPAID',
	OVERPAID_EXPIRED = 'OVERPAID_EXPIRED',
	UNDERPAID_EXPIRED = 'UNDERPAID_EXPIRED',
}

export default function getExternalInvoiceGroupedStatus(detailedStatus: ExternalInvoiceStatus) {
	let groupedStatus;

	switch (detailedStatus) {
		case ExternalInvoiceStatus.PAID:
			groupedStatus = ExternalInvoiceGroupedStatus.PAID;
			break;
		case ExternalInvoiceStatus.OVERPAID:
			groupedStatus = ExternalInvoiceGroupedStatus.OVERPAID;
			break;
		case ExternalInvoiceStatus.UNDERPAID:
			groupedStatus = ExternalInvoiceGroupedStatus.UNDERPAID;
			break;
		case ExternalInvoiceStatus.PAID_EXPIRED:
			groupedStatus = ExternalInvoiceGroupedStatus.PAID;
			break;
		case ExternalInvoiceStatus.UNDERPAID_EXPIRED:
			groupedStatus = ExternalInvoiceGroupedStatus.UNDERPAID_EXPIRED;
			break;
		case ExternalInvoiceStatus.OVERPAID_EXPIRED:
			groupedStatus = ExternalInvoiceGroupedStatus.OVERPAID_EXPIRED;
			break;
		case ExternalInvoiceStatus.PAID_CANCELLED:
			groupedStatus = ExternalInvoiceGroupedStatus.PAID;
			break;
		case ExternalInvoiceStatus.UNDERPAID_CANCELLED:
			groupedStatus = ExternalInvoiceGroupedStatus.PAID;
			break;
		case ExternalInvoiceStatus.OVERPAID_CANCELLED:
			groupedStatus = ExternalInvoiceGroupedStatus.PAID;
			break;
		case ExternalInvoiceStatus.EXPIRED:
			groupedStatus = ExternalInvoiceGroupedStatus.EXPIRED;
			break;
		case ExternalInvoiceStatus.CANCELLED:
			groupedStatus = ExternalInvoiceGroupedStatus.CANCELLED;
			break;
		case ExternalInvoiceStatus.FAILED:
			groupedStatus = ExternalInvoiceGroupedStatus.FAILED;
			break;
		case ExternalInvoiceStatus.PENDING:
			groupedStatus = ExternalInvoiceGroupedStatus.PENDING;
			break;

		default:
			return assertUnreachable(detailedStatus, `Discriminated union member: ${detailedStatus}`);
	}

	return groupedStatus;
}
