import styled, { css } from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { CheckboxMark, CheckboxText, CheckboxWrap } from '../checkbox/CheckboxStyle';
import StyledField from '../field/FieldStyle';

export const MultiSelectWrap = styled.div`
	position: relative;
	width: 100%;
	cursor: pointer;
`;

export const StyledMultiSelect = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	height: 100%;
	padding: 10px;
`;

export const MultiSelectLabel = styled.div<{ color?: string; countVisible: boolean }>`
	width: calc(100% - 25px);
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: Secondary;
	font-size: 16px;
	margin-right: 20px;
	text-transform: capitalize;

	span {
		position: absolute;
		visibility: hidden;
		background: ${Color.ORANGE};
		border-radius: 4px;
		padding: 4px;
		color: ${Color.WHITE};
		top: 0;
		margin: 4px 0 0 10px;
		height: 24px;
		width: 24px;
		min-width: 24px;
		text-align: center;
	}

	${(props) =>
		props.color &&
		css`
			color: ${Color.GRAY_8};
			text-transform: unset;
		`}

	${(props) =>
		props.countVisible &&
		css`
			span {
				visibility: visible;
			}
		`}
`;

export const MultiSelectDropDown = styled.div`
	position: absolute;
	z-index: 99;
	display: flex;
	flex-direction: column;
	top: 40px;
	width: auto;
	min-width: 100%;
	height: auto;
	background: white;
	padding: 15px 0 0 5px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
	border: 1px solid rgba(220, 221, 224, 0.5);
	border-radius: 4px;

	${StyledField.Wrapper} {
		margin: 0 0 10px 0 !important;
		white-space: nowrap;
	}

	${CheckboxText} {
		text-transform: capitalize;
	}

	${CheckboxMark} {
		min-height: 22px;
		height: 22px;
		min-width: 22px;
		width: 22px;
		margin-right: 5px;
	}

	${CheckboxWrap} {
		width: 100%;
	}
`;

export const ScrollableElement = styled.div`
	padding-right: 5px;
	/* scroll purpose */
	max-height: 315px;
	overflow: auto;

	/* width */
	::-webkit-scrollbar {
		width: 2px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: inherit;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${Color.GREEN_3};
	}
`;

export const SearchWrap = styled.div`
	display: flex;
	min-width: 100%;
	border: 1px solid ${Color.GRAY_5};
	border-radius: 15px;
	padding: 0 10px;
	text-align: center;
	height: 36px;

	& > div {
		padding-top: 7px;
		margin-right: 5px;
		height: auto;
	}
`;

export const DropSearch = styled.div`
	padding-right: 5px;
	margin-bottom: 10px;
	height: 36px;
	cursor: text;

	input {
		width: 100% !important;
		border: none;
		font-family: Secondary;
		font-size: 14px;
		line-height: 32px;
		min-height: 33px !important;

		:focus {
			outline: none;
		}
	}
`;

export const SelectableElement = styled.div<{ hidden: boolean }>`
	height: 36px;
	width: 100%;
	padding: 5px 10px 0 10px;
	margin-bottom: 5px;
	border-radius: 15px;

	${StyledField.Wrapper} {
		width: 100% !important;
	}

	${(props) =>
		props.hidden &&
		css`
			display: none;
		`}
`;

export const NoMatchingItems = styled.div`
	height: 36px;
	padding: 5px 10px 0 10px;
	margin-bottom: 5px;
	border-radius: 15px;
	cursor: default;
	text-align: center;
	color: ${Color.GRAY_TEXT};
`;

export const SelectArrow = styled.div`
	svg {
		position: absolute;
		top: 0px;
		right: 12px;
		bottom: 0px;
		margin: auto;
		height: 14px;
		transform: rotate(-90deg);

		path {
			fill: ${Color.GRAY_6};
		}
	}
`;

export const RotateArrow = styled.div`
	svg {
		transform: rotate(90deg);
	}
`;
