// tslint:disable:no-magic-numbers
import * as crypto from 'crypto';
import * as base32 from 'thirty-two';

const PI = '14159265358979323846264338327950288419716939937510';
const zeroString = '00000000';

const arrRelativeOffsets = PI.split('');

function checkLength(chashLength: number) {
	if (chashLength !== 160 && chashLength !== 288) {
		throw Error(`unsupported c-hash length: ${chashLength}`);
	}
}

function calcOffsets(chashLength: number): number[] {
	checkLength(chashLength);
	const arrOffsets = [];
	let offset = 0;
	let index = 0;

	for (let i = 0; offset < chashLength; i++) {
		const relativeOffset = parseInt(arrRelativeOffsets[i], 10);

		if (relativeOffset === 0) {
			continue;
		}

		offset += relativeOffset;

		if (chashLength === 288) {
			offset += 4;
		}

		if (offset >= chashLength) {
			break;
		}

		arrOffsets.push(offset);
		// console.log("index="+index+", offset="+offset);

		index++;
	}

	if (index !== 32) {
		throw Error('wrong number of checksum bits');
	}

	return arrOffsets;
}

const arrOffsets160 = calcOffsets(160);
const arrOffsets288 = calcOffsets(288);

function separateIntoCleanDataAndChecksum(bin: string) {
	const len = bin.length;
	let arrOffsets;

	if (len === 160) {
		arrOffsets = arrOffsets160;
	} else if (len === 288) {
		arrOffsets = arrOffsets288;
	} else {
		throw Error(`bad length=${len}, bin = ${bin}`);
	}
	const arrFrags = [];
	const arrChecksumBits = [];
	let start = 0;

	// tslint:disable-next-line:prefer-for-of
	for (let i = 0; i < arrOffsets.length; i++) {
		arrFrags.push(bin.substring(start, arrOffsets[i]));
		arrChecksumBits.push(bin.substr(arrOffsets[i], 1));
		start = arrOffsets[i] + 1;
	}

	// add last frag
	if (start < bin.length) {
		arrFrags.push(bin.substring(start));
	}
	const binCleanData = arrFrags.join('');
	const binChecksum = arrChecksumBits.join('');

	return { cleanData: binCleanData, checksum: binChecksum };
}

// function mixChecksumIntoCleanData(binCleanData: string, binChecksum: string) {
//   if (binChecksum.length !== 32) {
//     throw Error("bad checksum length");
//   }

//   const len = binCleanData.length + binChecksum.length;
//   let arrOffsets;

//   if (len === 160) {
//     arrOffsets = arrOffsets160;
//   } else if (len === 288) {
//     arrOffsets = arrOffsets288;
//   } else {
//     throw Error(`bad length=${len}, clean data = ${binCleanData}, checksum = ${binChecksum}`);
//   }

//   const arrFrags = [];
//   const arrChecksumBits = binChecksum.split("");
//   let start = 0;

//   for (let i = 0; i < arrOffsets.length; i++) {
//     const end = arrOffsets[i] - i;
//     arrFrags.push(binCleanData.substring(start, end));
//     arrFrags.push(arrChecksumBits[i]);
//     start = end;
//   }
//   // add last frag
//   if (start < binCleanData.length) {
//     arrFrags.push(binCleanData.substring(start));
//   }

//   return arrFrags.join("");
// }

function buffer2bin(buf: Buffer) {
	const bytes = [];
	// tslint:disable-next-line:prefer-for-of
	for (let i = 0; i < buf.length; i++) {
		let bin = buf[i].toString(2);

		if (bin.length < 8) {
			// pad with zeros
			bin = zeroString.substring(bin.length, 8) + bin;
		}
		bytes.push(bin);
	}

	return bytes.join('');
}

function bin2buffer(bin: string) {
	const len = bin.length / 8;
	const buf = Buffer.alloc(len);

	for (let i = 0; i < len; i++) {
		buf[i] = parseInt(bin.substr(i * 8, 8), 2);
	}

	return buf;
}

function getChecksum(cleanData: Buffer) {
	const fullChecksum = crypto.createHash('sha256').update(cleanData).digest();

	// console.log(fullChecksum);
	const checksum = Buffer.from([fullChecksum[5], fullChecksum[13], fullChecksum[21], fullChecksum[29]]);

	return checksum;
}

function isChashValid(encoded: string) {
	const encodedLen = encoded.length;

	if (encodedLen !== 32 && encodedLen !== 48) {
		// 160/5 = 32, 288/6 = 48
		throw Error(`wrong encoded length: ${encodedLen}`);
	}

	let chash: Buffer;

	try {
		chash = encodedLen === 32 ? base32.decode(encoded) : Buffer.from(encoded, 'base64');
	} catch (e) {
		return false;
	}

	const binChash = buffer2bin(chash);
	const separated = separateIntoCleanDataAndChecksum(binChash);
	const cleanData = bin2buffer(separated.cleanData);

	// console.log("clean data", cleanData);
	const checksum = bin2buffer(separated.checksum);

	// console.log(checksum);
	// console.log(getChecksum(cleanData));
	return checksum.equals(getChecksum(cleanData));
}

function isStringOfLength(str: string, len: number) {
	// tslint:disable-next-line:strict-type-predicates
	return typeof str === 'string' && str.length === len;
}

export function isValidChash(str: string, len: number) {
	return isStringOfLength(str, len) && isChashValid(str);
}
