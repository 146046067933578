import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Phone from 'react-phone-number-input';
import ReactSVG from 'react-svg';
import styled from 'styled-components/macro';

import { Color } from '../../gfx/constants';
import { media } from '../../services/media';

import { Border, Error, FieldWrapper, Label } from './HookFieldsStyle';

interface Props {
	name: string;
	label?: string;
	value?: string;
	disabled?: boolean;
	country?: string;
}

const DropdownButton = styled.div`
	width: 10px;
	position: absolute;
	left: 43px;
	transform: rotate(-90deg);

	svg path {
		fill: ${Color.GRAY_6};
	}
`;

const StyledPhone = styled(Phone)`
	width: 100%;
	font-size: 16px;
	display: flex;

	.PhoneInputCountryIcon {
		width: 24px;
		display: flex;
	}

	.PhoneInputCountryIcon svg {
		opacity: 0.6;
		width: 24px;
	}

	.PhoneInputInput {
		background: transparent;
		font-family: Primary;
		outline: none;
		line-height: 19px;
		padding: 0;
		-webkit-appearance: none;
		appearance: none;
		border: none;
		transition: border 0.1s;
		font-size: 15px;
		outline: none;
		padding: 0px 12px;
		width: 100%;
	}

	img {
		display: inherit;
		width: 100%;
	}

	.PhoneInputCountrySelect {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		font-size: 16px;
		z-index: 1;
		border: 0;
		opacity: 0;
		cursor: pointer;
	}

	.PhoneInputCountry {
		position: relative;
		align-self: stretch;
		display: flex;
		justify-content: center;
		border-right: 1px solid ${Color.GRAY_5};
		align-items: center;
		color: ${Color.GRAY_DARK};
		min-width: 65px;
		height: 48px;
		padding-right: 20px;
	}

	${media.maxSize481`
		& > div {
			height: 35px;
			min-height: 35px;
		}
	`}
`;

export default function HookPhoneField(props: Props) {
	const { name, label, value, disabled, country } = props;
	const { control } = useFormContext();

	return (
		<FieldWrapper>
			<Label>{label}</Label>
			<Border>
				<DropdownButton>
					<ReactSVG src="/files/svg/private/Arrow.svg" />
				</DropdownButton>
				<Controller
					as={StyledPhone}
					onChange={(e: any) => e}
					name={name}
					control={control}
					placeholder="+xxx xxxx xxxx"
					defaultValue={value}
					disabled={disabled}
					defaultCountry={country}
				/>
			</Border>
			<Error>
				<ErrorMessage name={name} />
			</Error>
		</FieldWrapper>
	);
}
