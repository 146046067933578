import * as React from 'react';
import ReactSVG from 'react-svg';

import Panel from '../../../../../../components/panel/Panel';
import PreviousLink from '../../../../../../components/previous-link/PreviousLink';
import View from '../../../../../../components/view/View';
import WithPermission from '../../../../../../components/with-permission/WithPermission';
import { RoutesUrls, Scopes } from '../../../../../../constants';
import { H1 } from '../../../../../../gfx/globals';
import { RouteProps } from '../../../../../../typings';
import { EnvironmentType } from '../../../../../../api/environmentApi';
import { Link } from 'react-router-dom';

export default (props: RouteProps) => {
	return (
		<View noUnderlineForLinks alignLeft>
			<H1 left>Create new environment</H1>
			<View.Description left>
				Create a new environment and integrated it with your website to accept payments
			</View.Description>
			<View.MerchantWrapper>
				<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
					<Link to={`/private/merchant/merchant-tools/environments/detail/${EnvironmentType.WOOCOMMERCE}`}>
						<Panel merchantPanel>
							<ReactSVG src="/files/svg/logos/woocommerce.svg" />
						</Panel>
					</Link>
				</WithPermission>

				<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
					<Link to={`/private/merchant/merchant-tools/environments/detail/${EnvironmentType.MAGENTO}`}>
						<Panel merchantPanel>
							<ReactSVG src="/files/svg/logos/magento.svg" />
						</Panel>
					</Link>
				</WithPermission>
				<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
					<Link to={`/private/merchant/merchant-tools/environments/detail/${EnvironmentType.OPENCART}`}>
						<Panel merchantPanel>
							<ReactSVG src="/files/svg/logos/opencart.svg" />
						</Panel>
					</Link>
				</WithPermission>
				<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
					<Link to={`/private/merchant/merchant-tools/environments/detail/${EnvironmentType.PRESTASHOP}`}>
						<Panel merchantPanel>
							<ReactSVG src="/files/svg/logos/prestashop.svg" />
						</Panel>
					</Link>
				</WithPermission>
				<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
					<Link to={`/private/merchant/merchant-tools/environments/detail/${EnvironmentType.EASY_DIGITAL_DOWNLOADS}`}>
						<Panel merchantPanel>
							<ReactSVG src="/files/svg/logos/easydigitaldownloads.svg" />
						</Panel>
					</Link>
				</WithPermission>
				<WithPermission permissions={[Scopes.ENVIRONMENTS, Scopes.CREATE_ENVIRONMENT, Scopes.GET_ENVIRONMENTS]}>
					<Link to={`/private/merchant/merchant-tools/environments/detail/${EnvironmentType.CUSTOM}`}>
						<Panel merchantPanel>
							<ReactSVG src="/files/svg/logos/custom.svg" />
						</Panel>
					</Link>
				</WithPermission>
			</View.MerchantWrapper>
			<PreviousLink title="Back" to={RoutesUrls.ENVIRONMENTS} />
		</View>
	);
};
