import { action, Action } from 'easy-peasy';
import escrow, { EscrowModel } from './escrowModel';

import atm, { AtmModel } from './atmModel';
import country, { CountryModel } from './countryModel';
import customerLimit, { CustomerLimitModel } from './customerLimitModel';
import dagCard, { DagCardModel } from './dagCardModel';
import discount, { DiscountModel } from './discountModel';
import environment, { EnvironmentModel } from './environmentModel';
import geo, { GeoModel } from './geoModel';
import invite, { InviteModel } from './inviteModel';
import invoice, { InvoiceModel } from './invoiceModel';
import pos, { PosModel } from './posModel';
import rates, { RatesModel } from './ratesModel';
import schema, { SchemaModel } from './schemaModel';
import swipex, { SwipexModel } from './swipexModel';
import user, { UserModel } from './userModel';
import utm, { UtmModel } from './utmModel';
import verification, { VerificationModel } from './verificationModel';
import viewer, { ViewerModel } from './viewerModel';
import wallet, { WalletModel } from './walletModel';
import externalInvoice, { ExternalInvoiceModel } from './externalInvoiceModel';
import tatumEnvironment, { TatumEnvironmentModel } from './tatumEnvironmentModel';

export interface StoreModel {
	customerLimit: CustomerLimitModel;
	wallet: WalletModel;
	environment: EnvironmentModel;
	tatumEnvironment: TatumEnvironmentModel;
	escrow: EscrowModel;
	verification: VerificationModel;
	viewer: ViewerModel;
	invoice: InvoiceModel;
	externalInvoice: ExternalInvoiceModel;
	rates: RatesModel;
	invite: InviteModel;
	user: UserModel;
	schema: SchemaModel;
	utm: UtmModel;
	country: CountryModel;
	pos: PosModel;
	swipex: SwipexModel;
	geo: GeoModel;
	atm: AtmModel;
	discount: DiscountModel;
	dagCard: DagCardModel;
	reset: Action<StoreModel | any, undefined>;
}

const model: StoreModel = {
	customerLimit,
	wallet,
	environment,
	tatumEnvironment,
	escrow,
	verification,
	viewer,
	invoice,
	externalInvoice,
	rates,
	invite,
	utm,
	country,
	user,
	schema,
	pos,
	swipex,
	geo,
	atm,
	discount,
	dagCard,
	reset: action((state, payload) => ({
		...{
			country: {},
			schema: {},
			environment: {},
			tatumEnvironment: {},
			escrow: {},
			invite: {},
			externalInvoice: {},
			invoice: {},
			pos: {},
			rates: {},
			user: {},
			utm: {},
			verification: {},
			viewer: {},
			wallet: {},
			customerLimit: {},
			swipex: {},
			geo: {},
			atm: {},
			discount: {},
			dagCard: {},
		},
	})),
};

export default model;
