import styled from 'styled-components/macro';

export const ModalFooter = styled.div`
	div {
		margin-top: 0px;
		display: block;
	}

	button {
		float: right;
	}
`;
