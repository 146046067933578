import { GraphData } from '../api/authApi';
import moment, { unitOfTime, DurationInputArg1, DurationInputArg2 } from 'moment';
import { extendMoment } from 'moment-range';

const momentRange = extendMoment(moment as any);

interface AddMissingGraphTicksArgs {
	dataIn: Array<GraphData>;
	rangeByUnit: unitOfTime.Diff;
	formatString: string;
	subtractAmount: DurationInputArg1;
	subtractUnit: DurationInputArg2;
}

export function addMissingGraphTicks(args: AddMissingGraphTicksArgs) {
	const { dataIn, formatString, rangeByUnit, subtractAmount, subtractUnit } = args;

	let today = moment();
	let range = momentRange.range(moment().subtract(subtractAmount, subtractUnit), moment());
	const yearMonthList = Array.from(range.by(rangeByUnit));

	if (moment([...yearMonthList].reverse()[0]).isBefore(today)) {
		yearMonthList.push(moment());
	}

	const filledArray = yearMonthList.map((yearMonth: moment.Moment) => {
		const foundGraphUnit = dataIn.filter((graphUnit) =>
			yearMonth.isBetween(moment(graphUnit.startDate), moment(graphUnit.endDate), 'hour', '[]'),
		);

		return {
			xAxis: yearMonth.format(formatString),
			dag: foundGraphUnit.length > 0 ? foundGraphUnit[0].dag : 0,
			formattedTime: yearMonth.format('H:mm'),
			formattedDate: yearMonth.format('D MMM, YYYY'),
		};
	});

	return filledArray;
}
