import { FieldOptionProps } from '../components/field/Field';
import { BASE_CURRENCIES, Currency } from '../constants';
import currenciesJson from '../views/private/merchant/merchant-tools/currency-list.json';

interface Props {
	current: Currency;
}

const sanitizedCurrenciesJson = JSON.parse(JSON.stringify(currenciesJson));

export function getBaseCurrencyOptions(props: Props) {
	const { current } = props;

	return BASE_CURRENCIES.reduce((acc, curr, index) => {
		acc[index] = {
			label: getCurrencyDescriptiveName(curr),
			value: curr,
			isInitialSelected: curr === current.toString(),
		};

		return acc;
	}, [] as FieldOptionProps[]);
}

function getCurrencyDescriptiveName(key: string) {
	const value = sanitizedCurrenciesJson[key];
	return value ? `${key} - ${value.name}` : key;
}
