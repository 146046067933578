import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { ParentViews } from '../../constants';
import getMergedRoutes from '../../services/get-merged-routes';
import { RouteProps } from '../../typings';
import { privateRoutes } from '../../views/private/routes';

import {
	StyledNavigationBar,
	NavLink,
	StyledNavigationBarWrap,
	StyledNavigationMobile,
	StyledNavigationDesktop,
	NavigationButton,
	RotateArrow,
} from './NavigationBarStyle';

interface Props extends RouteProps {
	parentView: ParentViews;
	subTitle?: String;
}

function NavigationBar(props: Props) {
	const { history, parentView, subTitle } = props;
	const [isDropDownVisible, setIsDropDownVisible] = useState(false);
	const [wrapperRef] = useState(React.createRef<HTMLDivElement>());

	const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
		const currentElement = event.target;

		if (
			wrapperRef &&
			wrapperRef.current &&
			currentElement instanceof Node &&
			!wrapperRef.current.contains(currentElement)
		) {
			setIsDropDownVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const toggleDropDownVisibility = () => {
		setIsDropDownVisible(!isDropDownVisible);
	};

	const links = getMergedRoutes(privateRoutes.filter((route) => route.parentView === parentView));
	const navigationLinks = links.filter((link) => link.navLinkTitle && link.url);

	const onLinkClick = (link: string) => (_e: React.MouseEvent<HTMLElement>) => props.history.push(link);

	return (
		<StyledNavigationBarWrap>
			<StyledNavigationMobile onClick={toggleDropDownVisibility} ref={wrapperRef}>
				<NavigationButton>
					{subTitle}
					{isDropDownVisible ? (
						<RotateArrow>
							<ReactSVG src="/files/svg/private/Arrow.svg" />
						</RotateArrow>
					) : (
						<ReactSVG src="/files/svg/private/Arrow.svg" />
					)}
				</NavigationButton>
				{isDropDownVisible && (
					<StyledNavigationBar>
						{navigationLinks.map((unit, index) => (
							<NavLink
								key={index}
								active={history.location.pathname === unit.url}
								onClick={onLinkClick(unit.url)}
								id={unit.permissions[0]}
							>
								{unit.navLinkTitle}
							</NavLink>
						))}
					</StyledNavigationBar>
				)}
			</StyledNavigationMobile>
			<StyledNavigationDesktop>
				<StyledNavigationBar>
					{navigationLinks.map((unit, index) => (
						<NavLink
							key={index}
							active={history.location.pathname === unit.url}
							onClick={onLinkClick(unit.url)}
							id={unit.permissions[0]}
						>
							{unit.navLinkTitle}
						</NavLink>
					))}
				</StyledNavigationBar>
			</StyledNavigationDesktop>
		</StyledNavigationBarWrap>
	);
}

export default withRouter(NavigationBar);
